import DataManagementStore from '../../../commons/DataManagementStore';

export default function UpdateFabric(data, payload, date, user, isListening){

  data.assets.fabrics[payload.id] = payload;

  //console.log('update', data.assets.fabrics[payload.id]);

  if(isListening){
    // live side effects
    DataManagementStore.updateItem(payload.id,payload);
  }

  return data;
}