import SecureLS from 'secure-ls';
import LogRocket from 'logrocket';

// change this common salt if you want to force all the localstorage to be rebuilt
const commonSalt = '32qt4r2_6'; // any kind of hash you want...

var _persistedStorageData;

var _workingUserData;

var _userId;

// everything implemented "as" async for future compatibility (fo instance with indexedDB)

export async function initUserStorage(configs){

  //console.log('--- run initUserStorage');

  _userId = configs.userId;

  _persistedStorageData = new SecureLS({
    encodingType: 'aes',
    isCompression: false,
    encryptionSecret: ((configs.hash.k ? configs.hash.k : configs.hash) + commonSalt + _userId).split('').reverse().join('')
  });
  try {
    _workingUserData = await getStoredItem(_userId);
  } catch(e) {
    LogRocket.captureException(e, {
      tags: {
        // additional data to be grouped as "tags"
        section: 'initialization',
        method: 'initUserStorage'
      }
    });
  }
  
  if(! _workingUserData || _workingUserData == ''){
    //console.log('-- no (persisted) user storage');
    _workingUserData = {
      profile: {
        myId: configs.userId,
        partitionId: configs.partitionId
      }
    };
  }
  window.profileData = _workingUserData; // TMP!
  return new Promise(resolve => {
    //console.log(_workingUserData);
    resolve(_workingUserData);
  });
}

function getStoredItem(key){
  return new Promise(resolve => {
    resolve( _persistedStorageData.get(key) );
  });
}

function setStoredItem(key, value){
  return new Promise(resolve => {
    //console.log('set item', key, value, _workingUserData);
    resolve( _persistedStorageData.set(key, value) );
  });
}

export async function persistUserStorage(){
  await setStoredItem(_userId, _workingUserData);
  return new Promise(resolve => {
    resolve(true);
  });
}

export function getWorkingData(){
  return _workingUserData;
}

export function getHotItem(key){
  return _workingUserData ? _workingUserData[key] : null;
}

export function setHotItem(key, value){
  _workingUserData[key] = value;
}