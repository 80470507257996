import itemsColors from './mainColors';

import MouPalettes from './palettes';

import { Museo, MuseoBold } from './fonts'; 

const adminTheme =  {
  typography: {
   // useNextVariants: true,
    fontFamily: [
      'Museo'
    ].join(',')
  },
  palette: {
    primary: MouPalettes.administration,
    secondary:  {
      main: MouPalettes.administration.main,
      contrastText: "#ffffff"
    }
  },
  itemHomeTopContentBg: '#585858',
  itemsColors,

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Museo,MuseoBold],
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 15
      }
    }
  },
};

export default adminTheme;