'use strict';

import React from 'react';
import MicroEvent from 'microevent';

import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

var 
  _path  = [],
  _title = '';

class BreadCrumbStoreClass extends MicroEvent{

  setBreadCrumb(params){
    _path  = params.path;
    _title = params.title;
    this.trigger('page:set');
  }

  refreshBreadCrumb(){
    this.trigger('page:set');
  }

  appenditem(params){
    _title = params.title;
    _path.push(params.item);
    this.trigger('page:set');
  }

  getBreadCrumb(){
    return {
      path:  _path,
      title: _title
    };
  }

}

var BreadCrumbStore = new BreadCrumbStoreClass();

class BreadCrumbComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      path: [],
      title: ''
    };
  }

  componentDidMount() { 
    BreadCrumbStore.bind('page:set', this.onPageLoad.bind(this));
  }

  componentWillUnmount() { 
    BreadCrumbStore.unbind('page:set', this.onPageLoad.bind(this));
  }

  onPageLoad(){
    var data = BreadCrumbStore.getBreadCrumb();
    this.setState(data);
  }

  //shouldComponentUpdate(nextProps, nextState){
  //  return nextState.title != this.state.title;
  //}

  render(){
    var 
      state = this.state,
      path = state.path,
      crumbs = [];

    for(var i in path){
      let 
        currStep = path[i], 
        item, 
        innerItem = (
          <span key={'step' + i}>{currStep.title}</span>
        );

      if(currStep.link !== null){
        item = (
          <Link key={'step' + i} to={currStep.link} style={{color:'white'}}>
            {innerItem}
          </Link>
        );
      } else {
        item = innerItem;
      }
      crumbs.push(item, (<span key={'separator' + i}>{" > "}</span>));
    }
    crumbs.pop();

    return (
      <div style={{lineHeight: '20px', paddingTop: 12, paddingLeft: 10}}>
        <div><Typography variant={'h5'} color={'primary'}>{state.title}</Typography></div>
        {/*<div style={{fontSize:'12px', fontWeight:'lighter'}}>{crumbs}</div>*/}
      </div>
    );
  }

}

var BreadCrumb  = {
  Component: BreadCrumbComponent,
  Store: BreadCrumbStore
}

export default BreadCrumb;