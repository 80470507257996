import React from 'react';

import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withTranslation, Trans } from 'react-i18next';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import getUrlQueryVariable from '../../../../../../commons/Utils/getUrlQueryVariable';
import InvalidLinkMessage from '../InvalidLinkMessage';

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      checkingCode: true,
      codeError: null,
      error: null,
      sending: false,
      done: false,
      email: null,
      success: false
    } 
  }

  componentDidMount(){
    // hideInitialLoader();
    const
      oobCode = getUrlQueryVariable('oobCode');
    if(!oobCode){
      this.setState({
        ...this.state,
        checkingCode: false,
        codeError: 'no-code'
      });
      return;
    }
    this.props.actions.verifyPasswordResetCode(oobCode, (err, email) => {
      if(err){
        this.setState({
          ...this.state,
          checkingCode: false,
          codeError: err.code
        })
      } else {
        this.setState({
          ...this.state,
          checkingCode: false,
          email: email
        })
      }
    });
  }

  resetConfirm(){
    this.setState({
      ...this.state,
      sending: true
    });
    this.props.actions.confirmPasswordReset(getUrlQueryVariable('oobCode'), this.state.password, err => {
      if(err){
        this.setState({
          ...this.state,
          sending: false,
          done: true,
          success: false
        });
      } else {
        this.setState({
          ...this.state,
          sending: false,
          done: true,
          success: true
        });
      }
    });
  }

  changeValue(property, event){   
    var state = {};
    state[property] = event.target.value;
    this.setState(state);
  }

  render() {
    const
      { t } = this.props,
      email = this.state.email;
    
    if(this.state.checkingCode === true){
      return (
        <div style={{marginTop: 15, display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </div>
      )
    }

    if(this.state.codeError !== null){
      if(this.state.codeError === 'no-code'){
        return <InvalidLinkMessage />;
      }
      return (
        <div style={{marginTop: 15}}>
          <Typography variant={'body1'}>
            {t('reset-password-' + this.state.codeError)}
          </Typography>
          <div className="row end-xs"  style={{marginTop:20}}>
              {this.props.loggedIn === false
                ? <div className="col-xs-12">
                    <Link to={'/forgot-password'} style={{textDecoration: 'none'}}>
                      <Button
                        color='primary'
                        variant="contained"
                        disableElevation
                      >
                        {t('reset-password-reset-again')}
                      </Button>
                    </Link>
                  </div>
                : <div className="col-xs-12">
                    <Link to={'/'} style={{textDecoration: 'none'}}>
                      <Button
                        color='primary'
                        variant="contained"
                        disableElevation
                      >
                        Voltar para a página inicial
                      </Button>
                    </Link>
                  </div>
              }
              </div>
        </div>
      )
    }

    return (
      <div>

        {this.state.done === true
          ? <div style={{marginTop: 15}}>
              <Typography variant={'body1'}>
                {this.state.success === true 
                  ? t('reset-password-success') 
                  : t('reset-password-fail') 
                }
              </Typography>
              <div className="row end-xs"  style={{marginTop:20}}>
                <div className="col-xs-12">
                  <Link 
                    to={this.state.success === true ? '/' : '/forgot-password'} 
                    style={{textDecoration: 'none'}}
                  >
                    <Button
                      color='primary'
                      variant="contained"
                      disableElevation
                    >
                      {this.state.success === true 
                        ? this.props.loggedIn === true
                          ? t('reset-password-final-button')
                          : t('reset-password-login-button')
                        : t('reset-password-reset-again')
                      }
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

          : <div>
              
              <div className="row" style={{marginTop: 15}}>
                <div className="col-xs-12">
                  <Typography variant={'body1'}>
                    <Trans t={t} i18nKey={'reset-password-text'} email={email}>
                      Redefina a senha para o usuário {{email}}
                    </Trans>
                  </Typography>
                  <TextField 
                    name='password'
                    type='password'
                    defaulValue={this.state.password}
                    label={t('reset-password-new-password-label')}
                    onChange={this.changeValue.bind(this, 'password')}
                    style={{width:"100%"}} 
                    disabled={this.state.sending}
                  />
                </div>
              </div>

              <div className="row" style={{marginTop: 15}}>
                <div className="col-xs-12">
                  <TextField 
                    name='ConfirmPassword'
                    type='password'
                    defaulValue={this.state.confirmPassword}
                    label={t('reset-password-confirm-password-label')}
                    onChange={this.changeValue.bind(this, 'confirmPassword')}
                    style={{width:"100%"}} 
                    disabled={this.state.sending}
                  />
                </div>
              </div>

              <div className="row end-xs"  style={{marginTop:20}}>
                <div className="col-xs-6" style={{textAlign:'left'}}>
                  {this.state.sending &&
                  <CircularProgress size={36} style={{marginLeft: '50%'}}/>
                  }
                  {this.state.error &&
                    <Typography variant={'body2'}>{t(this.state.error)}</Typography>
                  }
                </div>
                <div className="col-xs-6">
                  <Button
                    type="submit"
                    color='primary'
                    variant="contained"
                    disableElevation
                    disabled={this.state.sending || !(this.state.password === this.state.confirmPassword && this.state.password.length > 5)}
                    onClick={this.resetConfirm.bind(this)}>
                      {t('reset-password-button')}
                  </Button>
                </div>
              </div>
            
            </div>
        }
    </div>);
  }
}

export default withTranslation()(ResetPassword);