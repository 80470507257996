import { Map, fromJS, List } from 'immutable';

import { ACCOUNT_ADD_NESTING_TABLE, ACCOUNT_REMOVE_NESTING_TABLE, ACCOUNT_READ_NESTING_TABLES } from '../constants';

var defaultState = Map({nestingTables:fromJS([{name: 'Mesa X', height: 150, width: 500},{name: 'Mesa Grande', height: 250, width: 1500}])});

export default function account( state = defaultState,  action) {

  if(!action) return state;

    switch (action.type) {
      
    case ACCOUNT_ADD_NESTING_TABLE:
    	// console.log('foi');
      return state.updateIn(["nestingTables"], List(), list => list.push(fromJS(action.data)));

  case ACCOUNT_REMOVE_NESTING_TABLE:
      // console.log('remo');
      return state.updateIn(["nestingTables"], List(), list => list.push(fromJS(action.data)));


    case ACCOUNT_READ_NESTING_TABLES:
      return state;

    default:
       return state;
    }
}
