import DataManagementStore from '../../../commons/DataManagementStore';



export default function RenameProject(data, payload, date, user, isListening){

  //console.log('Trash base handler');

  data.projects[payload.id].name = payload.name;

  if(isListening){
    // live side effects
    DataManagementStore.renameItem(payload.id, payload.name);
  }

  return data;
}