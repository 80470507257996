import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';

import ModelingIcon from '../../mou-icons/ModelingIcon';

const styles = {
  modelingAvatar: { 
    background: palettes.mouRed.main
  },
  modelingIcon: {
    width:34, 
    height:34, 
    stroke: palettes.mouRed.contrastText, 
    strokeWidth: 6, 
    marginTop: 9
  },
  modelingBorder: {
    border: '2px solid ' + palettes.mouRed.contrastText
  }
}

const ProjectAvatar = (props) =>  <Avatar 
                                    className={
                                      props.classes.modelingAvatar + 
                                      (props.withBorder ? ' ' + props.classes.modelingBorder : '')
                                    } 
                                    size={props.size} 
                                    style={props.style}
                                  >
                                    <ModelingIcon className={props.classes.modelingIcon} />
                                  </Avatar>

export default withStyles(styles)(ProjectAvatar);