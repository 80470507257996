import React, { Fragment, useEffect } from "react";
import mainColors from "../mou-mui-themes/mainColors";
import { Card, Link, Typography } from "@material-ui/core";
import { getHotItem } from "../commons/storage/storage";
import { useHistory } from "react-router";

function isUnpaid(status){
  return ['unpaid', 'invalid-payment-status'].includes(status);
}

function isOnTrial(status){
  return status === 'on-trial';
}

export default function SubscriptionAlert(props){
  const
    profile = getHotItem('profile'),
    history = useHistory(),
    isSubscriptionPage = history.location.pathname === '/profile/subscription',
    { subscriptionStatus } = profile;

  useEffect( () => {
    if(subscriptionStatus){
      if(isUnpaid(subscriptionStatus.status)){
        console.log('Sera deslogado...');
        setTimeout(() => {
          alert('Você será deslogado automaticamente. Ainda pode fazer login novamente e assinar para continuar a utilizar a MOU.');
          props.actions.logout();
        }, 5 * 60 * 1000); // 5 minutes
      }
    }
  }, []);

  if(!isSubscriptionPage && subscriptionStatus){
    const
      days = subscriptionStatus.promo.trialDaysLeft,
      status = subscriptionStatus.status;
    return (
      <Fragment>
        {isUnpaid(status) && 
          <div 
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: '#ffffff88',
              zIndex: 9998,
              backdropFilter: 'blur(5px)'
            }}
          />
        }
        {(isOnTrial(status) || isUnpaid(status)) &&
          <Card
            elevation={5}
            style={{
              position: 'absolute',
              left: '50%',
              top: -18,
              background: mainColors.mouRed,
              zIndex: 9999,
              padding: '20px 8px 5px 8px',
              transform: 'translateX(-50%)'
            }}
          >
            <Typography
              variant={'body2'}
              style={{
                color: 'white'
              }}
            >
              {(isOnTrial(status)) &&
                <span>Sua Degustação MOU termina em {days} dia{days === 1 ? '' : 's'}. <Link 
                  onClick={() => history.push('/profile/subscription')}
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}>
                    Saiba mais
                  </Link>
                </span>
              }
              {isUnpaid(status) &&
                <span>
                  {status == 'unpaid' 
                    ? 'Sua Degustação MOU terminou!' 
                    : 'Sua assinatura MOU precisa ser ativada ou renovada!'
                  }<br />
                  Para continuar a utilizar a MOU, <Link 
                  onClick={() => history.push('/profile/subscription')}
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}>
                    Assine agora
                  </Link>
                </span>
              }
            </Typography>
          </Card>
        }
      </Fragment>
    );
  }
  return null;
}