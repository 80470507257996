class TreeFunctions {

	// tree param can be a full tree o some branch of it
	findOneChild(queryParam, queryValue, tree, childrenParamName){
    var result = null;
    var childrenParamName = childrenParamName || 'children';
    var path = [];
    function _findTheRightChild(children){
      for(var i in children){
        path.push(i);
        var currChild = children[i];
        if(currChild[queryParam] == queryValue){
          currChild.__path = path;
          result = currChild;
          break;
        }
        if(currChild[childrenParamName]){
          _findTheRightChild(currChild[childrenParamName]);
        }
      }
    }
    _findTheRightChild(tree);
    return result;
  }

  /**
   * usage examples:
  
    just on value, whisch is also the key:

    var teste1 = [
      [118016, 118784, 119552, 120320, 121600],
      [118016, 118784, 119552, 120320, 121856],
      [118016, 118784, 120064, 120576, 121600],
      [118016, 118784, 120064, 120576, 121856],
      [118016, 119040, 119552, 121088, 121600],
      [118272, 119040, 119552, 121088, 121600]
    ];
    console.log(  TreeFunctions.createTreeFromPathsArray(teste1, 'ch')  );

    an object with value, and the key must be specified as the 3rd param

    var teste2 = [
      [{id: 118016, name: '2015'}, {id: 118784, name: 'marca 1'}, {id: 119552, name: 'verão'},   {id: 120320, name: 'beachwear'}, {id: 121600, name: 'feminino'}, {id: collectionId, apparels: a}],
      [{id: 118016, name: '2015'}, {id: 118784, name: 'marca 1'}, {id: 119552, name: 'verão'},   {id: 120320, name: 'beachwear'}, {id: 121856, name: 'masculino'}],
      [{id: 118016, name: '2015'}, {id: 118784, name: 'marca 1'}, {id: 120064, name: 'inverno'}, {id: 120576, name: 'sleepwear'}, {id: 121600, name: 'feminino'}],
      [{id: 118016, name: '2015'}, {id: 118784, name: 'marca 1'}, {id: 120064, name: 'inverno'}, {id: 120576, name: 'sleepwear'}, {id: 121856, name: 'masculino'}],
      [{id: 118016, name: '2015'}, {id: 119040, name: 'marca 2'}, {id: 119552, name: 'verão'},   {id: 121088, name: 'lingerie'},  {id: 121600, name: 'feminino'}],
      [{id: 118272, name: '2014'}, {id: 119040, name: 'marca 2'}, {id: 119552, name: 'verão'},   {id: 121088, name: 'lingerie'},  {id: 121600, name: 'feminino'}]
    ];
    console.log(  TreeFunctions.createTreeFromPathsArray(teste2, 'ch', 'id')  );


   */

  createTreeFromPathsArray(paths, childrenPropName, itemKey){
    childrenPropName = childrenPropName || 'children';
    var out = {};
    var curr;
    var maxPathLength = 0;
    for(var i in paths){
      maxPathLength = Math.max(maxPathLength, paths[i].length);
    }

    function setBranch(branch, keyOrItem){
      if(!branch[childrenPropName]){
        branch[childrenPropName] = {};
      }
      if(!itemKey){
        if(!branch[childrenPropName][keyOrItem]){
          branch[childrenPropName][keyOrItem] = {};
        }
        return branch[childrenPropName][keyOrItem];
      } else {
        var key = keyOrItem[itemKey];
        if(!branch[childrenPropName][key]){
          branch[childrenPropName][key] = keyOrItem;
        }
        return branch[childrenPropName][key];
      }
    }

    for(var p in paths){
      curr = setBranch(out, paths[p][0]);
      for(var i = 1; i<maxPathLength; i++){
        var path = paths[p];
        if(path){
          curr = setBranch(curr, paths[p][i]);
        }
      }
    }
    return out;
  }

}

export default new TreeFunctions();