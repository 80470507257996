import React, { Component } from 'react';

import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

import {Link, withRouter } from 'react-router-dom';
import Divider  from '@material-ui/core/Divider';
import DataManagementStore from '../../commons/DataManagementStore';
import DateTime from '../../components/Utils/DateTime';
import UserName from '../../components/Utils/UserName';

import BaseAvatar from './Avatar/BaseAvatar';
import ProjectAvatar from './Avatar/ProjectAvatar';
import SizesTableAvatar from './Avatar/SizesTableAvatar';
import NestingAvatar from './Avatar/NestingAvatar';
import getWorkspaceMemberName from '../../commons/workspaces/getWorkspaceMemberName';

const itemsUrls = {
  'userBases': 'creation/base/',
  'userProjects': 'creation/project/',
  'accountSizesTables': 'creation/sizes-table/',
  //'fabrics': () => <FabricAvatar />,
  'userNestings': 'production/nesting/session/'
}

const itemsUrlsSuffix = {
  'userBases': '/modeling',
  'userProjects': '/modeling',
  'accountSizesTables': '',
  //'fabrics': () => <FabricAvatar />,
  'userNestings': ''
}

class RecentItemsHome extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recentsItemsLoaded: DataManagementStore.recentsItemsLoaded
    }
    this.recentItemsLoadedHandler = this.onRecentItemsLoaded.bind(this);
  }

  componentDidMount(){
    if(DataManagementStore.recentsItemsLoaded == false){
      DataManagementStore.bind('recentItemsLoaded', this.recentItemsLoadedHandler);
    }
  }

  componentWillUnmount(){
    if(DataManagementStore.recentsItemsLoaded == false){
      DataManagementStore.unbind('recentItemsLoaded', this.recentItemsLoadedHandler);
    }
  }

  onRecentItemsLoaded(){
    DataManagementStore.unbind('recentItemsLoaded', this.recentItemsLoadedHandler);
    this.state.recentsItemsLoaded = true;
    this.setState(this.state);
  }

  _onClick(id, link){
    if(this.props.onItemClick) this.props.onItemClick(id, link);
  }

  onItemMenuClick(action, bucket, id){
    if(this.props.onMenuOptionClick){
      this.props.onMenuOptionClick(action, bucket, id);
    }
  }

  render() {
    const 
      CurrentAvatar = {
        'userBases': () =>  <BaseAvatar />,
        'userProjects': () => <ProjectAvatar />,
        'accountSizesTables': () => <SizesTableAvatar />,
        //'fabrics': () => <FabricAvatar />,
        'userNestings': () => <NestingAvatar />
      }
    var 
      recentBody = null,
      p = this.props,
      elevation = p.noShadow ? 0 : 2;
    if(this.state.recentsItemsLoaded == false){
      recentBody = <Paper elevation={elevation}><p style={{padding: 15}}>{p.loadingText}</p></Paper>;
    } else {
      var items = DataManagementStore.getRecentItems(this.props.buckets, 10);
      if(!items || items.length == 0){
        recentBody = <Paper elevation={elevation}><p style={{padding: 15}}>{p.noItemsText}</p></Paper>;
      } else {
        recentBody = [];
        items.forEach( (_item, i) => {
          var { item, event } = _item;
          var selected = false;
          if(p.selectedItems[item.id] && !p.uncheckedItems[item.id]){
            selected = true;
          }
          var link = '/' + p.itemLink + '/' + item.id;
          /*
          list.push(
            <FileListItem
              item={itemData}
              key={i}
              secondaryText={<span style={{marginTop:4}}></span>}
              selected={selected}
              onItemClick={this._onClick.bind(this, item.targetId, link)}
              itemLink={link}
              showOpenInNewTab={true} />
            , 
            <Divider key={'d' + i} />
          );<FileListItem
                mode={'card'}
                item={item}
                key={i}
                secondaryText={<span style={{marginTop:4}}></span>}
                selected={selected}
                onItemClick={this._onClick.bind(this, item.id, link)}
                itemLink={link}
                showOpenInNewTab={true} 
                menuOptions={this.props.menuOptions || []}
                onMenuOptionClick={(action) => this.onItemMenuClick(action, item.__bucket, item.id)}
                loading={p.loadingItem == item.id} />
          */
  
        

          recentBody.push(
              <ListItem 
                button 
                key={i}
                onClick={() => { p.history.push('/' + itemsUrls[item.__bucket] + item.id + itemsUrlsSuffix[item.__bucket])}}
              >
                <ListItemAvatar>
                  {CurrentAvatar[item.__bucket]()}
                </ListItemAvatar>
                <ListItemText
                  primary={<div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.name}</div>}
                  secondary={ <span style={{marginTop:4}}>
                                {(item.workspace && item.sent_by)
                                  &&  <span style={{color: '#b5b5b5'}}>
                                        {getWorkspaceMemberName(item.workspace.id, item.sent_by)} | {item.workspace.name}<br />
                                      </span>
                                }
                                {(item.sharedWith)
                                  &&  <span style={{color: '#b5b5b5'}}>
                                        {item.sharedFrom == 'me' 
                                          ? 'Compartilhado por você' 
                                          : <span>Compartilhado por <UserName id={item.sharedFrom} /></span>
                                        }
                                        <br />
                                      </span>
                                }
                                Aberto <DateTime v={event.d} />
                              </span>}
                />
              </ListItem>,
            <Divider key={i+'d'} />
         );
        });
        //list.pop();
        //recentBody = <Paper><List style={{paddingBottom: 0, paddingTop: 0}} >{list}</List></Paper>;
      }
    }

    return  <Card>
              <div style={{padding:'8px 24px 0 16px'}}>
                <Typography variant="h5" component="h2">
                  Arquivos Recentes
                </Typography>
              </div>
              
              <List><Divider />
                {recentBody}
              </List>
            </Card> 
    //<div className={'row start-xs start-sm start-md start-lg'}>{recentBody}</div>;
  }
}


export default withRouter(RecentItemsHome);