
import firebase from 'firebase/app';
import 'firebase/database';
import { getHotItem, setHotItem } from '../storage/storage';
import DataManagementStore from '../DataManagementStore';

var db = firebase.database();
var eventDbRef = null;

export async function updateTimeline(currentTimeline, userId, partitionId){
  if(!currentTimeline || !currentTimeline.lastEventId){
    //console.log('sem timeline');
    var events = await getTimeline(userId, partitionId);
    var eventsArray = [], lastEventId;
    events.forEach( event => {
      eventsArray.push(event.val());
      lastEventId = event.key;
    });
    return {
      events: eventsArray,
      lastEventId: lastEventId
    }
  } else {
    //console.log('habemus timeline!');
    var eventsArray = currentTimeline.events; // not yet updated really, but..
    var events = await getTimeline(userId, partitionId, currentTimeline.lastEventId);
    if(events.numChildren() < 2){
      //console.log('no new events');
      return currentTimeline;
    }
    //console.log('new events:', events.val());
    var lastEventId;
    var first = true;
    events.forEach( event => {
      if(first == true){
        first = false;
      } else {
        lastEventId = event.key;
        eventsArray.push(event.val());
      }
    });
    var timeline = {
      events: eventsArray,
      lastEventId: lastEventId
    };
    //console.log('all events:', eventsArray);
    setHotItem('timeline', timeline);
    return timeline;
  }
}

function getTimeline(userId, partitionId, from){
  return new Promise(resolve => {
    //console.log('from', from);
    if(from && from != undefined && from != null){
      db.ref('/userTimeline/' + partitionId + '/' + userId)
        .orderByKey()
        .startAt(from)
        .once('value')
        .then( snapshot => {
          resolve(snapshot);
        });
    } else {
      db.ref('/userTimeline/' + partitionId + '/' + userId)
        .once('value')
        .then( snapshot => {
          resolve(snapshot);
        });
    }
  });
}

export function listenToUserTimeline(userId, partitionId){

  var { events, lastEventId } = getHotItem('timeline');
  
  if(lastEventId){
    eventDbRef = db.ref('/userTimeline/' + partitionId + '/' + userId)
                 .orderByKey()
                 .startAt(lastEventId);
  } else {
    eventDbRef = db.ref('/userTimeline/' + partitionId + '/' + userId)
                   .orderByKey();
  }
  
  eventDbRef.on('child_added', (child) => {
    var key = child.key;
    if(key > lastEventId){
      var newEvent = child.val();
      //console.info('NEW TIMELINE EVENT', newEvent);
      events[key] = newEvent;
      var timeline = { events: events, lastEventId: key };
      setHotItem('timeline', timeline);
      DataManagementStore.addRecentItem(newEvent, true);
    }
  });
}