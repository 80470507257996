import { fromJS, List } from 'immutable';

import { 
  USER_DATA_LOADED, 
  REFRESH_AUTH, 
  LOGOUT_SUCCESS, 
  RECENT_ITEM_TYPE_LOADED, 
  RECENT_ITEM_TYPE_ADDED, 
  SIZESTABLE_RENAMED, 
  PROFILE_CONFIGS_LOADED, 
  USER_CONFIG_SET,
} from '../constants';

var defaultState = fromJS({
  recent_sizestables_loading: true,
  recent_projects_loading: true,
  recent_bases_loading: true,
  recent_fabric_loading: true,
  configs: null,

});


export default function userProfile(state = defaultState, action) {

    //console.log(state, action);

    if(!action) return state;

    var d = action.data;

    switch (action.type) {

      case USER_DATA_LOADED:
        return action.data.user;


      case REFRESH_AUTH:
        return action.data;

      case LOGOUT_SUCCESS:
        return fromJS({});
      
      case RECENT_ITEM_TYPE_LOADED:
        var data = {};
        data['recent_' + d.type + '_loading'] = false;
        data['recent_' + d.type] = List(d.items);
        return state.merge(data);
      
      case RECENT_ITEM_TYPE_ADDED:
        return state;

      case SIZESTABLE_RENAMED:
        return state.merge({
          recent_sizestables_loading: true,
          recent_sizestables: List([])
        });

      case PROFILE_CONFIGS_LOADED:
        var cfg = d || {};
        return state.set('configs', fromJS(cfg));

      case USER_CONFIG_SET:
        return state.setIn(['configs', d.path], d.value);

      default:
        return state;
    }
}
