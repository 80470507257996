/**
 * Asynchronously loads the component for HomePage
 */
import React, { lazy, Suspense } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const LazyAdministrationComp = lazy(() => import('./index'));

export default function (){
  return (
    <Suspense fallback={<LoadingIndicator width={250} message={'Carregando área de administração...'} />}>
      <LazyAdministrationComp />
    </Suspense>
  );
};
