/*
 this file is imported in theme-modeling to be part of the material-ui theme
 and allow to use styled component with the same pattern
*/

const themeModelingAppUi =  {

  light: {

    name: 'light',
    background: '#f8f8f8',

    tracingAreaStroke: '#C7C7C7',
    tracingNameBackground: 'white',
    tracingNameColor: 'black',
    tracingHoverBackground: '#00000010',
    tracingHoverOpacity: 1,

    rectangleBackground: '#fffefc',
    rectangleShadow: false,
    rectangleStroke: '#DDD',

    auxLinesStroke: 'grey',

    guidelinesStroke: '#61a8fd',
    guidelinesHoverBackground: '#DDD',
    guidelinesBarBackground: '#ffe566',

    shapesFill: '#fffbec',
    shapesHighlightFill: '#99ff7c',
    shapesFillHover: '#eeffe9',
    shapesFillSelected: '#ceeeff',
    shapesFillDragging: '#fbfbc8',
    shapesOpacity: 0.75,
    shapesShadow: false,
    shapesLinesStroke: 'black',
    shapesNameColor: 'black',
    
    auxPointsColor: '#808080',
    innerPointsColor: '#b400ff',
    guidelinesVerticesColor: '#61a8fd',
    pointsColor: '#222222',
    pointsSelectedColor: '#00ffdb',
    pointsHoverColor: '#cbff00',
    curvePointsColor: '#1e88e5',
    pointsExtraToolsColor: '#ef0079',
    dynamicPencePointsColor: '#e09b00',
    targetPointColor: 'black',

    linesHoverColorHalo: '#cbff00',
    linesHoverColor: '#00ff42',
    linesSelectedColorHalo: '#86ffff',
    linesSelectedColor: '#ef0079',
    linesHintColor: '#ef0079',

    metricTapeBackground: '#ffe566',
    metricTapeStroke: 'black',

    lineStretchLengthStroke: '#673ab7',
    lineStretchDeltaStroke: '#009688',

    intersectionsHover: '#00ff42',

    // pencesColor: '#ffc800',
    // #d56c6c
    pencesColor: "#d56c6c",

    distanceLineHelperColor: 'black',
    distanceTextColor: 'black',

    coordinatesTextColor: 'black',

    ortogonaDistancelLineHelperColor: 'black',

    angleTextColor: 'black',
    anglePathFillColor: '#CCC',
    anglePathFillOpacity: 0.4,

    perpendicularHelperColor: '#90CAF9',
    intersectionCircleColor: '#90CAF9',
    projectionIndicatorColor: '#90CAF9',
    snapIndicatorColor: '#90CAF9',
    lineSnapIndicatorColor: '#90CAF9',

    newCircleHelperColor: 'blue'
  },

  dark: {

    name: 'dark',
    background: 'black',

    tracingAreaStroke: '#555',
    tracingNameBackground: 'black',
    tracingNameColor: 'white',
    tracingHoverBackground: '#FFFFFF25',
    tracingHoverOpacity: 1,

    rectangleBackground: '#222',
    rectangleShadow: false,
    rectangleStroke: '#333',

    auxLinesStroke: '#DDD',

    guidelinesStroke: '#666',
    guidelinesHoverBackground: 'red',
    guidelinesBarBackground: '#ffe566',
    guidelinesBarStroke: 'black',

    shapesFill: '#2b353e',
    shapesHighlightFill: 'red',
    shapesOpacity: 0.75,
    shapesShadow: false,
    shapesLinesStroke: 'white',
    shapesNameColor: 'white',
    
    auxPointsColor: 'grey',
    pointsColor: 'yellow',
    pointsSelectedColor: '#00ffdb',
    pointsHoverColor: '#cbff00',
    curvePointsColor: 'lime',
    pointsExtraToolsColor: 'red',

    linesHoverColorHalo: 'red',
    linesHoverColor: 'white',
    linesSelectedColorHalo: 'yellow',
    linesSelectedColor: 'white',
    linesHintColor: '#666',

    curvesHoverColor: 'red',

    metricTapeBackground: '#ffe566',
    metricTapeStroke: 'black',

    lineStretchLengthStroke: 'lime',
    lineStretchDeltaStroke: 'purple'
  }

}

export default themeModelingAppUi;