import { TextField, Icon, IconButton, Typography, CircularProgress, Button, Tooltip } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import emailValidator from 'email-validator';
import { userCan } from '../../../../../../../../commons/Permissions';

function NameField(props){
  const
    [ email, setEmail ] = useState(props.email || ''),
    [ editing, setEditing ] = useState(false),
    [ askPassword, setAskPassword ] = useState(false),
    [ password, setPassword ] = useState(''),
    [ loading, setLoading ] = useState(false),
    [ result, setResult ] = useState(null),
    undoEditing = () => {
      setEmail(props.email || '');
      setEditing(false);
      setAskPassword(false);
    },
    passwordStep = () => {
      setAskPassword(true);
      setEditing(false);
    },
    sendStep = () => {
      if(!userCan('changeOwnEmail')){
        return;
      }
      setAskPassword(false);
      setEditing(false);
      setLoading(true);
      props.actions.updateUserEmail( 
        email, 
        password, 
        result => {
          setLoading(false);
          setResult(result);
        }
      );
    };
  return (
    <Fragment>
      <TextField 
        label={'E-mail'}
        value={email}
        disabled={!editing}
        onChange={(e) => setEmail(e.target.value)}
        style={{width: 300}}
      />
      {editing 
        ? <Fragment>
            <IconButton 
              color={'primary'}
              disabled={email === '' || email === props.email || emailValidator.validate(email) === false}
              onClick={passwordStep}
            >
              <Icon>check</Icon>
            </IconButton>
            <IconButton 
              color={'primary'}
              onClick={() => undoEditing()}
            >
              <Icon>clear</Icon>
            </IconButton>
          </Fragment>
        : askPassword
          ? <Fragment>
              <br />
              <TextField
                type={'password'}
                focused
                label={'Senha de acesso à MOU'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{width: 300, marginTop: 10}}
              />
              <Typography variant={'body2'}>Para continuar, digite sua senha
                <IconButton 
                  color={'primary'}
                  onClick={sendStep}
                  disabled={password.length === 0}
                >
                  <Icon>check</Icon>
                </IconButton>
                <IconButton 
                  color={'primary'}
                  onClick={() => undoEditing()}
                >
                  <Icon>clear</Icon>
                </IconButton>
              </Typography>
            </Fragment>
          : loading 
            ? <CircularProgress size={20} style={{marginTop: 20, marginLeft: 16}} />
            : result === null 
              ? <Tooltip
                  title={
                    userCan('changeOwnEmail')
                      ? 'Clique para alterar seu e-mail'
                      : 'Você não tem permissão para alterar seu email. Entre em contato com os administradores de sua conta.'
                  }
                >
                  <span>
                    <IconButton 
                      color={'primary'}
                      onClick={() => setEditing(true)}
                      disabled={!userCan('changeOwnEmail')}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </span>
                </Tooltip>
              : <Fragment>
                  <Typography variant={'body2'}>
                    {
                      { 
                        'success': 'Seu e-mail foi alterado com sucesso',
                        'wrong-password': 'Senha incorreta',
                        'error': 'Não foi possível alterar seu e-mail. Por favor, tente novamente mais tarde.'
                      }[result]
                    }
                    <Button 
                      color={'primary'} 
                      size={'small'} 
                      onClick={() => { setResult(null)}}
                    >OK
                    </Button>
                  </Typography>
                </Fragment>
      }
    </Fragment>
  );
}

export default NameField;