/* eslint-disable */

import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './containers/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Needed for redux - saga es6 generator support
// Import all the third party stuff

import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import { Router } from 'react-router-dom';
// Import root app
// Load the favicon
/* eslint-disable import/no-webpack-loader-syntax */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
/* eslint-enable import/no-webpack-loader-syntax */
import "flexboxgrid/dist/flexboxgrid.min.css";
import configureStore from './configureStore';
import './i18n';
//import CurrentEnvironment from './commons/CurrentEnvironment';
// LogRocket incluído e inicializado o quanto antes
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import fs from 'fs'
import Courier from '!!raw-loader!pdfkit/js/data/Courier.afm'
import Helvetica from '!!raw-loader!pdfkit/js/data/Helvetica.afm'

require ("./commons/polyFills/replaceAllPolyFill");

fs.writeFileSync('data/Courier.afm', Courier);
fs.writeFileSync('data/Helvetica.afm', Helvetica)


if(process.env.FIREBASE_ENV === 'prod'){
  let 
    version = '0.12.0';
  LogRocket.init(
    'mou/mou-web-app', 
    {
      release: version
    }
  );
  setupLogRocketReact(LogRocket);
}


// Create redux store with history
const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);

ReactDOM.render(

    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();