import itemsColors from './mainColors';

import MouPalettes from './palettes';

const fabricTheme =  {
  typography: {
    //useNextVariants: true,
    fontFamily: [
      'monospace'
    ].join(',')
  },
  palette: {
    primary: MouPalettes.fabrics,
    secondary:  {
      main: '#efb000',
      contrastText: "#ffffff" 
    }
  },
  itemHomeTopContentBg: '#585858',
  itemsColors
};

export default fabricTheme;