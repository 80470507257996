import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';

import NestingTableIcon from '../../mou-icons/NestingTableIcon';


const styles = {
  nestingTableAvatar: { 
    background: palettes.nestingTables.main
  },
  nestingTableIcon: {
    width:34, 
    height:34, 
    stroke: palettes.nestingTables.contrastText, 
    strokeWidth: 5, 
    marginTop: 9
  },
}
const NestingTableAvatar = (props) => <Avatar 
                                        className={props.classes.nestingTableAvatar} 
                                        size={props.size} 
                                        style={props.style}
                                      >
                                        <NestingTableIcon className={props.classes.nestingTableIcon}  />
                                      </Avatar>

export default withStyles(styles)(NestingTableAvatar);