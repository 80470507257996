import itemsColors from './mainColors';

const 
  { 
    accountSizesTables, userNestings, adminColor, nestingTables, fabrics, printCenterColor,
    mouRed, mouRedLight, mouYellow
  } = itemsColors,
  palettes =  {
    mouRed: {
      50: mouRed,
      100: mouRed,
      200: mouRed,
      300: mouRed,
      400: mouRed,
      500: mouRed,
      600: mouRed,
      700: mouRed,
      800: mouRed,
      900: mouRed,
      A100: mouRed,
      A200: mouRed,
      A400: mouRed,
      A700: mouRed,
      main: mouRed,
      light: mouRed,
      dark: mouRed,
      contrastText: "#ffffff"
    },
    mouRedLight: {
      50: mouRedLight,
      100: mouRedLight,
      200: mouRedLight,
      300: mouRedLight,
      400: mouRedLight,
      500: mouRedLight,
      600: mouRedLight,
      700: mouRedLight,
      800: mouRedLight,
      900: mouRedLight,
      A100: mouRedLight,
      A200: mouRedLight,
      A400: mouRedLight,
      A700: mouRedLight,
      main: mouRedLight,
      light: mouRedLight,
      dark: mouRedLight,
      contrastText: "#ffffff"
    },
    mouGreen: {
      50: accountSizesTables,
      100: accountSizesTables,
      200: accountSizesTables,
      300: accountSizesTables,
      400: accountSizesTables,
      500: accountSizesTables,
      600: accountSizesTables,
      700: accountSizesTables,
      800: accountSizesTables,
      900: accountSizesTables,
      A100: accountSizesTables,
      A200: accountSizesTables,
      A400: accountSizesTables,
      A700: accountSizesTables,
      main: accountSizesTables,
      light: accountSizesTables,
      dark: accountSizesTables,
      contrastText: "#ffffff"
    },
    mouYellow: {
      50: mouYellow,
      100: mouYellow,
      200: mouYellow,
      300: mouYellow,
      400: mouYellow,
      500: mouYellow,
      600: mouYellow,
      700: mouYellow,
      800: mouYellow,
      900: mouYellow,
      A100: mouYellow,
      A200: mouYellow,
      A400: mouYellow,
      A700: mouYellow,
      main: mouYellow,
      light: mouYellow,
      dark: mouYellow,
      contrastText: "#ffffff"
    },
    nesting: {
      50: userNestings,
      100: userNestings,
      200: userNestings,
      300: userNestings,
      400: userNestings,
      500: userNestings,
      600: userNestings,
      700: userNestings,
      800: userNestings,
      900: userNestings,
      A100: userNestings,
      A200: userNestings,
      A400: userNestings,
      A700: userNestings,
      main: userNestings,
      light: userNestings,
      dark: userNestings,
      contrastText: "#ffffff"
    },
    nestingTables: {
      50: nestingTables,
      100: nestingTables,
      200: nestingTables,
      300: nestingTables,
      400: nestingTables,
      500: nestingTables,
      600: nestingTables,
      700: nestingTables,
      800: nestingTables,
      900: nestingTables,
      A100: nestingTables,
      A200: userNestings,
      A400: nestingTables,
      A700: nestingTables,
      main: nestingTables,
      light: nestingTables,
      dark: nestingTables,
      contrastText: "#ffffff"
    },
    fabrics: {
      50: fabrics,
      100: fabrics,
      200: fabrics,
      300: fabrics,
      400: fabrics,
      500: fabrics,
      600: fabrics,
      700: fabrics,
      800: fabrics,
      900: fabrics,
      A100: fabrics,
      A200: fabrics,
      A400: fabrics,
      A700: fabrics,
      main: fabrics,
      light: fabrics,
      dark: fabrics,
      contrastText: "#ffffff"
    },
    administration: {
      50: adminColor,
      100: adminColor,
      200: adminColor,
      300: adminColor,
      400: adminColor,
      500: adminColor,
      600: adminColor,
      700: adminColor,
      800: adminColor,
      900: adminColor,
      A100: adminColor,
      A200: adminColor,
      A400: adminColor,
      A700: adminColor,
      main: adminColor,
      light: adminColor,
      dark: adminColor,
      contrastText: "#ffffff"
    },
    printCenter: {
      50: printCenterColor,
      100: printCenterColor,
      200: printCenterColor,
      300: printCenterColor,
      400: printCenterColor,
      500: printCenterColor,
      600: printCenterColor,
      700: printCenterColor,
      800: printCenterColor,
      900: printCenterColor,
      A100: printCenterColor,
      A200: printCenterColor,
      A400: printCenterColor,
      A700: printCenterColor,
      main: printCenterColor,
      light: printCenterColor,
      dark: printCenterColor,
      contrastText: "#ffffff"
    },
  };

export default palettes;