/**
 * This is a event handler used to create some attribute Type off the current bucket of an item(file)
 * @function
 * @param  {object} data - userProfile Data object, local 
 * @param  {object} payload - payload content is data sent by the action, item id, attribute information
 * @param  {string} date - current date
 * @param  {string} user - current user
 * @param  {boolean} isListening
 * @returns {data} user profile Data, local
 */
import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateAttributeType(data, payload, date, user, isListening){
  var bucketName = '';
  switch(payload.bucketKey){
    case 'userProjects':
      bucketName = 'projects';
      break;
    case 'userBases':
      bucketName = 'bases';
      break;
    case 'accountSizesTables':
      // bucketName = 'sizetables'; //this was causing bugs about correct collection selection
      bucketName = 'sizestables';

      break;
    default:
      bucketName = payload.bucketKey;
  }
  if(!data.attrsTypes[bucketName]){
    data.attrsTypes[bucketName]={};
  };
  var order = Object.keys(data.attrsTypes[bucketName]).length;
  
  data.attrsTypes[bucketName][payload.id] = {
    name: payload.name,
    order: order,
    hasDetails:payload.hasDetails
  }

  if(isListening){
    // live side effects
    DataManagementStore.addCollectionAttributeTypeToBucket({
      bucketKey: payload.bucketKey,
      attributeTypeData: {
        name: payload.name,
       	order: order,
        hasDetails:payload.hasDetails
      },
      attributeTypeId: payload.id
    });
  }

  return data;
};