import itemsColors from './mainColors';

import MouPalettes from './palettes';

import { Museo, MuseoBold } from './fonts'; 


const nestingTheme = {
  typography: {
    //useNextVariants: true,
    fontFamily: [
      'Museo'
    ].join(',')
  },
  palette: {
    primary: MouPalettes.mouYellow,
    secondary:  MouPalettes.mouYellow
  },
  itemHomeTopContentBg: '#585858',
  itemsColors,

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Museo,MuseoBold],
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 15
      }
    }
  }
};

export default nestingTheme;