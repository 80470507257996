import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';

import FabricIcon from '../../mou-icons/FabricIcon';

const styles = {
  fabricAvatar: { 
    background: palettes.fabrics.main
  },
  fabricIcon: {
    width:40, 
    height:40, 
    stroke: palettes.fabrics.contrastText, 
    strokeWidth: 5, 
    marginTop: 9,
    marginLeft: 4
  },
}

const FabricAvatar = (props) => <Avatar 
                                  className={props.classes.fabricAvatar} 
                                  size={props.size} 
                                  style={props.style}
                                >
                                  <FabricIcon className={props.classes.fabricIcon}  />
                                </Avatar>

export default withStyles(styles)(FabricAvatar);