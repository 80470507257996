import i18n from 'i18next';
import LocizeBackend from 'i18next-locize-backend';
//import LocizeEditor from 'locize-editor';
//import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

//console.log('i18n loading...');

i18n
  .use(LocizeBackend)
  //.use(LocizeEditor)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt-BR',
    appendNamespaceToCIMode: true,
    saveMissing: true,

    // have a common namespace used around the full app
    ns: [
      'global',
      'dashboard',
      'modelingInstructions',
      'modelingMeasures',
      'modelingUI'
    ],
    defaultNS: 'global',

    debug: false,
    //keySeparator: '### not used ###', // we use content as keys
    //nsSeparator: '### not used ###', // we use content as keys

    backend: {
      projectId: '82df113b-3151-4d1c-b71f-ba887eb3a001', // <-- replace with your projectId
      apiKey: '8a7271e8-4815-4731-a6d9-6193d74482dc',
      referenceLng: 'pt-BR'
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },

    react: {
      wait: true,
      useSuspense: false
    },

    editor: {
      // trigger a reload on editor save
      onEditorSaved: function(lng, ns) {
        i18n.reloadResources(lng, ns);
      }
    }
  });


export default i18n;