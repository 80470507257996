/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { 
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import Main from '../Main';
import MobilePage from '../MobilePage';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import MouMuiTheme from '../../mou-mui-themes/theme-main';
import { CssBaseline } from '@material-ui/core';
import {isMobile} from 'react-device-detect';

const theme = createTheme(MouMuiTheme);

const App = () => {

if(isMobile) return <MobilePage/>
return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Redirect from='/app' to={'/'} exact />
          <Redirect from='/app/*' to={'/*'} />
          <Route path='/' component={Main} />
        </Switch>
      </MuiThemeProvider>
      
);
}
export default App;