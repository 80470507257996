/**
 * Asynchronously loads the component for HomePage

import Loadable from 'react-lo adable';

import LoadingIndicator from 'components/LoadingIndicator';

export default Loadable({
  loader: () => import('./index'),
  loading: LoadingIndicator,
});
 */

/**
 * Asynchronously loads the component for Production
 */
 import React, { lazy, Suspense } from 'react';

 import LoadingIndicator from '../../components/LoadingIndicator';

 const LazyMainComp = lazy(() => import('./index'));
 
 export default function (){
   return (
     <Suspense fallback={<LoadingIndicator width={250} message={'Carregando área de produção...'} />}>
       <LazyMainComp />
     </Suspense>
   );
 };