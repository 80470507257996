import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    //background: 'fuchsia',
    [theme.breakpoints.up('xs')]: {
      width: 300
    },
    [theme.breakpoints.up('sm')]: {
      width: 740
    },
    [theme.breakpoints.up('md')]: {
      width: 930
    },
    [theme.breakpoints.up('lg')]: {
      width: 1128
    }
  },
}));

export const ResponsiveWrapper = (props) => {
  const
    classes = useStyles()
  return(
    <div className={props.disabled == true ? '' : classes.root}>
      {props.children}
    </div>
  )
}

export default ResponsiveWrapper;