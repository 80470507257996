import DataManagementStore from '../../../commons/DataManagementStore';

export default function SetProjectAttributes(data, payload, date, user, isListening){

    var itemId = payload['id'];
    if(itemId){

        var projects = data['projects'];
        var currentCollections = projects[itemId]['collections'];
        var updatedCollections = payload['collections'];
        currentCollections = payload['collections'];

        if(isListening){
            DataManagementStore.updateItem(itemId,payload,false)
        };
    }
    return data;

};