import React from 'react';
import { withRouter, browserHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DataManagementStore from '../../../../commons/DataManagementStore';
import CircularProgress from '@material-ui/core/CircularProgress';

class Share extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      itemInfos: null,
      purpose: 'getItemInfos',
    };
    this.onCancel = this.onCancel.bind(this);
    this.onImport = this.onImport.bind(this);
  }

  componentDidMount(){
    var 
      { type, match } = this.props;
    this.props.actions.getSharedItemInfos(
      match.params.id, 
      type, 
      result => {
        if(result){
          this.setState({
            ...this.state,
            loading: false,
            purpose: 'getItemInfos',
            itemInfos: result.data,

          })
        }
      },
      this.props.purpose
    );
  }

  onCancel(){
    this.props.history.replace('/app', null);
  }

  onImport(){
    var 
      { type, match } = this.props;
    this.setState(
      {
        ...this.state,
        loading: true,
        purpose: 'importing'
      },
      () => {
        this.props.actions.importSharedItem(
          match.params.id, 
          type, 
          result => {
            var links = {
              bases: '/creation/base/' + result.id + '/modeling',
              projects: '/creation/project/' + result.id + '/modeling',
              sizestables: '/creation/sizes-table/' + result.id
            }
            this.props.history.replace(links[type], null);
          },
          this.props.purpose
        );
      }
    );
    
  }

  render(){
    // hideInitialLoader();
    var 
      { loading, itemInfos, purpose } = this.state,
      content = null, 
      actions = null;
    if(loading == true){
      var text;
      if(purpose == 'getItemInfos'){
        text = 'Obtendo informações do arquivo...';
      } else if(purpose == 'importing'){
        text = 'Importando arquivo...';
      }
      content = <div className='row center-xs'>
                  <div className='col-xs-12' style={{textAlign: 'center'}}>
                    <CircularProgress />
                  </div>
                  <div className='col-xs-12' style={{textAlign: 'center'}}>
                    {text}
                  </div>
                </div>;
    } else {
      if(!itemInfos || itemInfos.success === false){
        content = <DialogContentText>Arquivo compartilhado não encontrado</DialogContentText>;
        actions = <DialogActions>
                    <Button color={'primary'} onClick={this.onCancel}>Fechar</Button>
                  </DialogActions>;
      } else {
        var 
          itemsName = {
            'bases': 'a base',
            'projects': 'a modelagem',
            'sizestables': 'a tabela de medidas'
          }[ this.props.match.path.split('/')[2] ];
        content = <DialogContentText>Deseja importar {itemsName} <strong>"{itemInfos.name}"</strong>?</DialogContentText>;
        actions = <DialogActions>
                    <Button color={'primary'} onClick={this.onCancel}>Não</Button>
                    <Button color={'secondary'} onClick={this.onImport}>Sim</Button>
                  </DialogActions>;
      }
    }
    
    return ( 
      <Dialog open={true}>
        <DialogTitle>Importar arquivo compartilhado por outros</DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
        {actions}
      </Dialog>
    );
  }
}

export default withRouter(Share);