import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';

import NestingIcon from '../../mou-icons/NestingIcon';

const styles = {
  nestingAvatar: { 
    background: palettes.mouYellow.main
  },
  nestingIcon: {
    width:34, 
    height:34, 
    stroke: palettes.nesting.contrastText, 
    strokeWidth: 5, 
    marginTop: -2
  },
}

const NestingAvatar = (props) =>  <Avatar 
                                    className={props.classes.nestingAvatar} 
                                    size={props.size} 
                                    style={props.style}
                                  >
                                    <NestingIcon className={props.classes.nestingIcon}  />
                                  </Avatar>

export default withStyles(styles)(NestingAvatar);