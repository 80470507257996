import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/functions';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const FBFgetWorkspaceSendUserUrl = firebase.functions().httpsCallable('getWorkspaceSendUserUrl');

export default function WorkspaceSendRedirect(props){

  var 
    [ url, setUrl] = useState(null),
    [ status, setStatus ] = useState('loading');
  
  function getUrl(){
    // hideInitialLoader();
    //console.log('getting', props.match.params.id);
    FBFgetWorkspaceSendUserUrl({ sendId: props.match.params.id }).then( ({data}) => {
      //console.log(data);
      if(!data){
        setStatus('error');
      } else {
        setUrl(data);
      }
    })
  }
  

  useEffect( getUrl, []);

  return url 
          ? <Redirect to={url} /> 
          : status == 'loading'
            ? <Box textAlign='center'>
                <CircularProgress />
                <br />
                <Typography variant={'body1'}>
                  Redirecionando para o seu arquivo...
                </Typography>
              </Box>
            : <Box textAlign='center'>
                <Typography variant={'body1'}>
                  O link informado é inválido para o seu usuário
                </Typography>
              </Box>;
}