import DataManagementStore from '../../../commons/DataManagementStore';
/**
 * This is a event handler used to remove some attribute Type off the current bucket of an item(file)
 * @method
 * @param  {object} data data - userProfile Data, local 
 * @param  {object} payload - data sent by actions
 * @param  {string} date - current date
 * @param  {string} user - current user
 * @param  {boolean} isListening
 * @returns {data} user profile Data, local
 */
export default function RemoveAttributeType(data, payload, date, user, isListening){
  
  var bucketName = '';
  switch(payload.bucketKey){
    case 'userProjects':
      bucketName = 'projects';
      break;
    case 'userBases':
      bucketName = 'bases';
      break;
    case 'accountSizesTables':
      // bucketName = 'sizetables';
      bucketName = 'sizestables';

      break;
    default:
      bucketName = payload.bucketKey;
  }
  // var order = Object.keys(data.attrsTypes[payload.bucketKey]).length;
  var attrKeys = Object.keys(data.attrs[bucketName]);
  for(var id in attrKeys){
    var key = attrKeys[id];
    if(data.attrs[bucketName][key].type == payload.type){
      delete data.attrs[bucketName][key].order;
      if(isListening){
        DataManagementStore.deleteAttributeItem(key, true);
      }
    }
  }

  var order = data.attrsTypes[bucketName][payload.id].order;
  var keys = Object.keys(data.attrsTypes[bucketName]);
  for(var id in keys){
    var key = keys[id];
    if(order < data.attrsTypes[bucketName][key].order){
      var itemOrder = data.attrsTypes[bucketName][key].order;
      data.attrsTypes[bucketName][key].order = data.attrsTypes[bucketName][key].order -1;
      if(isListening){
        DataManagementStore.updateItem(key, data.attrsTypes[bucketName][key],true);
      }
    }
  }

  delete data.attrsTypes[bucketName][payload.id];

  if(isListening){
    // live side effects
    DataManagementStore.deleteAttributeTypesItem(payload.id);
  }

  return data;
};