import { Typography, makeStyles, Button, Icon } from '@material-ui/core';
import React from 'react';
import EmailField from './components/EmailField';
import NameField from './components/NameField';
import PassswordField from './components/PasswordField/PasswordField';
import UserAvatarField from './components/UserAvatarField';

const
  useStyles = makeStyles( theme => ({
    userInfos: {
      display: 'flex',
      marginTop: 20
    },
    userPhotoWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: 200,
      justifyContent: 'flex-start'
    },
    userPhoto: {
      maxWidth: 200,
      maxHeight: 200,
      minWidth: 48,
      minHeight: 48,
      background: '#EEE'
    },
    userForm: {
      marginLeft: 20
    },
    nameField: {
      marginTop: 0
    },
    emailField: {
      marginTop: 20
    },
    passwordField: {
      marginTop: 20
    }
  }));

function AccountTab(props){
  const
    classes = useStyles(),
    user = props.auth.get('user');
  return (
    <div>
      <Typography variant={'body1'} gutterBottom>Aqui você pode verificar e alterar seus dados pessoais e de acesso.</Typography>
      <div className={classes.userInfos}>
        <div className={classes.userPhotoWrap}>
          <UserAvatarField user={user} classes={classes} actions={props.actions} />
        </div>
        <div className={classes.userForm}>
          <div className={classes.nameField}>
            <NameField 
              displayName={user.get('displayName')} actions={props.actions} 
            />
          </div>
          <div className={classes.emailField}>
            <EmailField 
              email={user.get('email')}
              actions={props.actions} 
            />
          </div>
          <div className={classes.passwordField}>
            <PassswordField 
              actions={props.actions} 
            />
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default AccountTab;