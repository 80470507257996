import React from "react";

export default function LoadingImgUri(props){
  const
    fill = props.fill || '#CC6666';
  return  <svg viewBox="0 0 305 90" width="305" height="90">
            <path 
              d=" M 152.501 0 C 130.134 0 112.001 18.132 112.001 40.499 C 112.001 62.867 130.134 80.999 152.501 80.999 C 174.868 80.999 193 62.867 193 40.499 C 193.001 29.758 188.734 19.456 181.139 11.861 C 173.544 4.266 163.242 -0.001 152.501 0 L 152.501 0 L 152.501 0 Z  M 152.501 63.626 C 139.729 63.626 129.375 53.272 129.375 40.499 C 129.375 27.727 139.729 17.373 152.501 17.373 C 165.273 17.373 175.627 27.727 175.627 40.499 C 175.627 53.272 165.273 63.626 152.501 63.626 Z " 
              fill={fill}
            />
            <path 
              d=" M 62.004 24.691 C 49.232 24.691 38.878 14.337 38.878 1.565 L 21.502 1.565 C 21.373 16.12 29.064 29.625 41.648 36.94 C 54.232 44.255 69.773 44.255 82.357 36.94 C 94.941 29.625 102.632 16.12 102.504 1.565 L 85.13 1.565 C 85.13 14.337 74.777 24.691 62.004 24.691 Z " 
              fill={fill}
              style={{opacity: 0.75}}
            />
            <path 
              d=" M 85.1 1.6 L 102.5 1.6 L 102.5 79.5 L 85.1 79.5 L 85.1 1.6 Z "
              fill={fill}
            />
            <path 
              d=" M 21.5 1.6 L 38.9 1.6 L 38.9 79.5 L 21.5 79.5 L 21.5 1.6 Z " 
              fill={fill}
            />
            <path 
              d=" M 266.117 1.562 L 266.117 40.499 L 266.117 40.499 C 266.117 53.272 255.763 63.626 242.991 63.626 C 230.219 63.626 219.865 53.272 219.865 40.499 L 219.865 40.499 L 219.865 1.562 L 202.498 1.562 L 202.498 40.499 C 202.694 62.728 220.77 80.644 242.999 80.644 C 265.228 80.644 283.303 62.728 283.5 40.499 L 283.5 1.562 L 266.117 1.562 L 266.117 1.562 Z " 
              fill={fill}
            />
          </svg>
}