import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Avatar  from '@material-ui/core/Avatar';
import AppBar  from '@material-ui/core/AppBar';
import Icon  from '@material-ui/core/Icon';
//import Drawer  from '@material-ui/core/Drawer';
import Popover from '@material-ui/core/Popover';
import Typography  from '@material-ui/core/Typography';
import Divider  from '@material-ui/core/Divider';

import BreadCrumb from './Utils/BreadCrumb';
import UserIconMenu from './Utils/UserIconMenu';
//import LogoImg from "images/mou_logo_header.png";
import HeaderController from './Utils/HeaderController';
import ResponsiveWrapper from './ResponsiveWrapper';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import MouMuiTheme from '../mou-mui-themes/theme-main';
import MouMuiThemeModeling from '../mou-mui-themes/theme-modeling';
import MouMuiThemeSizesTables from '../mou-mui-themes/theme-sizesTables';
import MouMuiThemeNesting from '../mou-mui-themes/theme-nesting';
import MouMuiThemeNestingTables from '../mou-mui-themes/theme-nestingTables';
import MouMuiThemeFabrics from '../mou-mui-themes/theme-fabrics';
import MouMuiThemeAdmin from '../mou-mui-themes/theme-administration';
import MouMuiThemePrintCenter from '../mou-mui-themes/theme-printCenter';


import MouIcon from './mou-icons/MouIcon';

import BaseAvatar from './Utils/Avatar/BaseAvatar';
import ProjectAvatar from './Utils/Avatar/ProjectAvatar';
import SizesTableAvatar from './Utils/Avatar/SizesTableAvatar';
import PrintCenterAvatar from './Utils/Avatar/PrintCenterAvatar';

import NestingAvatar from './Utils/Avatar/NestingAvatar';
import NestingTableAvatar from './Utils/Avatar/NestingTableAvatar';
import FabricAvatar from './Utils/Avatar/FabricAvatar';

import AdminUserAvatar from './Utils/Avatar/AdminUserAvatar';

import { userCan } from '../commons/Permissions';
import mainColors from '../mou-mui-themes/mainColors';
import { Card } from '@material-ui/core';
import SubscriptionAlert from './SubscriptionAlert';

const 
  theme = createTheme(MouMuiTheme),
  modelingTheme = createTheme(MouMuiThemeModeling),
  sizesTablesTheme = createTheme(MouMuiThemeSizesTables),
  nestingTheme = createTheme(MouMuiThemeNesting),
  nestingTableTheme = createTheme(MouMuiThemeNestingTables),
  fabricTheme = createTheme(MouMuiThemeFabrics),
  adminTheme = createTheme(MouMuiThemeAdmin),
  printCenterTheme= createTheme(MouMuiThemePrintCenter),
  avatarStyle = { width: 55, height: 55, background: '#CC6666', marginLeft: 4 },
  iconStyle = { fontSize: '33px' },
  MouAvatar = <MouIcon width={178} height={55} colorScheme={'red'} />,
  sizesTablesLogo = <SizesTableAvatar style={{ marginTop: 8, marginBottom: 6, width: 52, height: 52}} />,
  mouSizesTableLogo = <MouIcon width={178} height={55} colorScheme={'green'} innerIcon={sizesTablesLogo} />,
  modelingLogo = <ProjectAvatar style={{ marginTop: 8, marginBottom: 6, width: 52, height: 52}} />,
  mouModelingLogo = <MouIcon width={178} height={55} colorScheme={'red'} innerIcon={modelingLogo} />,
  baseLogo = <BaseAvatar size={42} style={{ marginTop: 8, marginBottom: 6, width: 52, height: 52}} />,
  mouBaseLogo = <MouIcon width={178} height={55} colorScheme={'red'} innerIcon={baseLogo} />,
  nestingLogo = <NestingAvatar style={{ marginTop: 8, marginBottom: 6}} />,
  nestingTablesLogo = <Avatar style={avatarStyle}><Icon style={iconStyle}>featured_play_list</Icon></Avatar>,
  fabricsLogo = <Avatar style={avatarStyle}><Icon style={iconStyle}>map</Icon></Avatar>,
  adminLogo = <Avatar style={{...avatarStyle, background: mainColors.adminColor, marginTop: 7, marginLeft: -2}}>
                <Icon style={iconStyle}>vpn_key</Icon>
              </Avatar>,
  mouAdminLogo = <MouIcon width={178} height={55} colorScheme={'lightblue'} innerIcon={adminLogo} />,
  accountLogo = <Avatar style={{...avatarStyle, background: mainColors.mouRed, marginTop: 7, marginLeft: -2}}>
                <Icon style={iconStyle}>account_circle</Icon>
              </Avatar>,
  mouAccountLogo = <MouIcon width={178} height={55} colorScheme={'red'} innerIcon={accountLogo} />,    
  printCenterLogo = <PrintCenterAvatar style={{ marginTop: 8, marginBottom: 6}}  />,
  iconMap = {
    '_home': MouAvatar,
    'profile': mouAccountLogo,
    'subscription': mouAccountLogo,
    'creation': MouAvatar,
    'bases': mouBaseLogo,// baseLogo,
    'base': mouBaseLogo,
    'projects': mouModelingLogo,
    'project': mouModelingLogo,
    'sizes-tables': mouSizesTableLogo,
    'sizes-table': mouSizesTableLogo,
    'production': MouAvatar,
    'nesting': nestingLogo,
    'nestingTables': nestingTablesLogo,
    'fabrics': fabricsLogo,
    'administration': mouAdminLogo,
    'users': mouAdminLogo,
    'workspaces': mouAdminLogo,
    'print-center': printCenterLogo,
    'print': printCenterLogo,

  };

class MainDrawer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      open: false
    }
    this._onClick = this._onClick.bind(this);
    this._onClose = this._onClose.bind(this);
    this.logoRef = React.createRef();
  }

  _onClick(){
    this.setState({...this.state, open: true});
  }

  _onClose(){
    this.setState({...this.state, open: false});
  }

  render(){
    return (
      <Fragment>
        <a style={{cursor: 'pointer', verticalAlign: 'middle', lineHeight: 0}} onClick={this._onClick} ref={this.logoRef}>
          {this.props.children}
        </a>
        <Popover 
          open={this.state.open} 
          onClose={this._onClose} 
          anchorEl={this.logoRef.current} 
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={this.props.page == '_home' ? { marginTop: -49, marginLeft: -15} : {marginTop: -18, marginLeft: -15}}
          elevation={16}
        >
          <div style={{width: 260}}>
            
            <div 
              style={{
                padding: '7px 10px 0px 15px'
              }}
            >
              <div>
                <Link to={'/'} onClick={this._onClose}>
                    <MouIcon width={178} height={55} colorScheme={'red'} />
                </Link>
              </div>
              
            </div>
            
            {/*<Divider />*/}
            
            {/* START CREATION */}
            {userCan('accessCreation') &&
              <Fragment>
                <div className={'row'} style={{padding: '10px 10px 4px 16px'}}>
                  <div className="col-xs-12">
                    <Typography variant={'h6'} style={{fontWeight: 300}}>Criação</Typography>
                  </div>  
                </div>
                <div className={'row'} style={{marginBottom: 10}}>
                  <div className="col-xs-4">
                      <div className={'box'} style={{width: '100%', textAlign: 'center'}}>
                        <Link to="/creation/sizes-tables" onClick={this._onClose}>
                          <SizesTableAvatar style={{margin: 'auto'}} />
                        </Link>
                        <Typography variant={'caption'}>Tabelas</Typography>
                      </div>
                    </div>
                  <div className="col-xs-4">
                    <div className={'box'} style={{width: '100%', textAlign: 'center'}}>
                    <Link to="/creation/bases" onClick={this._onClose}>
                      <BaseAvatar style={{margin: 'auto'}} />
                    </Link>
                    <Typography variant={'caption'}>Bases</Typography>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className={'box'} style={{width: '100%', textAlign: 'center'}}>
                      <Link to="/creation/projects" onClick={this._onClose}>
                        <ProjectAvatar style={{margin: 'auto'}} />
                      </Link>
                      <Typography variant={'caption'}>Modelagens</Typography>
                    </div>
                  </div>
                  
                </div>
              </Fragment>
            }
            {/* END CREATION */}


            {/* START PRODUCTION * /}
            {userCan('accessProduction') && 
              <Fragment>
                <div className={'row'} style={{padding: '20px 10px 4px 16px'}}>
                  <div className="col-xs-12">
                    <Typography variant={'h6'} style={{fontWeight: 300}}>Produção</Typography>
                  </div>  
                </div>
                <div className={'row'}>
                  <div className="col-xs-4">
                    <div className={'box'} style={{width: '100%', textAlign: 'center'}}>
                    <Link to="/production/nesting" onClick={this._onClose}>
                      <NestingAvatar style={{margin: 'auto'}} />
                    </Link>
                    <Typography variant={'caption'}>Encaixe</Typography>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className={'box'} style={{width: '100%', textAlign: 'center'}}>
                      <Link to="/production/nestingTables" onClick={this._onClose}>
                        <NestingTableAvatar style={{margin: 'auto'}} />
                      </Link>
                      <Typography variant={'caption'}>Mesas</Typography>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className={'box'} style={{width: '100%', textAlign: 'center'}}>
                      <Link to="/production/fabrics" onClick={this._onClose}>
                        <FabricAvatar style={{margin: 'auto'}} />
                      </Link>
                      <Typography variant={'caption'}>Tecidos</Typography>
                    </div>
                  </div>
                </div>
              </Fragment>
            }
            {/* END PRODUCTION */}
            

            {/* START ADMINISTRATION */}
            {userCan('accessAdmin') 
              /* ==> the following condition is just for some old test account compatibility @TODO must remove * / 
              || userCan('accessProjects') 
              /* <== */
              ? <Fragment>
                  <div className={'row'} style={{padding: '20px 10px 4px 16px'}}>
                    <div className="col-xs-12">
                      <Typography variant={'h6'} style={{fontWeight: 300}}>Administração</Typography>
                    </div>  
                  </div>
                  <div className={'row'}>
                  {userCan('manageUsers') &&
                    <div className="col-xs-4">
                      <div className={'box'} style={{width: '100%', paddingBottom: 10, textAlign: 'center'}}>
                        <Link to="/administration/users" onClick={this._onClose} data-tip data-for={'lk_admin'}>
                          <AdminUserAvatar style={{margin: 'auto'}} />
                        </Link>
                        <Typography variant={'caption'}>Usuários</Typography>
                      </div>
                    </div>
                  }
                  {(userCan('manageWorkspaces') || userCan('manageItsWorkspaces')) &&
                    <div className="col-xs-4">
                      <div className={'box'} style={{width: '100%', paddingBottom: 10, textAlign: 'center'}}>
                        <Link to="/administration/workspaces" onClick={this._onClose} data-tip data-for={'lk_admin'}>
                          <AdminUserAvatar style={{margin: 'auto'}} />
                        </Link>
                        <Typography variant={'caption'}>Grupos</Typography>
                      </div>
                    </div>
                  }
                  </div>
                </Fragment>
              : null
            }
            {/* START ADMINISTRATION */}

          </div>
        </Popover>
      </Fragment>
    );
  }
}

class Header extends React.Component {
	
	render() {

		var { auth, app, layoutIsNarrow, actions, location } = this.props;

    var user = auth.get('user');

		if(!user) return null;

		var style1, style2, style3;

		if(layoutIsNarrow){
			style1 = {marginTop: 4, marginBottom: 4};
			style2 = {position:'relative', textAlign:'left'};
			style3 = {};
		} else {
			style1 = {marginTop: 4, marginBottom: 0};
			style2 = {position:'relative', textAlign:'left'};
			style3 = {marginBottom: 4, marginLeft: 1};
    }
    
    var 
      themesMap = {
        '_home': theme,
        'creation': theme,
        'profile': theme,
        'bases': modelingTheme,
        'base': modelingTheme,
        'projects': modelingTheme,
        'project': modelingTheme,
        'sizes-tables': sizesTablesTheme,
        'sizes-table': sizesTablesTheme,
        'production': theme,
        'nesting': nestingTheme,
        'nestingTables': nestingTableTheme,
        'fabrics': fabricTheme,
        'administration': adminTheme,
        'users': adminTheme,
        'workspaces': adminTheme,
        'print-center': printCenterTheme,
        'print': printCenterTheme
      },
      { pathname } = location,
      pathSplit = pathname.split('/'),
      pathSection = pathSplit[2] || pathSplit[1] || pathSplit[0],
      key = pathSection == '' ? '_home' : pathSection,
      currentTheme = themesMap[key];
      

		return  <MuiThemeProvider theme={currentTheme}>
              <SubscriptionAlert actions={actions} />
              <AppBar 
                style={{ 
                  position: "fixed",
                  top: 0, 
                  boxShadow: 'none', 
                  background: 'white', 
                  borderBottom: '1px solid #d4d4d4'
                }} 
                position="static" 
                color={'primary'}
              >
                <ResponsiveWrapper disabled={!layoutIsNarrow}>
                  <div style={style1}>
                    <div style={style2}>
                      <div className={'row between-xs'} style={style3}>
                        <div className={'col-xs-10'}>
                          <div style={{float:'left'}}>
                            <MainDrawer page={key}>{iconMap[key]}</MainDrawer>
                          </div>
                          <div style={{float:'left'}}><BreadCrumb.Component /></div>
                        </div>
                        <div className={'col-xs-2'}>
                          <UserIconMenu user={user} actions={actions} />
                        </div>
                      </div>
                      <HeaderController.Placeholder />
                    </div>
                  </div>
                </ResponsiveWrapper>
              </AppBar>
            </MuiThemeProvider>
  }
};

export default withRouter(Header);