import { Map } from 'immutable';

import { START_MODELING, START_SPECSHEET, START_SKETCH, STOP_MODELING, STOP_SPECSHEET, STOP_SKETCH, WINDOW_RESIZE, SET_SCREEN_LAYOUT } from '../constants';
import {pt_BR} from '../../../locales/pt-BR';

var defaultAppConf = Map({ 
  activeModule: null,
  language: 'pt_BR',
  translations: pt_BR,
  windowWidth:  window.innerWidth,
  windowHeight: window.innerHeight,
  screenLayout: 'narrow'
});

export default function auth(
  state = defaultAppConf, 
  action) {

  if(!action) return state;

    switch (action.type) {

      case WINDOW_RESIZE:
        return state.merge({
          windowWidth:  window.innerWidth,
          windowHeight: window.innerHeight
        });

      case START_MODELING:
        return state.set('activeModule', 'modeling');

      case START_SPECSHEET:
        return state.set('activeModule', 'specsheet');

      case START_SKETCH:
        return state.set('activeModule', 'sketch');

      case STOP_MODELING:
        //console.log('STOP_MODELING!!!');
        return state.set('activeModule', null);

      case STOP_SPECSHEET:
        return state.set('activeModule', null);

      case STOP_SKETCH:
        return state.set('activeModule', null);

      case SET_SCREEN_LAYOUT:
        return state.set('screenLayout', action.data);
        
      default:
        return state;
    }
}
