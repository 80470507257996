export default function CreateContext(data, payload, date, user){

  if(!data.contexts) data.contexts = {};

  data.contexts[payload.id] = { 
    name: payload.name,
    entityAttrsTypes: {},
    entityAttrs: {}
  };

  return data;
}