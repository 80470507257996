import { getHotItem } from '../storage/storage';
import LogRocket from 'logrocket';

export default function getWorkspaceMemberName(workspaceId, memberId){
  try{
    var
      profile = getHotItem('profile') || {};
    if(!profile){
      //console.log('não tem profile');
      return null;
    }
    var
      workspaces = profile.workspaces || [];
    if(!workspaces){
      //console.log('não tem workpace');
      return null;
    }
    var
      filtered = workspaces.filter( w => w.id == workspaceId )[0];
    if(!filtered){
      return null;
    }
    var
      member = filtered.members[memberId];
    if(!member){
      return null;
    }
    //console.log('deu', member.name);
    return member.name || null;
  } catch(err){
    LogRocket.captureException(err, {
      tags: {
        // additional data to be grouped as "tags"
        section: 'workspace',
        method: 'getWorkspaceMemberName'
      },
      extra: {
        // additional arbitrary data associated with the event
        profile: profile
      },
    });
    return null;
  }
}