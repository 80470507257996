import React, { Component } from 'react';
import IconButton  from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UserAvatar from './Avatar/UserAvatar';
import Divider  from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { getHotItem } from '../../commons/storage/storage';
import { Chip, ListItemIcon, Tooltip, Icon, Typography, ListItemText } from '@material-ui/core';

class UserIconMenu extends Component {

  constructor(props){
    super(props);
    this.state = {
      anchorEl: undefined,
      open: false,
    };
  }

  handleClick(event) {
    this.setState({ open: true, anchorEl: event.currentTarget });
  }
  handleRequestClose(){
    this.setState({ open: false });
  };

  logout() {
    this.props.history.push('/');
    this.props.actions.logout();
    this.setState({ open: false });
  }

  onProfileClick(){
    this.props.history.push('/profile');
    this.setState({ open: false });
  }
  
  onSwitchAccount(id){
    this.props.actions.switchAccount({id}, () => {
      window.location = '/';
    });
  }

  render() {
    var 
      user = this.props.user,
      profile = getHotItem('profile') || {},
      showAccountNameChip = profile.hosts && profile.hosts.length > 0, // && profile.personalAccount.id != profile.currentAccountId,
      currentAccountName = profile.currentAccountName,
      t = this.props.t,
      availableAccounts = 0,
      canUsePersonalAccount = false
      ;

    if(profile.personalAccount && profile.personalAccount.canBeUsed == true){
      canUsePersonalAccount = true;
      availableAccounts++;
    }
    if(profile.hosts && profile.hosts.length > 0){
      availableAccounts += profile.hosts.filter(h => h.showHost === true).length;
    }
    var
      canSwitchAccount = availableAccounts > 1;
      
    return (
      <div style={{position: 'absolute', right: 5, top: 3}} id={'userMenu'}>
        <IconButton
          aria-label="More"
          aria-owns={this.state.open ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={(e) => this.handleClick(e)}
          style={{padding: 0, zIndex: 2}}>
          <span>
            <UserAvatar 
              photoURL={user.get('photoURL')} 
              size={50} 
              showAccountAvatar={false} 
              style={{
                cursor:'pointer', 
                width: 50, 
                height: 50,
                border: '1px solid #e0e0e0',
                borderRadius: '50%'
              }}
            />
          </span>
        </IconButton>

        {/*showAccountNameChip &&
          <Tooltip title={currentAccountName}>
            <Chip 
              onClick={(e) => this.handleClick(e)}
              label={currentAccountName + ' long long long'} 
              size="small"
              color={'primary'}
              style={{
                position: 'absolute',
                right: 25,
                bottom: 13,
                zIndex: 1,
                maxWidth: 125,
                paddingRight: 20
              }}
            />
          </Tooltip>
        */}

        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={() => this.handleRequestClose()}
          MenuListProps={{
            style: {
              width: 'auto',
            },
          }}
        >
          <MenuItem selected={false} disabled>
            {user.get('displayName') || <i>(Usuário sem nome)</i>}<br />
          </MenuItem>
          <MenuItem selected={false} disabled>
            {user.get('email')}
          </MenuItem>
          <MenuItem 
            className={'logoutMenuItem'} 
            selected={false} 
            onClick={() => this.onProfileClick()}
          >
            Perfil
          </MenuItem>

          {canSwitchAccount &&
            <div>
              <Divider />
              <MenuItem selected={false} disabled style={{color: '#CC6666', opacity: 1, paddingBottom: 0}}>
                Alternar conta
              </MenuItem>
              {canUsePersonalAccount &&
                <MenuItem 
                  style={{paddingTop: 0, paddingBottom: 0}} 
                  selected={false} 
                  disabled={profile.currentAccountId == profile.personalAccount.id}
                  onClick={() => this.onSwitchAccount(profile.personalAccount.id)}
                >
                  <ListItemIcon style={{minWidth:34}}>
                    {profile.currentAccountId == profile.personalAccount.id && <Icon>check</Icon>}
                  </ListItemIcon>
                  <ListItemText 
                    primary={profile.personalAccount.name} 
                    secondary={'Conta pessoal'}
                  />
                </MenuItem>
              }

              {profile.hosts.map( (h, k) => h.showHost ?(
                <MenuItem 
                  key={k}
                  style={{paddingTop: 0, paddingBottom: 0}} 
                  selected={false}
                  disabled={profile.currentAccountId == h.id}
                  onClick={() => this.onSwitchAccount(h.id)}
                >
                  <ListItemIcon style={{minWidth:34}}>
                    {profile.currentAccountId == h.id && <Icon>check</Icon>}
                  </ListItemIcon>
                  <ListItemText 
                    primary={h.name} 
                    secondary={
                      [t('role-' + h.accountType + '-' + h.defaultRole)]
                        .concat(
                          Object.keys(h.extraRoles || {})
                                .map( r => t('role-' + h.accountType + '-' + r))
                        ).join(', ')
                    }
                  />
                  <Typography variant="inherit"></Typography>
                </MenuItem>
              ) : null )}
            </div>
          }
          {/*profile.accountName 
            &&  <MenuItem selected={false} disabled>
                  {'Em: ' + profile.accountName || ''}
                </MenuItem>
          */}
          <Divider />
          
          <MenuItem className={'logoutMenuItem'} selected={false} onClick={() => this.logout()}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withRouter(withTranslation()(UserIconMenu));