
/**
 * This is a event handler used to create some attribute off the current bucket of an item(file)
 * @function
 * @param  {object} data - userProfile Data object, local 
 * @param  {object} payload - payload content is data sent by the action, item id, attribute information
 * @param  {string} date - current date
 * @param  {string} user - current user
 * @param  {boolean} isListening
 * @returns {data} user profile Data, local
 */
import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateAttribute(data, payload, date, user, isListening){

  var bucketName = '';
  switch(payload.bucketKey){
    case 'userProjects':
      bucketName = 'projects';
      break;
    case 'userBases':
      bucketName = 'bases';
      break;
    case 'accountSizesTables':
      // bucketName = 'sizetables'; //this was causing bugs about correct collection selection
      bucketName = 'sizestables';

      break;
    default:
      bucketName = payload.bucketKey;
  }
  var order = 0;
  if(!data.attrs[bucketName]){
    data.attrs[bucketName]={};
  };
  var keys = Object.keys(data.attrs[bucketName]);
  for(var id in keys){
    var key = keys[id];
    if(data.attrs[bucketName][key].type == payload.type){
      order++;
    }
  }

  data.attrs[bucketName][payload.id] = {
    name: payload.name,
    order: order,
    type: payload.type
  }

  if(isListening){
    // live side effects
    DataManagementStore.addCollectionAttributeToBucket({
      bucketKey: payload.bucketKey,
      attributeData: {
        name: payload.name,
       	order: order,
        type: payload.type
      },
      attributeId: payload.id
    });
  }

  return data;
}