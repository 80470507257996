import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import {withStyles,Icon} from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';



const styles = {
    printCenterAvatar: {
        background: palettes.printCenter.main
    }
}

const PrintCenterAvatar = (props) =>  <Avatar 
                                        className={props.classes.printCenterAvatar}
                                        size={props.size}
                                        style={props.style}
                                      >
                                        <Icon>print</Icon>
                                      </Avatar>

export default withStyles(styles)(PrintCenterAvatar);