import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';

import SizesTableIcon from '../../mou-icons/SizesTableIcon';


const styles = {
  sizesTableAvatar: { 
    background: palettes.mouGreen.main
  },
  sizesTableIcon: {
    width:34, 
    height:34, 
    stroke: palettes.mouGreen.contrastText, 
    strokeWidth: 5, 
    marginTop: 9
  },
  sizesTableBorder: {
    border: '2px solid ' + palettes.mouGreen.contrastText
  }
}

const SizesTableAvatar = (props) => <Avatar 
                                      className={
                                        props.classes.sizesTableAvatar + 
                                        (props.withBorder ? ' ' + props.classes.sizesTableBorder : '')
                                      } 
                                      size={props.size} 
                                      style={props.style}
                                    >
                                      <SizesTableIcon className={props.classes.sizesTableIcon}  />
                                    </Avatar>

export default withStyles(styles)(SizesTableAvatar);