import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import LogRocket from 'logrocket';

import Paper  from '@material-ui/core/Paper';

// external/shared component
import ResponsiveWrapper from '../../components/ResponsiveWrapper';
import ExtraContentTop from '../../components/Utils/ExtraContentTop';
import MouIcon from '../../components/mou-icons/MouIcon';
import LoadingIndicator from '../../components/LoadingIndicator';
import Header from '../../components/Header';

// internal components
import UpdateNotification from './components/UpdateNotification';
import OfflineNotification from './components/OfflineNotification';

// containers route components
import Creation from '../../containers/Creation/Loadable';
import Production from '../../containers/Production/Loadable';
import Administration from '../../containers/Administration/Loadable';
import PrintCenter from '../../containers/PrintCenter/Loadable';
import NotFoundPage from '../../containers/NotFoundPage';

// internal route components
import CreateAccount from './components/CreateAccount';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/AuthChange/components/ResetPassword/ResetPassword';
import Dashboard from './components/Dashboard';
import ImportSharedItem from './components/ImportSharedItem';
import WorkspaceSendRedirect from './components/WorkspaceSendRedirect';
import Profile from './components/Profile';
import AuthChange from './components/AuthChange';
import NotLoggedInFrame from './components/NotLoggedInFrame';

export default class Main extends React.Component { // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load repos
   */

  constructor(props) {
    super(props);
    this.errorMessages = {
      isEmail: "E-mail inválido"
    };
    this.state = {
      error: false
    } 
    this.onErrorClose = this.onErrorClose.bind(this);
  }

  
  componentDidMount() {
    this.props.actions.checkAuth();
    window.onerror = (message, source, lineno, colno, error) => { 
      this.state.error = error;
      this.setState(this.state);
    }
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  componentDidCatch(error, errorInfo){
    LogRocket.captureException(error, {
      tags: {
        // additional data to be grouped as "tags"
        section: 'main',
        component: 'Main'
      },
      extra: {
        errorInfo
      }
    });
    this.state.error = error;
    this.setState(this.state);
  }

  handleResize() {
    this.props.actions.resizeWindow();
  }

  enableButton() {
    this.setState({
      canSubmit: true
    });
  }

  disableButton () {
    this.setState({
      canSubmit: false
    });
  }

  submitForm(model) {
   this.props.actions.login(model);
  }

  onErrorClose(){
    this.state.error = false;
    this.setState(this.state);
  }

  render() {
    const 
      { props } = this,
      { auth, app } = props,
      layoutIsNarrow = app.get('screenLayout') == 'narrow',
      loggedIn = auth.get('loggedIn') === true;

    if(auth.get('checkingAuth') === true) return <LoadingIndicator message={'Autenticando...'} width={180} />;

    if(auth.get('blocked') === true) return <h1 style={{ textAlign: 'center', padding: 30}}>O acesso do seu usuário a essa conta foi bloqueado</h1>;

    if(auth.get('expired') === true) return <h1 style={{ textAlign: 'center', padding: 30}}>O acesso do seu usuário a essa conta expirou</h1>;

    return (
      <div
        style={
          loggedIn 
            ? {}
            : { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: '#f5f6f7' }
        }
      >
        <UpdateNotification />
        <OfflineNotification />
        
        {loggedIn 
          ? auth.get('checkingProfile') === true 
            ? <LoadingIndicator message={'Carregando seu perfil...'} width={220} />
          : <Fragment>
              <Header 
                auth={auth} 
                actions={props.actions} 
                app={app} 
                layoutIsNarrow={layoutIsNarrow} 
              />
              <ExtraContentTop.Placeholder />
              <ResponsiveWrapper 
                disabled={!layoutIsNarrow}
              >
                <div 
                  style={
                    layoutIsNarrow
                    ? { margin: 0, marginTop: 90, textAlign: 'left' }
                    : { margin: '90px 15px 0 15px', textAlign: 'left' }
                  }
                >
                  <Switch>
                    <Route path='/create-account'  render={(props) => <CreateAccount  {...this.props} />}  />

                    <Route path='/shared/bases/:id' render={() => <ImportSharedItem {...props} type={'bases'} purpose={'share'} />} />
                    <Route path='/shared/projects/:id' render={() => <ImportSharedItem {...props} type={'projects'} purpose={'share'} />} />
                    <Route path='/shared/sizestables/:id' render={() => <ImportSharedItem {...props} type={'sizestables'} purpose={'share'} />} />

                    <Route path='/support/bases/:id' render={() => <ImportSharedItem {...props} type={'bases'} purpose={'support'} />} />
                    <Route path='/support/projects/:id' render={() => <ImportSharedItem {...props} type={'projects'} purpose={'support'} />} />
                    <Route path='/support/sizestables/:id' render={() => <ImportSharedItem {...props} type={'sizestables'} purpose={'support'} />} />

                    <Route path='/creation'        component={Creation} />
                    <Route path='/production'      component={Production} />
                    <Route path='/administration'  component={Administration} />
                    <Route path='/print-center' component={PrintCenter} />
                    <Route path='/' exact           render={() => <Dashboard auth={auth} />} />
                    <Route path='/forgot-password' component={Dashboard}    />
                    <Route path='/auth-change' render={(props) => <AuthChange {...this.props} loggedIn={true} />}     />
                    <Route path='/workspace/send/:id' component={WorkspaceSendRedirect} />

                    <Route path='/profile' render={() => <Profile {...props} />} />

                    <Route path="" component={NotFoundPage} />
                  </Switch>
                </div>
              </ResponsiveWrapper>
            </Fragment>
          : // not logged
          <NotLoggedInFrame>
            <Switch>
              <Route path='/sign-up'  render={(props) => <CreateAccount  {...this.props} />}  />
              <Route path='/create-account'  render={(props) => <CreateAccount  {...this.props} />}  />
              <Route path='/forgot-password' render={(props) => <ForgotPassword  {...this.props} />}     />
              <Route path='/auth-change' render={(props) => <AuthChange {...this.props} loggedIn={false} />}     />
              <Route path='/' render={(props) => <Login  {...this.props} />}/>
            </Switch>
          </NotLoggedInFrame>
        }
      </div>
    );
  }
}