import { Paper, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import BreadCrumb from '../../../../components/Utils/BreadCrumb';
import AccountTab from './components/AccountTab';
import SubscriptionTab from './components/SubscriptionTab';

const
  titles = {
    '/profile': 'Perfil',
    '/profile/subscription': 'Assinatura'
  };

function Profile(props){

  const
    history = useHistory(),
    navigateTo = (url) => {
      history.push(url);
      //BreadCrumb.Store.setBreadCrumb({ title: titles[url]});
    };

  useEffect(
    () => {
      BreadCrumb.Store.setBreadCrumb({ title: 'Perfil'});
    },
    []
  );

  return (
    <Paper style={{display: 'flex'}}>
      <div style={{width: 150, borderRight: '1px solid #DDD'}}>
        <Tabs
          orientation={'vertical'}
          value={history.location.pathname}
        >
          <Tab 
            value={'/profile'}
            onClick={() => navigateTo('/profile')}  
            label={'Conta'}
          />
          {
          <Tab 
            value={'/profile/subscription'}
            onClick={() => navigateTo('/profile/subscription')}
            label={'Assinatura'}
          />
          }
        </Tabs>
      </div>
      <div style={{padding: '10px 15px'}}>
        <Switch>
          <Route exact path={'/profile'} render={() => <AccountTab {...props} />} />
          <Route exact path={'/profile/subscription'} render={() => <SubscriptionTab {...props} />} />
        </Switch>
      </div>
    </Paper>
  )
}

export default Profile;