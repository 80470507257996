import DataManagementStore from '../../../commons/DataManagementStore';

export default function ReleaseNestableProject(data, payload, date, user, isListening){

  data.nestableProjects['nst:' + payload.projectId] = {
    name: payload.name,
    version: payload.projectVersion,
    released_at: date,
    released_by: user
  }

  if(isListening){
    // live side effects
    DataManagementStore.addItemToBucket({
      bucketKey: 'nestableProjects',
      itemData: {
        name: payload.name,
        // tags: [],
        uuid: 'nst:' + payload.projectId,
        released_at: date,
        released_by: user,
        version: payload.projectVersion
      },
      itemId: 'nst:' + payload.projectId
    });
  }

  return data;
}