import React, { useState, useEffect } from 'react';

import { addMetadata, getMetadata } from '../../commons/MetadataEngine/MetadataEngine';

export default function UserName(props){
  const 
    [ name, setName ] = useState('...');
  
  /*
  useEffect( async () => {
    var
      nm = await getMetadata(['users', props.id, 'name']);
    setName(nm || '....');
  }, []);
  */

  return <span>{name}</span>
}