import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { 
  googleLogin, 
  checkAuth, 
  logout,
  resizeWindow, 
  sendPasswordResetEmail, 
  signInWithEmailAccount, 
  createEmailAccount, 
  //createAccount, 
  getSharedItemInfos,
  importSharedItem,
  getConfirmation,
  confirmAccount,
  switchAccount,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updateUserPhoto,
  updateUserDisplayName,
  updateUserEmail,
  checkActionCode,
  recoverUserEmail,
  createSupportRequest
} from './actions';

import * as actions from './actions';
import { makeSelectAuth, makeSelectApp } from './reducers/selectors';

//import saga from './saga';
import Main from './Main';

const mapDispatchToProps = (dispatch) => ({
  actions: {
    googleLogin: () => { dispatch(googleLogin()) },
    checkAuth:   () => { dispatch(checkAuth()) },
    logout:      () => { dispatch(logout()) },
    resizeWindow:() => {dispatch(resizeWindow())},
    createEmailAccount:(email,password) => {dispatch(createEmailAccount(email,password))},
    signInWithEmailAccount:(email, password, rememberMe) => {dispatch(signInWithEmailAccount(email, password, rememberMe))},
    sendPasswordResetEmail:(email, callback) => {dispatch(sendPasswordResetEmail(email, callback))},
    verifyPasswordResetCode:(code, callback) => {dispatch(verifyPasswordResetCode(code, callback))},
    confirmPasswordReset:(code, newPassword, callback) => {dispatch(confirmPasswordReset(code, newPassword, callback))},
    //createAccount:(accountName) => {dispatch(createAccount(accountName))},
    getSharedItemInfos: (id, type, callback, purpose) => {dispatch(getSharedItemInfos(id, type, callback, purpose))},
    importSharedItem: (id, type, callback, purpose) => {dispatch(importSharedItem(id, type, callback, purpose))},
    getConfirmation: (id, callback) => {dispatch(getConfirmation(id, callback))},
    confirmAccount: (id, callback) => {dispatch(confirmAccount(id, callback))},
    switchAccount: (id, callback) => {dispatch(switchAccount(id, callback))},
    updateUserPhoto: (photoData, callback) => {dispatch(updateUserPhoto(photoData, callback))},
    updateUserDisplayName: (name, callback) => {dispatch(updateUserDisplayName(name, callback))},
    updateUserEmail: ( newEmail, password, callback) => {dispatch(updateUserEmail(newEmail, password, callback) )},
    checkActionCode: (actionCode, callback) => {dispatch(checkActionCode(actionCode, callback) )},
    recoverUserEmail: (actionCode, callback) => {dispatch(recoverUserEmail(actionCode, callback) )},
    createSupportRequest: (supportData, callback) => {dispatch(createSupportRequest(supportData, callback) )},
  }
});


const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
  app: makeSelectApp()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

//const withReducer = injectReducer({ key: 'auth', reducer: authReducer });
//const withReducer2 = injectReducer({ key: 'userProfile', reducer: userProfileReducer });
//const withSaga = injectSaga({ key: 'home', saga });

export default compose(withConnect)(Main);
export { mapDispatchToProps };