import React from 'react';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormErrorText from '../../../../components/Utils/FormErrorText';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiLink from '@material-ui/core/Link';
import getUrlQueryVariable from '../../../../commons/Utils/getUrlQueryVariable';
import { Box, Link } from '@material-ui/core';

import firebaseApp from '../../../../commons/Firebase';
import { resetPermissions } from '../../../../commons/Permissions';


//function t(a){ return a };

class CreateAccount extends React.Component { 

  
  constructor(props) {
    super(props);
    this.errorMessages = {
      isEmail: "E-mail inválido"
    };
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      showForm: false,
      hasConfirmation: false,
      confirmationData: null,
      confirmationId: null,
      confirmationStatus: null
    } 
  }

  componentDidMount(){
    var 
      auth = firebaseApp.auth();
    resetPermissions();
    auth.signOut().then( () => {
      // hideInitialLoader();
      var
        confirmation = getUrlQueryVariable('confirm');
      if(!confirmation){
        this.setState({...this.state, showForm: true });
      } else {
        this.props.actions.getConfirmation(confirmation, result => {
          if(!result){
            this.setState({
              ...this.state, 
              showForm: true, 
              hasConfirmation: true 
            });
          } else {
            this.setState({
              ...this.state, 
              showForm: true, 
              hasConfirmation: true, 
              confirmationData: result, 
              email: result.email,
              confirmationId: confirmation,
              confirmationStatus: 'pending',
              name: ''
            });
          }
          
        });
      }
    });
  }

  onSubmit(){
    var
      { email, password, confirmationData, confirmationId } = this.state;
    this.setState({
      ...this.state,
      showForm: false
    });
    if(confirmationData){
      this.props.actions.confirmAccount(
        {
          userId: confirmationData.userId,
          confirmationId,
          password
        },
        result => {
          if(result.data.success == true){
            this.setState({
              ...this.state,
              showForm: true,
              confirmationStatus: 'success'
            });
          } else {
            this.setState({
              ...this.state,
              showForm: true,
              confirmationStatus: 'error'
            });
          }
        }
      );
    } else {
      this.props.actions.createEmailAccount(email, password);
    }
  }

  changeValue(property, event){  	
  	var state = {};
  	state[property] = event.target.value;
  	this.setState(state);
  }


  render() {
    var 
      { t, auth } = this.props,
      { hasConfirmation, confirmationData, showForm, confirmationStatus } = this.state;
    return (
      <div>
        {showForm ?
          <div style={{position:'relative'}}>
            {(hasConfirmation && confirmationData && confirmationStatus == 'pending') || !hasConfirmation 
                ? <div style={{marginTop: 10}}>
                    {
                      confirmationData &&
                      <Typography variant={'body1'}>
                        Olá {confirmationData.name}, termine seu cadastro criando a sua senha.<br /> <br />
                      </Typography>
                    }
                    {
                      !confirmationData &&
                      <div className="row" style={{marginBottom: 10}}>
                        <div className="col-xs-12">
                          <TextField className="FormsyText"
                            name='name'
                            value={this.state.name}
                            label={t('name')}
                            onChange={this.changeValue.bind(this,'email')}
                            style={{width:"100%"}} />
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className="col-xs-12">
                        <TextField className="FormsyText"
                          disabled={hasConfirmation}
                          name='email'
                          value={this.state.email}
                          label={t('email')}
                          onChange={this.changeValue.bind(this,'email')}
                          style={{width:"100%"}} />
                      </div>
                    </div>

                    <div className="row" style={{marginTop: 10}}>
                      <div className="col-xs-12">
                        <TextField
                          type='password'
                          name='password'
                          defaulValue={this.state.password}
                          label={t('password')}
                          onChange={this.changeValue.bind(this,'password')}
                          style={{width:"100%"}} />
                      </div>
                    </div>

                    <div className="row" style={{marginTop: 10}}>
                      <div className="col-xs-12">
                        <TextField
                          type='password'
                          name='password'
                          defaulValue={this.state.confirmPassword}
                          onChange={this.changeValue.bind(this,'confirmPassword')}
                          label={t('confirm-password')}
                          style={{width:"100%"}} />
                      </div>
                    </div>
                    
                    <div className="row between-xs" style={{marginTop: 10}}>
                      
                        { auth && auth.get('loginError') 
                            ? <div className="col-xs-6" style={{marginTop:15, textAlign: 'left', padding: '7px'}}>
                                <FormErrorText message={t(auth.get('loginError'))} /> 
                              </div>
                            : <div className="col-xs-6" style={{marginTop:15, textAlign: 'left', padding: '7px'}}>
                                {hasConfirmation
                                  ? null 
                                  : <MuiLink href={'/app'} variant={'body2'}>
                                      {t('already-have-account')}
                                    </MuiLink>
                                }
                              </div>
                        }

                      <div className="col-xs-6" style={{marginTop:15, textAlign: 'right'}}>
                        <Button
                          type="submit"
                          color='primary'
                          variant="contained"
                          style={{width: '100%'}}
                          disabled={this.state.password == '' || this.state.password.length < 6 || this.state.password != this.state.confirmPassword}
                          onClick={this.onSubmit.bind(this)}>
                            {confirmationData ? 'Enviar' : t('create-account')}
                        </Button>
                      </div>
                    </div>

                    {/* * /}
                    <div className="row between-xs">
                      <div className="col-xs-12" style={{marginTop: 90, marginBottom: 15, textAlign: 'center'}}>
                        <Typography variant="body2">{t('want-more-confort')}?<br />{t('tired-of-creating-passwords')}?</Typography>
                        <Button
                          type="button"
                          color='primary'
                          onClick={this.props.actions.googleLogin}>
                            {t('do-login-with-google')}
                        </Button>
                      </div>
                    </div>
                    {/* */}
                  </div>
              : (hasConfirmation && confirmationData) 
                ? confirmationStatus == 'success'
                  ? <Typography variant={'body1'} align={'center'} style={{marginTop: 15}}>
                      Cadastro confirmado com sucesso!< br />
                      Faça login <Link href={'/'}>aqui</Link>.
                    </Typography>
                  : <Typography variant={'body1'} align={'center'} style={{marginTop: 15}}>
                      Não foi possível confirmar o seu cadastro.
                    </Typography>
                : <Typography variant={'body1'} align={'center'} style={{marginTop: 15}}>
                    Este link não é válido ou já expirou.
                  </Typography>
              
            }
          </div>
        : <div style={{margin: '20px 0 0 148px'}}><CircularProgress /></div>
        }
      </div>
    );
  }
}

export default withTranslation(['global', 'dashboard'])(CreateAccount);