/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

 // app reducer 
export const START_MODELING = 'START_MODELING';
export const START_SPECSHEET = 'START_SPECSHEET';
export const START_SKETCH = 'START_SKETCH';
export const STOP_MODELING = 'STOP_MODELING';
export const STOP_SPECSHEET = 'STOP_SPECSHEET';
export const STOP_SKETCH = 'STOP_SKETCH';
export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const SET_SCREEN_LAYOUT = 'SET_SCREEN_LAYOUT';

// auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_INVALID = 'LOGIN_INVALID';
export const LOGIN_ALREADY_DONE = 'LOGIN_ALREADY_DONE';
export const REFRESH_AUTH = 'REFRESH_AUTH';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_REPEATED_USER_SUCCESS = 'LOGOUT_REPEATED_USER_SUCCESS';
export const LOGOUT_WITH_MESSAGE = 'LOGOUT_WITH_MESSAGE';

// auth =>user
export const SET_PROFILE_PHOTOURL = 'SET_PROFILE_PHOTOURL';
export const SET_PROFILE_DISPLAYNAME = 'SET_PROFILE_DISPLAYNAME';
export const SET_PROFILE_EMAIL = 'SET_PROFILE_EMAIL';


// auth (with Firebase)
export const USER_NOT_LOGGED = 'USER_NOT_LOGGED';
export const NO_ACCOUNT = 'NO_ACCOUNT';
export const USER_IS_BLOCKED = 'USER_IS_BLOCKED';
export const ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_ACCOUNT_SUCCESS = 'SET_ACCOUNT_SUCCESS';
export const HAS_ACCOUNT = 'HAS_ACCOUNT';
export const SET_PARTITION = 'SET_PARTITION';
export const PROFILE_DATA_LOADED = 'PROFILE_DATA_LOADED';
export const PROFILE_CONFIGS_LOADED = 'PROFILE_CONFIGS_LOADED';

export const USER_DATA_LOADED = 'USER_DATA_LOADED';
export const RECENT_ITEM_TYPE_LOADED = 'RECENT_ITEM_TYPE_LOADED';
export const RECENT_ITEM_TYPE_ADDED = 'RECENT_ITEM_TYPE_ADDED';
export const SIZESTABLE_RENAMED = 'SIZESTABLE_RENAMED'; // WTF?? @TODO

// user profile
export const USER_CONFIG_SET = 'USER_CONFIG_SET';


//account
export const ACCOUNT_ADD_NESTING_TABLE = 'ACCOUNT_ADD_NESTING_TABLE';
export const ACCOUNT_READ_NESTING_TABLES = 'ACCOUNT_READ_NESTING_TABLES';
export const ACCOUNT_REMOVE_NESTING_TABLE = 'ACCOUNT_REMOVE_NESTING_TABLE';