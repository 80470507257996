import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function NestingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="30 25 70 100">
      <path d="m93.800049,178l0,-75.600006l-30.400025,0l0,-30.5l-29.400024,0l0,30.5l0,74.400009" fill="none" />
      <path d="m41.300049,-17l-1,77.400002l30.5,0l0,30.400001l29.399902,0l0,-30.400001l0.300049,-60.400002" fill="none" />
    </SvgIcon>
  );
}