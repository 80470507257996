import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function NestingTableIcon(props) {
  return (
    <SvgIcon {...props} viewBox="10 25 110 110">
      <polygon fill={'none'} points="77.800048828125,61.899993896484375 19.800048828125,61.899993896484375 54.699951171875,44.099998474121094 112.699951171875,44.099998474121094 "/>
      <line x1="19.5" x2="19.5" y1="61.9" y2="96.2"/>
      <line x1="76.8" x2="76.8" y1="61.9" y2="96.2"/>
      <line x1="113" x2="113" y1="44.8" y2="79"/>
    </SvgIcon>
  );
}