import React from 'react';
import { withTranslation } from 'react-i18next';

import RecentItemsHome from '../../../../components/Utils/RecentItemsHome';
import BreadCrumb from '../../../../components/Utils/BreadCrumb';

import DashboardSectionCreation from './DashboardSectionCreation';
import DashboardSectionProduction from './DashboardSectionProduction';
import DashboardSectionAdministration from './DashboardSectionAdministration';

import { userCan, arePermissionsLoaded } from '../../../../commons/Permissions';

class Dashboard extends React.Component {	

  constructor(props){
    super(props);
  }

  setBreadcrumb(){
    BreadCrumb.Store.setBreadCrumb({
      title: '',
      path: [{
        link: null,
        title: 'Página inicial' 
      }]
    });
  }

  componentDidUpdate(){
    this.setBreadcrumb();
  }

  componentDidMount(){
    this.setBreadcrumb();
    // hideInitialLoader(); 
  }
  
  render() {

    if(!arePermissionsLoaded()){
      //console.log('no permissions!');
      return null;
    } 
  
    const { t, auth } = this.props;

    if(!auth){
      return null;
    }
    if(!auth.get('user')){
      return null;
    }

    const
      showRecents = userCan('accessCreation') || userCan('accessProduction');

    return (
      <div>
        <div className="row">
          <div className={"col-xs-" + (showRecents ? '8' : '12')}>
              <div className="box">
                {userCan('accessCreation') && <DashboardSectionCreation />}
                {/*userCan('accessProduction') && */<DashboardSectionProduction />/**/}
                {userCan('accessAdmin') && <DashboardSectionAdministration />}
              </div>
          </div> 
          {showRecents &&
            <div className="col-xs-4">
              <RecentItemsHome
                itemType={'bases'} 
                buckets={['userProjects', 'userBases', 'accountSizesTables', 'fabrics', 'userNestings']}
                userProfile={this.props.userProfile} 
                auth={this.props.auth}
                itemLink={'/'}
                loadingText={'Carregando itens recentes...'}
                noItemsText={'Você não abriu nenhum arquivo ainda.'}
                onItemClick={() => { alert('oi') }}
                selectedItems={{}}
                uncheckedItems={{}}
                noShadow={true} 
                actions={this.props.actions}
                menuOptions={[
                  { label: 'Renomear arquivo', value: 'rename' },
                  { label: 'Excluir arquivo', value: 'trash' }
                ]}
                onMenuOptionClick={(action, bucket, id) => { alert('oieee') }} 
                loadingItem={false}
              />
            </div> 
          }
          
        </div>
      </div>
    );
	}
}

export default withTranslation(['global', 'dashboard'])(Dashboard)