import { addPermissions } from '../../../commons/Permissions';

export default function AddUserToRole(data, payload, date, user){

  if(data.myId == payload.userId){
    // console.log('deu');

    if(!data.myRoles){
      data.myRoles = {};
    }

    data.myRoles[payload.roleId] = true;

    if(!data.myAllowedActions){
      data.myAllowedActions = {};
    }

    var roleActions = (data.roles[payload.roleId] || {}).allowedActions || {};
    for(var actionKey in roleActions){
      data.myAllowedActions[actionKey] = roleActions[actionKey];
    }

    addPermissions(roleActions);
  }

  return data;
}