import itemsColors from './mainColors';

import MouPalettes from './palettes';

import { Museo, MuseoBold } from './fonts'; 


const fabricTheme =  {
  typography: {
    //useNextVariants: true,
    fontFamily: [
      'Museo'
    ].join(',')
  },
  palette: {
    primary: MouPalettes.fabrics,
    secondary:  {
      main: '#efb000',
      contrastText: "#ffffff" 
    }
  },
  itemHomeTopContentBg: '#585858',
  itemsColors,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Museo,MuseoBold],
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 15
      }
    }
  }
};

export default fabricTheme;