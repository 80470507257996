/**
 * Mobile Page
 *
 * This is the page we show when the user is trying to access MOU APP from a mobile device
 */

import React from 'react';
import { Paper, Typography, Button, Link } from '@material-ui/core';
import MouIcon from '../../components/mou-icons/MouIcon';
import svg from '../../images/svgs/mou_official_background.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: "auto",
      marginTop:20,
      display:"block",
      backgroundColor:'#cc6666',
      width:'auto',
      color:'white'
    },
  }));

export default function MobilePage(props) {
    const classes = useStyles();

// hideInitialLoader();
    return (
    <div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, backgroundImage: `url(${svg})`, zIndex: 1200}}>
        <div style={{position: 'absolute', top: '50%', left: '50%', marginLeft: -200, marginTop: -200, zIndex: 1021}}>
            <Paper elevation={16} style={{width: 'auto', padding: 30}}>
                <MouIcon colorScheme={'red'} width={340} height={80}/>
                <Typography variant='h6' gutterBottom align='left' style={{marginTop:15}}>
                A <strong>MOU</strong> não pode ser utilizada a partir de dispositivos móveis.<br/> Por favor, acesse via computador desktop ou notebook.
                </Typography>
                <Link href={'https://mou-digital.atlassian.net/l/c/xU0b6nGJ'} underline='hover'>
                    <Button variant='text' className={classes.button}>
                        Equipe Suporte MOU
                    </Button>
                </Link>
            </Paper>
        </div>
    </div>
    );
}