import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateBase(data, payload, date, user, isListening){

  data.bases[payload.id] = {
    name: payload.name,
    created_at: date,
    created_by: user,
    collections: payload.collections,
    // tags: payload.tags
  }

  if(payload.workspace){
    data.bases[payload.id].workspace = payload.workspace;
  }
  if(payload.sent_by){
    data.bases[payload.id].sent_by = payload.sent_by;
  }

  if(isListening){
    // live side effects
    var 
      addData = {
        bucketKey: 'userBases',
        itemData: {
          name: payload.name,
          // tags: [],
          uuid: payload.id,
          collections: payload.collections,
          created_at: date,
          created_by: user
        },
        itemId: payload.id
      };
    if(payload.workspace){
      addData.itemData.workspace = payload.workspace;
    }
    if(payload.sent_by){
      addData.itemData.sent_by = payload.sent_by;
    }
    DataManagementStore.addItemToBucket(addData);
  }

  return data;
}