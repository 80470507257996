import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateNestingTable(data, payload, date, user, isListening){


  if(!data.assets.nestingTables[payload.uuid]) return null;
  for(var i in payload.updates){
    data.assets.nestingTables[payload.uuid][i] = payload.updates[i];
  }
  // data.assets.nestingTables[payload.uuid] = {
  //   name: payload.name,
  //   uuid: payload.id,
  //   height: payload.height,
  //   width: payload.width,
  //   active: true
  // }

  if(isListening){
    // live side effects
    DataManagementStore.updateItem(payload.uuid, payload.updates);
  }

  return data;
}