import { TextField, Icon, IconButton, Typography, CircularProgress, Button } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth'

function PassswordField(props){
  const
    [ editing, setEditing ] = useState(false),
    [ sending, setSending ] = useState(false),
    [ sendError, setSendError ] = useState(null),
    undoEditing = () => {
      setEditing(false);
      setSendError(null);
    },
    confirmEditing = () => {
      setEditing(false);
      setSending(true);
      props.actions.sendPasswordResetEmail(
        firebase.auth().currentUser.email, 
        error => {
          setSending(false);
          if(!error){
            setSendError(false);
          } else {
            setSendError(true);
          }
        });
    };
  return (
    <Fragment>
      <TextField 
        label={'Senha'}
        type={'text'}
        value={'Não pode visualizar, apenas alterar'}
        disabled={true}
        style={{width: 300}}
      />
      {editing 
        ? <Fragment>
            <Typography variant={'body2'}>Enviar link para redefinição de senha? 
              <IconButton 
                color={'primary'}
                onClick={() => confirmEditing()}
              >
                <Icon>check</Icon>
              </IconButton>
              <IconButton 
                color={'primary'}
                onClick={() => undoEditing()}
              >
                <Icon>clear</Icon>
              </IconButton>
            </Typography>
          </Fragment>
        : sending 
          ? <CircularProgress size={20} style={{marginTop: 20, marginLeft: 16}} />
          : sendError === null
            ? <IconButton 
                color={'primary'}
                onClick={() => setEditing(true)}
              >
                <Icon>edit</Icon>
              </IconButton>
            : sendError === false
              ? <Fragment>
                  <Typography 
                    variant={'body2'} 
                    style={{marginTop: 10}}
                  >
                    Link de redefinição de senha enviado com sucesso.
                    <br />
                    Verifique seu e-mail. <Button color={'primary'} size={'small'} onClick={undoEditing}>OK</Button>
                  </Typography>
                  
                </Fragment>
              : <Fragment>
                  <Typography 
                    variant={'body2'} 
                    style={{marginTop: 10}}
                  >
                    Não foi possível enviar o link de redefiição de senha para o seu email. 
                    <br />
                    Por favor tente novamente mais tarde. <Button color={'primary'} size={'small'} onClick={undoEditing}>OK</Button>
                  </Typography>
                </Fragment>
      }
    </Fragment> 
  );
}

export default PassswordField;