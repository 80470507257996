import DataManagementStore from '../../../commons/DataManagementStore';

export default function SetSizestableAttribute(data, payload, date, user, isListening){
    var itemId = payload['id'];
    if(itemId != undefined){

        var sizestables = data['sizestables'];
        var currentCollections = sizestables[itemId]['collections'];
        var updatedCollections = payload['collections'];
        currentCollections = payload['collections'];

        if(isListening){
            DataManagementStore.updateItem(itemId,payload,false)
        };
    };
    return data;

};