import React from 'react';
import MicroEvent from 'microevent';

class Header_Store extends MicroEvent{};

var HeaderStore = new Header_Store();

class HeaderPlaceholder extends React.Component{

  constructor(props){
    super(props);
    this.state = { children: <div />}
    this.onChange = this._onChange.bind(this);
  }

  _onChange(children){
    this.setState({ children: children });
  }

  componentDidMount(){
    HeaderStore.bind('header:change', this.onChange);
  }

  componentWillUnmount(){
    HeaderStore.unbind('header:change', this.onChange);
  }

  render(){
    return <div>{this.state.children}</div>;
  }

}

class HeaderLoader extends React.Component{

  componentDidMount(){
    HeaderStore.trigger('header:change', this.props.children);
  }

  componentWillUnmount(){
    HeaderStore.trigger('header:change', null);
  }

  componentDidUpdate(){
    HeaderStore.trigger('header:change', this.props.children);
  }

  render(){
    return null;
  }

}

var HeaderController = {
  Placeholder: HeaderPlaceholder,
  Loader: HeaderLoader
}

export default HeaderController;