var actions = {};

export function addPermissions(data){
  //console.log('----', data);
  for(var key in data){
    actions[key] = data[key];
  }
  //console.log('permissions:', actions);
}

export function getAllPermissions(){
  return actions;
}

export function deletePermissions(data){
  for(var key in data){
    delete actions[key];
  }
  //console.log('permissions:', actions);
}

export function resetPermissions(){
  actions = {};
}

export function userCan(actionKey){
  //console.log('verificando', actionKey, actions);
  if(actions[actionKey] == true) return true;
  return false;
}

export function arePermissionsLoaded(){
  return Object.keys(actions).length > 0;
}