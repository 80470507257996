function reorderAttrsTypesBeforeInsertNew(attrsTypePool, newItemOrder){
  for(var i in attrsTypePool){
    var attr = attrsTypePool[i];
      if(attr.order >= newItemOrder){
        attr.order += 1;
      }
  }
}


export default function CreateEntityAttributeType(data, payload){


  var attrsTarget, typesTarget;

  if(payload.contextId == 'partition'){

    if(!data.entityAttrsTypes[payload.entityType])
      data.entityAttrsTypes[payload.entityType] = {};
    if(!data.entityAttrs[payload.entityType])
      data.entityAttrs[payload.entityType] = {};
    typesTarget = data.entityAttrsTypes[payload.entityType];
    attrsTarget = data.entityAttrs[payload.entityType];

  } else {

    if(!data.contexts[payload.contextId].entityAttrsTypes[payload.entityType])
      data.contexts[payload.contextId].entityAttrsTypes[payload.entityType] = {};
    if(!data.contexts[payload.contextId].entityAttrs[payload.entityType])
      data.contexts[payload.contextId].entityAttrs[payload.entityType] = {};
    typesTarget = data.contexts[payload.contextId].entityAttrsTypes[payload.entityType];
    attrsTarget = data.contexts[payload.contextId].entityAttrs[payload.entityType];
  }

  var order;

  if(payload.order === undefined){
    order = Object.keys(typesTarget).length;
  } else {
    reorderAttrsTypesBeforeInsertNew(typesTarget, payload.order);
    order = payload.order;
  }

  var attrType = {
    name: payload.name,
    order: order
  };

  if(payload.attributes){
    for(var attrId in payload.attributes){
      attrsTarget[attrId] = payload.attributes[attrId];
      attrsTarget[attrId].type = payload.id;
    }
  }

  typesTarget[payload.id] = attrType;

  return data;
}