import { getHotItem } from '../../commons/storage/storage';

var 
  metadata = {},
  areWorkspaceUsersCached = false;

function cacheWorkspaceUsers(){
  getHotItem('profile').workspaces.forEach( ({ members}) => {
    Object.entries(members).forEach( ([id, { name }]) => {
      addMetadata(['users', id], { name } );
    })
  });
  areWorkspaceUsersCached = true;
}

export function clearMetadata(){
  metadata = {};
  areWorkspaceUsersCached = false;
}

export async function getMetadata(path){
  var
    out = metadata;
  if(!areWorkspaceUsersCached){
    cacheWorkspaceUsers();
  }
  path.forEach( p => {
    if(out[p]){
      out = out[p];
    } else {
      return null;
      //throw new Error('Metadata path not found: ' + path.join(':'));
    }
  });
  return out;
}

export function addMetadata(path, data){
  var 
    currBranch = metadata;
  path.slice(0, -1).forEach( p => {
    if(!currBranch[p]){
      currBranch[p] = {};
    } 
    currBranch = currBranch[p];
  });
  currBranch[path[path.length-1]] = data;
}