export default function CreatePartition(data, payload){

  //console.log(data, payload);

  data.accountName = payload.name;

  data.attrsTypes = payload.attrsTypes;
  data.attrs = payload.attrs;
  
  data.projects = payload.projects || {};
  data.bases = payload.bases || {};
  data.sizestables = payload.sizestables || {};

  data.nestableProjects = {};
  data.nestings = {};

  if(payload.assets && payload.assets.fabrics){
    for(var i in payload.assets.fabrics){
      payload.assets.fabrics[i].width = 130;
      payload.assets.fabrics[i].marginBottom = 2;
      payload.assets.fabrics[i].marginTop = 2;
    }
  }
  

  data.assets = payload.assets || {};
  
  data.myAllowedActions = payload.allowedActions;

  return data;
}