import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function AdminUserIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 130 130">
      <path d="m103,112.4l0,0.7c0,-15.2 -8.8,-28.5 -21.6,-35c5.6,-4.9 9.2,-12.2 9.2,-20.2c0,-14.9 -12,-26.9 -26.9,-26.9s-26.9,12 -26.9,26.9c0,8.1 3.6,15.3 9.2,20.2c-12.8,6.5 -21.6,19.8 -21.6,35l0,-0.7" fill="none" />
      <path d="m114.2,28.8c-5.4,3.9 -8.9,10.2 -8.9,17.4c0,6.4 2.8,12.2 7.3,16.1c-10.2,5.2 -17.2,15.7 -17.2,27.9l0,-0.6" fill="none" />
      <path d="m12.3,29.2c5.4,3.9 8.9,10.2 8.9,17.4c0,6.4 -2.8,12.2 -7.3,16.1c10.2,5.2 17.2,15.7 17.2,27.9l0,-0.6" fill="none" />
    </SvgIcon>
  );
}