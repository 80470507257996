function reorderAttrsBeforeInsertNew(attrsPool, attrTypeId, newItemOrder){
  for(var i in attrsPool){
    var attr = attrsPool[i];
    if(attr.type == attrTypeId){
      if(attr.order >= newItemOrder){
        attr.order += 1;
      }
    }
  }
}

function getAttrsTypeChildrenLength(attrsPool, attrTypeId){
  var len = 0;
  for(var i in attrsPool){
    var attr = attrsPool[i];
    if(attr.type == attrTypeId){
      len++;
    }
  }
  return len;
}

export default function CreateEntityAttribute(data, payload){

  var target, types; 

  if(payload.contextId == 'partition'){
    target = data.entityAttrs[payload.entityType];
    types  = data.entityAttrsTypes[payload.entityType];
  } else {
    target = data.contexts[payload.contextId].entityAttrs[payload.entityType];
    types  = data.contexts[payload.contextId].entityAttrsTypes[payload.entityType];
  }

  var order;
  if(payload.order === undefined){
    order = getAttrsTypeChildrenLength(target, payload.attributeTypeId);
  } else {
    order = payload.order;
    reorderAttrsBeforeInsertNew(target, payload.attributeTypeId, order);
  }

  var attrData = {
    name: payload.name,
    type: payload.attributeTypeId,
    order: order
  }

  target[payload.id] = attrData;

  return data;
}