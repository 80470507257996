// PT-BR

export const pt_BR = {

  'invalid-login': 'Usuário ou senha inválidos',
  'already-logged': 'Usuário logado em outra máquina ou navegador.',
  'already-logged-extra': 'Clique aqui para terminar a outra sessão e fazer login neste navegador',
  'alreadyLogged': 'Você já fez o login',
  'loginButton': 'Entrar',

  'measures-m000': 'Perímetro pescoço',
  'measures-m001': 'Largura ombro a ombro (largura das costas)',
  'measures-m002': 'Largura do ombro',
  'measures-m003': 'Altura de cava',
  'measures-m004': 'Largura de entrecavas da frente',
  'measures-m005': 'Largura de entrecavas das costas',
  'measures-m006': 'Altura do alto busto',
  'measures-m007': 'Altura do busto',
  'measures-m008': 'Perímetro do busto/tórax',
  'measures-m009': 'Distância entre seios',
  'measures-m010': 'Comprimento papila mamária/jugular',
  'measures-m011': 'Altura do baixo busto',
  'measures-m012': 'Perímetro do baixo busto',
  'measures-m013': 'Altura do corpo frente',
  'measures-m014': 'Altura do corpo costas',
  'measures-m015': 'Perímetro da cintura',
  'measures-m016': 'Altura da baixa cintura',
  'measures-m017': 'Perímetro da baixa cintura',
  'measures-m018': 'Altura entre cintura e baixo quadril',
  'measures-m019': 'Altura do quadril',
  'measures-m020': 'Perímetro do quadril',
  'measures-m021': 'Altura do baixo quadril',
  'measures-m022': 'Perímetro do baixo quadril',
  'measures-m023': 'Altura do gancho',
  'measures-m024': 'Contorno gancho frente/costas',
  'measures-m025': 'Gancho total (frente/costas pescoço)',
  'measures-m026': 'Altura de cintura/tornozelo',
  'measures-m027': 'Altura do entrepernas',
  'measures-m028': 'Perímetro de coxa',
  'measures-m029': 'Altura de cintura até joelho',
  'measures-m030': 'Perímetro do joelho',
  'measures-m031': 'Perímetro da panturrilha',
  'measures-m032': 'Perímetro do tornozelo',
  'measures-m033': 'Comprimento do braço (ombro/cotovelo/pulso)',
  'measures-m034': 'Perímetro de bíceps',
  'measures-m035': 'Perímetro de cotovelo',
  'measures-m036': 'Perímetro de pulso',
  'measures-m037': 'Comprimento total',
  'measures-m038': 'Perímetro da cabeça',


  tf_rect_width: 'Largura do retângulo de construção', //'1/4 {m000}',
  tf_rect_height: 'Altura do retângulo de construção', //'{m004}',
  tf_shoulder_h_guideline_y: 'Caimento dos ombros',
  tf_armhole_guideline_y: 'Altura de cava', //'1/2 {m003}',
  tf_bust_guideline_y: 'Linha do busto', //'{m006}',
  tf_shoulder_v_guideline_x: 'Linha de apoio dos ombros', //'1/2 {m009}'
  tf_neckhole_height: 'Decote (vertical)',
  tf_neckhole_width: 'Decote (horizontal)',

  mou: {

		languages: {
	    ptBR: 'Português (Brasil)',
	    en: 'Inglês'
	  },

  	
    

    apparel: {
      errors: {
        'col': 'Escolha uma coleção',
        'gzs': 'Escolha uma gradação',
      }
    },

    // AddBottomSheet
    addBototmSheetTitle: 'Adicionar Itens',

    // SizesTableBodyItem
    addSizestableFrom: 'Criar tabela de medidas apartir desta',

  	// ModelingApp
  	tracings: 'Traçados',
  	addTracing: 'Adicionar novo traçado',
    tracingAdded: 'Traçado adicionado com sucesso',

  	// ViewToolbar
  	viewAllTracings: 'Visualizar todos os traçados',
  	arrangeTracings: 'Reordenar Tracaços',
    customTracingPosition: 'Posicões personalizadas',
  	zoomIn: 'Aumentar Zoom',
  	zoomOut: 'Diminuir Zoom',
    tracingsTrash: 'Lixeira de traçados',
    tracingsActive: 'Traçados ativos',
    restoreTracing: 'Restaurar traçado',
    deleteTracing: 'Excluir traçado',

    forms: {
      required_field: 'Campo obrigatório',
      tracing: {
        name:        'Nome do traçado',
        rect_width:  'Largura do retângulo de construção',
        rect_height: 'Comprimento do retângulo de construção',
        rect_points: 'Pontos (vertices) do retângulo de construção',
        rect_lines:  'Linhas (lados) do retângulo de construção',
        cannot_submit: 'Impossível criar o traçado. Verifique o formulário'
      }
    },

    // resultTags: 'resultado(s) da tag',
    resultCollections: 'resultado(s) da coleção',

  	sizesTableChoose: 'Medidas',

  }		
}