import DataManagementStore from '../../../commons/DataManagementStore';

export default function ShareSizesTable(data, payload, date, user, isListening){

  data.sizestables[payload.id].sharedWith = payload.sharedWith;
  data.sizestables[payload.id].sharedFrom = 'me';
  data.sizestables[payload.id].sharedAt = date;

  if(isListening){
      DataManagementStore.updateItem(
        payload.id,
        { 
          sharedFrom: 'me',
          sharedWith: payload.sharedWith,
          sharedAt: date 
        },
        false
      )
  };

  return data;
};