import React from 'react';

import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br';

dayjs.extend(relativeTime);
dayjs.locale('pt-br');

const DateTime = (props) => {
  return dayjs(props.v).fromNow();
}

export default DateTime;