import React ,{ useState,useEffect }from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Backdrop, CircularProgress } from '@material-ui/core';
// import { Button, Link } from '@material-ui/core';
const FBFupdateUserPaymentStatus = firebase.functions().httpsCallable("updateUserPaymentStatus");

const CountDownRedirect = ({id,redirectLink}) => {
    const 
        [ seconds , setSeconds ] = useState(10),
        [ backdropOpen, setBackdropOpen ] = useState(false),
        redirectPayment = async () => {
            setBackdropOpen(true)
            try{
                let response = await FBFupdateUserPaymentStatus({accountId:id,paymentStatus:"paid"})
                if(response){
                    window.location.replace(redirectLink)
                }
            }catch(err){
                console.log("ERRO!:",err)
            } 
        };
    
    useEffect(() => {
        if(seconds>0){
            setTimeout(() => setSeconds(seconds-1),1000)
        }else{
            redirectPayment()
        }
    },[seconds]);

    return (
        <div>
            {seconds !== 0 ?
                <h3>Você será redirecionado automaticamente para a página de pagamentos em {seconds} segundos <br/>Ou clique em <span><b>prosseguir</b></span>.</h3>
                :
                <div>
                    <h4>Redirecionando...</h4>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdropOpen}
                        onClick={() => setBackdropOpen(false)}
                    >
                        <CircularProgress color='inherit'/>
                    </Backdrop>
                </div>
        }
        </div>
    );
}

export default CountDownRedirect;
