import DataManagementStore from '../../../commons/DataManagementStore';

export default function TrashProject(data, payload, date, user, isListening){

  //console.log('Trash project handler');

  if(!data.projects[payload.id]) return data;

  data.projects[payload.id].active = false;

  if(isListening){
    // live side effects
    DataManagementStore.trashItem(payload.id);
  }

  return data;
}