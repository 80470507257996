import React, { FunctionComponent, SetStateAction, useState} from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

//validators
import { cnpj, cpf } from 'cpf-cnpj-validator';
const formatTelephone = require('telefone/format');
const parseTelephone = require('telefone').parse;
//
interface SubscriptionFormDraft{
    name: string,
    company: string,
    adress: string,
    adressNumber: number | undefined,
    adressComplement?: string | undefined,
    cpf_cnpj: number | undefined,
    cep: number | undefined,
    city: string,
    state: string,
    telephoneNumber: number | undefined,
    id: string
}

interface SubscriptionFormProps {
    planType:string,
    paymentType:string | null,
    paymentHandler():void,
    draft: SubscriptionFormDraft,
    setFormDraft: React.Dispatch<SetStateAction<any>>,
    setAreFieldsIncorrect: React.Dispatch<SetStateAction<boolean>>,
    setAreFieldsEmpty: React.Dispatch<SetStateAction<boolean>>
};

const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
    planType,
    paymentType,
    paymentHandler,
    draft,
    setFormDraft,
    setAreFieldsIncorrect,
    setAreFieldsEmpty
}) => {
    const 
        [ cpf_cnpjError, setCpf_cnpjError] = useState(false),
        [ telephoneNumberError, setTelephoneNumberError] = useState(false),
        [ cepError, setCepError] = useState(false),
        [ nameError, setNameError] = useState(false),
        [ adressError, setAdressError] = useState(false),
        [ adressNumberError, setAdressNumberError] = useState(false),
        [ cityError, setCityError] = useState(false),
        [ stateError, setStateError] = useState(false),
        updateInput = (event:React.ChangeEvent<HTMLInputElement>) => {
            var {id,value} = event.target;
            setFormDraft((prevState: SubscriptionFormProps) =>({
                ...prevState,
                [id]: value
            }));
            validateInput(id,value)
        },
        validateInput = (id:string,value:any) => {
            setAreFieldsIncorrect(false);
            setAreFieldsEmpty(false);

            if(value === '' || value === undefined) setAreFieldsEmpty(true);

            switch(id){
                case 'name':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setNameError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    setNameError(false);
                    return
                case 'cpf_cnpj':
                    // let value = draft.id;
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setCpf_cnpjError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    
                    if((value).length >= 0 && (value).length <= 11){ //CPF CASE
                        let cpfNumber = value;
                        let isValid = cpf.isValid(cpfNumber);
                        if(isValid){
                            setCpf_cnpjError(false)
                            let formatedCpfNumber = cpf.format(cpfNumber);
                            setFormDraft((prevState: SubscriptionFormProps) =>({
                                ...prevState,
                                [id]: formatedCpfNumber
                            }));
                            return
                        };
                        setCpf_cnpjError(true)
                        setAreFieldsIncorrect(true);

                        return
                    }else if((value).length > 11 && (value).length <= 14){//CNPJ CASE
                        let cnpjNumber = value;
                        let isValid = cnpj.isValid(cnpjNumber);
                        if(isValid){
                            let formatedCnpjNumber = cnpj.format(cnpjNumber)
                            setFormDraft((prevState: SubscriptionFormProps) =>({
                                ...prevState,
                                [id]: cpf.format(formatedCnpjNumber)
                            }));
                            return
                        };
                        
                        return
                    }else {
                        break
                    };
                case 'adress':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setAdressError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    setAdressError(false);
                    return
                case 'adressNumber':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setAdressNumberError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    setAdressNumberError(false);
                    return
                case 'cep':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setCepError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    setCepError(false);
                    return;
                case 'city':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setCityError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    setCityError(false);
                    return
                case 'state':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setStateError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    setStateError(false);
                    setAreFieldsIncorrect(false);
                    return
                case 'telephoneNumber':
                    if(value === '' || value === undefined){
                        setAreFieldsEmpty(true);
                        setTelephoneNumberError(true);
                        setAreFieldsIncorrect(true);
                        return
                    };
                    let telephoneNumber = parseTelephone(value);
                    if(telephoneNumber){ //aprovado
                        setTelephoneNumberError(false);
                        setAreFieldsIncorrect(false);
                        telephoneNumber = formatTelephone(telephoneNumber)
                        setFormDraft((prevState: SubscriptionFormProps) =>({
                            ...prevState,
                            [id]: telephoneNumber
                        }));
                        return
                    }
                    return
                default:
                    setAreFieldsEmpty(false);
                    setAreFieldsIncorrect(false);
                    break
            };
        };

    return(
        <Box sx={{flexGrow:1}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Forma de pagamento: Mercado Pago (Link externo)</Typography>
                    {/* <TextField required label={'Forma de pagamento: Mercado Pago (Link externo)'}/> */}
                    {/* <FormControl component="fieldset">
                        <FormLabel component="legend">Escolha a forma de pagamento:</FormLabel>
                        <RadioGroup aria-label="plan" name="plan1" value={paymentType} onChange={paymentHandler} row>
                            <FormControlLabel value="pix" control={<Radio />}           label="Pix" />
                            <FormControlLabel value="boleto" control={<Radio />}        label="Boleto" />
                            <FormControlLabel value="transferência" control={<Radio />} label="Transferência" />
                        </RadioGroup>
                    </FormControl> */}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Dados para cobrança:
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField id={'name'} required label={'Nome'} onChange={updateInput} fullWidth value={draft.name}
                    error={nameError} helperText={ nameError ? 'Preencha seu nome!' : ''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id={'company'} label={'Empresa:'} onChange={updateInput} fullWidth value={draft.company}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField id={'cpf_cnpj'} required label={'CPF/CNPJ'} onChange={updateInput} value={draft.cpf_cnpj} 
                    error={cpf_cnpjError} helperText={ cpf_cnpjError? 'Digite um valor válido':''} 
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField id={'adress'} required label={'Endereço:'} fullWidth onChange={updateInput} value={draft.adress}
                    error={adressError} helperText={ adressError ? "Digite um valor válido!":''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField id={'adressNumber'} required label={'Número:'} onChange={updateInput} value={draft.adressNumber}
                    error={adressNumberError} helperText={ adressNumberError ? "Digite um valor válido!":''}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField id={'adressComplement'}  label={'Complemento:'} fullWidth onChange={updateInput} value={draft.adressComplement}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField id={'cep'} required label={'CEP:'} onChange={updateInput} value={draft.cep}
                    error={cepError} helperText={ cepError ? "Digite um valor válido!":''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Link href={'https://buscacepinter.correios.com.br/app/endereco/index.php'} target="_blank" >
                        <Typography variant='button' style={{position:'absolute',marginTop:15}}>
                            Não sei meu CEP
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <TextField id={'city'} required label={'Cidade:'} onChange={updateInput} fullWidth value={draft.city}
                    error={cityError} helperText={ cityError ? "Digite um valor válido!":''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id={'state'} required label={'Estado:'} onChange={updateInput} value={draft.state}
                    error={stateError} helperText={ stateError ? "Digite um valor válido!":''}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id={'telephoneNumber'} required label={'Telefone:'} onChange={updateInput} value={draft.telephoneNumber} 
                    error={telephoneNumberError} helperText={ telephoneNumberError ? 'Digite um valor válido':''}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SubscriptionForm;