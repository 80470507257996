import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateFabric(data, payload, date, user, isListening){

  if(!data.assets.fabrics){
    data.assets.fabrics = {};
  }

  data.assets.fabrics[payload.id] = {
    name: payload.name,
    uuid: payload.id,
    width: 90, // @TMP as default
    marginBottom: 2, // @TMP as default
    marginTop: 2 // @TMP as default
  }
  //console.log('criou', data.assets.fabrics[payload.id]);

  if(isListening){
    // live side effects
    DataManagementStore.addItemToBucket({
      bucketKey: 'fabrics',
      itemData: {
        name: payload.name,
        uuid: payload.id,
        width: payload.width || 0, // @TMP as default
        height: payload.height || 0,
        marginBottom: payload.marginBottom || 0,
        marginLeft: payload.marginLeft || 0,
        marginRight: payload.marginRight || 0,
        marginTop: payload.marginTop || 0,
        price: payload.price || 0,
        shrinkageX: payload.shrinkageX || 0,
        shrinkageY: payload.shrinkageY || 0,
        weigth: payload.weigth || 0
      },
      itemId: payload.id
    });
  }

  return data;
}