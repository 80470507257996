import React from 'react';

import Avatar from '@material-ui/core/Avatar';


import UserAvatarImg from "../../../images/default_user_avatar.png";

export default class UserAvatar extends React.Component { 

  render(){
    var img = UserAvatarImg;
    if(this.props.photoURL){
      img = this.props.photoURL;
    }

    if(!this.props.showAccountAvatar)
      return (<Avatar src={img} size={this.props.size} style={this.props.style} />);

    var style = this.props.style || {};
    style.position = "relative";
    style.width  = (this.props.size + 4) + 'px';
    style.height = (this.props.size + 4) + 'px';

    return (
      <div style={style}>
        <Avatar src={img} style={{width:this.props.size-2,height:this.props.size-2, border:'1px solid rgba(0, 0, 0, 0.08)'}} />
        
      </div>
    );
  }

}