export default function awaitData(getState, reducer, prop, done){
  //console.log(getState().get(reducer));
  if(getState().get(reducer).has(prop)) 
    return done(getState().get(reducer).get(prop));
  var interval = setInterval( () => {
    if(getState().get(reducer).has(prop)){
      clearInterval(interval);
      done(getState().get(reducer).get(prop));
    }
  }, 150);
}