import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles, Icon } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';

import AdminUserIcon from '../../mou-icons/AdminUserIcon';

const styles = {
  fabricAvatar: { 
    background: palettes.administration.main
  },
  fabricIcon: {
    width:40, 
    height:40, 
    stroke: palettes.administration.contrastText, 
    strokeWidth: 5, 
    marginTop: 0
  },
}

const AdminUserAvatar = (props) => <Avatar 
                                    className={props.classes.fabricAvatar} 
                                    size={props.size} 
                                    style={props.style}
                                  >
                                    <AdminUserIcon className={props.classes.fabricIcon}  />
                                  </Avatar>

export default withStyles(styles)(AdminUserAvatar);