import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function ModelingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 130 130">
      <path d="m22.315944,124.785433l-19.188248,-58.478469c0,0 -1.957984,-10.050987 10.050987,-14.097488c12.139504,-4.046501 28.194976,-12.139504 28.194976,-12.139504s6.004486,-4.046501 8.093002,-10.050987c1.957984,-6.004486 -1.957984,-10.050987 6.004486,-12.139504c8.093002,-1.957984 10.050987,0 10.050987,0" fill="none" />
      <path d="m107.684066,124.785433l19.188248,-58.478469c0,0 1.957984,-10.050987 -10.050987,-14.097488c-12.139504,-4.046501 -28.194976,-12.139504 -28.194976,-12.139504s-6.004486,-4.046501 -8.093002,-10.050987c-1.957984,-6.004486 1.957984,-10.050987 -6.004486,-12.139504c-8.093002,-1.957984 -10.050987,0 -10.050987,0" fill="none" />
      <path d="m57.559664,17.096288l2.610646,-10.050987c0,0 0.913726,-1.957984 2.610646,-1.957984c1.827452,0 1.827452,0 1.827452,0" fill="none" />
      <path d="m71.657152,17.096288l-2.610646,-10.050987c0,0 -0.913726,-1.957984 -2.610646,-1.957984c-1.827452,0 -1.827452,0 -1.827452,0" fill="none" />
    </SvgIcon>
  );
}