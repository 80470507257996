import firebaseApp from '../Firebase';


import { getHotItem, setHotItem } from '../storage/storage';

import * as handlers from './handlers/_loader';

var db = firebaseApp.database();

var eventDbRef = null;

export function startPartitionListener(){

  var hotProfile = getHotItem('profile');

  //console.log('Start partition listener');

  var partitionId = getHotItem('profile').partitionId;
    
  eventDbRef = db.ref('/partitionsEvents/' + partitionId)
                .orderByKey()
                .startAt(getHotItem('lastEventId'));
  eventDbRef.on('child_added', (child) => {
    if(child.key > getHotItem('lastEventId')){
      var newEvent = child.val();
      //console.info('NEW EVENT', newEvent);

      // ==> inicio código TEMPORARIO Tobia (deletar)
      if(!handlers[newEvent.e]) return;
      // <== fim código TEMPORARIO Tobia (deletar)

      hotProfile = handlers[newEvent.e](hotProfile, newEvent.p, newEvent.d, newEvent.u, true);
      setHotItem('lastEventId', child.key);
    }
  });
    
  
}
export function stopPartitionListener(){
  if(eventDbRef){
    //console.log('Stop partition listener');
    eventDbRef.off();
  }
}