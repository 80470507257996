import React from 'react';
import MicroEvent from 'microevent';

class ExtraContentTop_Store extends MicroEvent{};

var ExtraContentTopStore = new ExtraContentTop_Store();

class ExtraContentTopPlaceholder extends React.Component{

  constructor(props){
    super(props);
    this.state = { children: <div />}
    this.onChange = this._onChange.bind(this);
  }

  _onChange(children){
    this.setState({ children: children });
  }

  componentDidMount(){
    ExtraContentTopStore.bind('ectop:change', this.onChange);
  }

  componentWillUnmount(){
    ExtraContentTopStore.unbind('ectop:change', this.onChange);
  }

  render(){
    return <div>{this.state.children}</div>;
  }

}

class ExtraContentTopLoader extends React.Component{

  componentDidMount(){
    ExtraContentTopStore.trigger('ectop:change', this.props.children);
  }

  componentWillUnmount(){
    ExtraContentTopStore.trigger('ectop:change', null);
  }

  componentDidUpdate(){
    ExtraContentTopStore.trigger('ectop:change', this.props.children);
  }

  render(){
    return null;
  }

}

var ExtraContentTop = {
  Placeholder: ExtraContentTopPlaceholder,
  Loader: ExtraContentTopLoader
}

export default ExtraContentTop;