
import DataManagementStore from '../../../commons/DataManagementStore';
/**
 * This is a event handler used to remove some attribute off the current bucket of an item(file)
 * @method
 * @param  {object} data - userProfile Data, local 
 * @param  {object} payload - data sent by actions
 * @param  {string} date - current date
 * @param  {string} user - current user
 * @param  {boolean} isListening 
 * @returns {data} user profile Data, local
 */
export default function RemoveAttribute(data, payload, date, user, isListening){
  var bucketName = '';
  switch(payload.bucketKey){
    case 'userProjects':
      bucketName = 'projects';
      break;
    case 'userBases':
      bucketName = 'bases';
      break;
    case 'accountSizesTables':
      // bucketName = 'sizetables';
      bucketName = 'sizestables';

      break;
    default:
      bucketName = payload.bucketKey;
  }
  var order = payload.attributeOrder;
  var keys = Object.keys(data.attrs[bucketName]);
  for(var id in keys){
    var key = keys[id];
    var itemOrder = data.attrs[bucketName][key].order;
    if(data.attrs[bucketName][key].type == payload.type && order < itemOrder){
      data.attrs[bucketName][key].order = itemOrder -1;
      if(isListening){
        DataManagementStore.updateItem(key, data.attrs[bucketName][key],true);
      }
    }
  }

  delete data.attrs[bucketName][payload.id];

  if(isListening){
    // live side effects
    DataManagementStore.deleteAttributeItem(payload.id);
  }

  return data;
}