import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function FabricIcon(props) {
  return (
    <SvgIcon {...props} viewBox="20 20 100 130">
      <path fill="none" d="m121.5,48.5c-3.4,2.5 -6.8,3.8 -12,5c-26,6 -30,-3 -45.9,-13.3c-26.5,-17.2 -39.4,10.7 -39.4,10.7l-14.8,29.5c0,0 10.7,-23.8 39.4,-10.7c17.2,7.9 19.1,24.3 46.8,18c18,-4.1 27.9,-14.3 27.9,-14.3" />
      <circle fill="none" cx="21.4" cy="81.6" id="svg_84" r="11.9"/>
      <line x1="33.3" x2="41.4" y1="84.3" y2="67"/>
    </SvgIcon>
  );
}