import * as handlers from './handlers/_loader';
import firebase from 'firebase/app';
import 'firebase/functions';
import { initUserStorage, persistUserStorage, setHotItem } from '../storage/storage';
import { updateTimeline } from '../user-timeline';
import LogRocket from 'logrocket';

const FBFgetPartitionEvents = firebase.functions().httpsCallable('getPartitionEvents');

export default async function buildProfileFromEvents(configs){
  
  //console.log('---- run buildProfileFromEvents');
  
  // initializing and getting the user storage
  var userStorage = await initUserStorage(configs);

  //console.log('---- continuando buildProfileFromEvents');
  
  // if there is data, update it with new events, otherwise create data from scratch
  var lastEventId;
  if(!userStorage.lastEventId || !userStorage.profile){
    //console.log('-- no lastEventId');
    lastEventId = false;
  } else {
    //console.log('-- lastEventId:', userStorage.lastEventId);
    //console.log('-- profile:', userStorage.profile);
    lastEventId = userStorage.lastEventId;
  }

  var events = await getPartitionEvents(configs.partitionId, lastEventId);

  var profile = userStorage.profile;
  //console.log('eventos:', events);
  
  for(var eventId in events){
    var event = events[eventId];
    //console.log(event);

    // ==> inicio código TEMPORARIO Tobia (deletar)
    if(!handlers[event.e]) {
      console.log('evento inexistente ignorado!', event.e);
      continue;
    }
    // <== fim código TEMPORARIO Tobia (deletar)
    
    profile = handlers[event.e](profile, event.p, event.d, event.u);
    lastEventId = eventId;
  }
  setHotItem('lastEventId', lastEventId);

  var timeline = await updateTimeline(userStorage.timeline, configs.userId, configs.partitionId);
  //console.log('timeline', timeline);
  setHotItem('timeline', timeline);

  await persistUserStorage();

  return profile;
}
 
function getPartitionEvents(partitionId, lastEventId){
  return new Promise(resolve => {
    //console.log('----GETTING', lastEventId);
    FBFgetPartitionEvents({partitionId: partitionId, lastEventId: lastEventId}).then(function(result) {
      resolve(result.data);
    }).catch( error => {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          section: 'initialization',
          method: 'getPartitionEvents',
          firebaseFunction: 'getPartitionEvents'
        },
        extra: {
          // additional arbitrary data associated with the event
          partitionId: partitionId, 
          lastEventId: lastEventId
        },
      });
    });
  });
}
