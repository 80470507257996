import React from 'react';

import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { CircularProgress, Typography, Box } from '@material-ui/core';


class ForgotPassword extends React.Component { 


    constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      sending: false,
      done: false
    } 
  }

  sendPasswordResetEmail(){
    this.setState({
      ...this.state,
      error: null,
      sending: true
    });
    this.props.actions.sendPasswordResetEmail(this.state.email, err => {
      if(err){
        this.setState({
          ...this.state,
          error: err.code,
          sending: false
        })
      } else {
        this.setState({
          ...this.state,
          error: null,
          sending: false,
          done: true
        })
      }
    });
  }

  changeValue(property, event){   
    var state = {};
    state[property] = event.target.value;
    this.setState(state);
  }

  componentDidMount(){
    // hideInitialLoader();
  }


  render() {
    const
      { t } = this.props;
    return (
      this.state.done === true
        ? <div style={{marginTop: 15}}>
            <Typography variant={'body1'}>{t('forgot-password-send-link-success')}</Typography>
          </div>
        : <div>
            
            <div className="row" style={{marginTop: 15}}>
              <div className="col-xs-12">
                <Typography variant={'body1'}>{t('forgot-password-text')}</Typography>
                <TextField 
                  name='email'
                  value={this.state.email}
                  label="E-mail" 
                  onChange={this.changeValue.bind(this,'email')}
                  style={{width:"100%"}} 
                  disabled={this.state.sending}
                />
              </div>
            </div>

            <div className="row end-xs"  style={{marginTop:20}}>
              <div className="col-xs-6" style={{textAlign:'left'}}>
                {this.state.sending &&
                <CircularProgress size={36} style={{marginLeft: '50%'}}/>
                }
                {this.state.error &&
                  <Typography variant={'body2'}>{t(this.state.error)}</Typography>
                }
              </div>
              <div className="col-xs-6">
                <Button
                  type="submit"
                  color='primary'
                  variant="contained"
                  disableElevation
                  disabled={this.state.sending}
                  onClick={this.sendPasswordResetEmail.bind(this)}>
                    {t('forgot-password-button')}
                </Button>
              </div>
            </div>
          
          </div>
    );
  }
}

export default withTranslation()(ForgotPassword);