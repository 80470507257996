import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';

import {Link} from 'react-router-dom';

import NestingAvatar from '../../../../components/Utils/Avatar/NestingAvatar';
import NestingTableAvatar from '../../../../components/Utils/Avatar/NestingTableAvatar';
import FabricAvatar from '../../../../components/Utils/Avatar/FabricAvatar';
import { withTranslation } from 'react-i18next';

import CreationImage from "../../../../images/production_thumb.png";

const styles = theme => ({
  card: {
    display: 'flex',
    marginBottom: 15
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '30%',
    height: 200
  },
  areaLink: {
    color: '#6f6f6f', 
    textDecoration: 'none'
  }
});

function DashboardSectionProduction(props) {
  const { classes, theme, t } = props;

  return (
    <Card className={classes.card}>
      
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
          {t('dashboard:productionTitle')}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Área de encaixe, controle de mesas e cadastro de tecidos
          </Typography>
          <div className={'row'} style={{paddingTop: 30}}>
                      <div className="col-xs-4">
                        <div className={'box'} style={{width: '100%'}}>
                        <Link to="/production/nesting">
                          <NestingAvatar style={{margin: 'auto'}} />
                        </Link>
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <div className={'box'} style={{width: '100%'}}>
                          <Link to="/production/nestingTables">
                            <NestingTableAvatar style={{margin: 'auto'}} />
                          </Link>
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <div className={'box'} style={{width: '100%'}}>
                          <Link to="/production/fabrics">
                            <FabricAvatar style={{margin: 'auto'}} />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px'}}>
                        <Link to="/creation/bases" className={classes.areaLink}><span>Encaixe</span></Link>
                      </div>
                      <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px', color: '#000000'}}>
                        <Link to="/creation/projects" className={classes.areaLink}><span>Mesas de encaixe</span></Link>
                      </div>
                      <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px', color: '#000000'}}>
                        <Link to="/creation/sizes-tables" className={classes.areaLink}><span>Tecidos</span></Link>
                      </div>
                    </div>
        </CardContent>

      </div>
      <CardMedia
        className={classes.cover}
        image={CreationImage}
      />
    </Card>
  );
}

export default withTranslation(['global', 'dashboard'])(withStyles(styles, { withTheme: true })(DashboardSectionProduction));

