import { Snackbar, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import globalManager from '../../../../commons/GlobalManager';

export default function UpdateNotification(props){
  const
    [ showNotification, setShowNotification ] = useState(false),
    setNotification = () => {
      setShowNotification(true);
    };
  useEffect(
    () => {
      globalManager.bind('sw:updateFound', setNotification);
      return () => {
        globalManager.unbind('sw:updateFound', setNotification);
      }
    }
  );
  if(showNotification === true){
    return (
      <Snackbar 
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={'Uma nova versão da MOU está disponível!'}
        action={
          <Button 
            color={'primary'}
            onClick={() => window.location.reload()}
          >
            Atualize
          </Button>}
      />
    );
  }
  return null;
}