import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';

import {Link} from 'react-router-dom';

import BaseAvatar from '../../../../components/Utils/Avatar/BaseAvatar';
import ProjectAvatar from '../../../../components/Utils/Avatar/ProjectAvatar';
import SizesTableAvatar from '../../../../components/Utils/Avatar/SizesTableAvatar';

import { withTranslation } from 'react-i18next';


import CreationImage from "../../../../images/creation_thumb.png";

const styles = theme => ({
  card: {
    display: 'flex',
    marginBottom: 15
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '30%',
    height: 200
  },
  areaLink: {
    color: '#6f6f6f', 
    textDecoration: 'none'
  }
});

function DashboardSectionCreation(props) {
  const { classes, theme, t } = props;

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cover}
        image={CreationImage}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {t('dashboard:creationTitle')}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
          {t('dashboard:creationDesc')}
          </Typography>
          <div className={'row'} style={{paddingTop: 30}}>
            <div className="col-xs-4">
              <div className={'box'} style={{width: '100%'}}>
                <Link to="/creation/sizes-tables">
                  <SizesTableAvatar style={{margin: 'auto'}} />
                </Link>
              </div>
            </div>
            <div className="col-xs-4">
              <div className={'box'} style={{width: '100%'}} id={'basesHomeDashboardLink'}>
              <Link to="/creation/bases">
                <BaseAvatar style={{margin: 'auto'}} />
              </Link>
              </div>
            </div>
            <div className="col-xs-4">
              <div className={'box'} style={{width: '100%'}}>
                <Link to="/creation/projects">
                  <ProjectAvatar style={{margin: 'auto'}} />
                </Link>
              </div>
            </div>
            
          </div>
          <div className={'row'}>
            <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px', color: '#000000'}}>
              <Link to="/creation/sizes-tables" className={classes.areaLink}><span>Tabelas de medidas</span></Link>
            </div>
            <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px'}}>
              <Link to="/creation/bases" className={classes.areaLink}><span>Bases</span></Link>
            </div>
            <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px', color: '#000000'}}>
              <Link to="/creation/projects" className={classes.areaLink}><span>Modelagens</span></Link>
            </div>
            
          </div>
        </CardContent>

      </div>
      
    </Card>
  );
}

export default withTranslation(['global', 'dashboard'])(withStyles(styles, { withTheme: true })(DashboardSectionCreation));

