import React from 'react';
import LoadingImgUri from './LoadingImgUri';

import mainColors from '../../mou-mui-themes/mainColors';

const
  fills = {
    '': mainColors.userBases,
    'bases': mainColors.userBases,
    'base': mainColors.userBases,
    'projects': mainColors.userProjects,
    'project': mainColors.userProjects,
    'sizes-tables': mainColors.accountSizesTables,
    'sizes-table': mainColors.accountSizesTables,
    'nesting': mainColors.userNestings,
    'nesting-tables': mainColors.nestingTables,
    'fabrics': mainColors.fabrics,
    'users': mainColors.adminColor,
    'workspaces': mainColors.adminColor,
    'print': mainColors.printCenterColor
  }
// green: '#00a29d'
// yellow: '#efbc2a'

const LoadingIndicator = props => {
  const
    urlPath = window.location.pathname.split('/'),
    fill = fills[urlPath[2] || urlPath[1]];
  return <div style={{
          position:'absolute',
          top: 0, 
          bottom: 0,
          left: 0,
          right: 0,
          background: '#f5f6f7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
          }}
        >
          <LoadingImgUri fill={fill} />
          <div className="loaderWrap" style={{marginTop: 3}}> 
            <div 
              className="loaderAll" 
              style={{
                
              }}
            />
            <div 
              className="loaderPercent animationStripes" 
              id="loaderBar" 
              style={{
                width: props.width || 300,
                backgroundImage: `linear-gradient(90deg, ${fill} 0%, ${fill}55 16%, ${fill}55 33%, ${fill} 49%, ${fill} 66%, ${fill}55 82%)`,
                backgroundSize: '300% 100%',
                animation: 'progress 2s linear infinite'
              }}
            />
          </div>
          {props.message && <div className="loaderTxt">{props.message}</div>}
        </div>
};

export default LoadingIndicator;
