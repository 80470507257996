import { Typography, Link, Dialog, DialogTitle, DialogActions, Button, DialogContent, CircularProgress } from '@material-ui/core';
import React, { Fragment, useState, useEffect } from 'react';
import { getHotItem } from '../../../../../../commons/storage/storage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';
import CountDownRedirect from './CountDownRedirect';

const months = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];


function formatDate(date){
  const d = new Date(date + ' 00:00:00');
  return d.getDate() + ' de ' + months[d.getMonth()] + ' de ' + d.getFullYear();
}

function Plans({promoInfo}){
  let yearlyPrice,semestralPrice,monthlyPrice;
  if(!promoInfo){
    yearlyPrice = 155
    semestralPrice = 175
    monthlyPrice = 195
  }else{
    yearlyPrice = promoInfo.yearly.price
    semestralPrice = promoInfo.semiannual.price
    monthlyPrice = promoInfo.monthly.price
  }
  return (
    <Fragment>
      <Typography 
        variant={'h6'} 
        gutterBottom
        style={{marginBottom: 20}}
      >
        Nossos planos
      </Typography>
      <Typography variant={'body1'}>
        Anual: {yearlyPrice} R$/mês<br />
        Semestral: {semestralPrice} R$/mês<br />
        Mensal: {monthlyPrice} R$/mês<br />
      </Typography>
    </Fragment>
  );
}

function SubscriptionTab(props){

  const
    profile = getHotItem('profile'),
    { subscriptionStatus } = profile,
    monthlyPrice =subscriptionStatus ? subscriptionStatus.promo.monthly.price: 195,
    semiannualPrice =subscriptionStatus ? subscriptionStatus.promo.semiannual.price : 175,
    yearlyPrice = subscriptionStatus ? subscriptionStatus.promo.yearly.price : 155,
    [ isSubscribing, setIsSubscribing ] = useState(false),
    [ plan, setPlan ] = useState("yearly"),
    [ paymentType, setPaymentType ] = useState(null),
    [ sending, setSending ] = useState(false),
    [ response, setResponse ] = useState(null),
    [ areFieldsIncorrect, setAreFieldsIncorrect] = useState(true),
    [ areFieldsEmpty, setAreFieldsEmpty] = useState(true),
    [ draft , setFormDraft ] = useState({
      name:'',
      company:'',
      adress:'',
      adressNumber:undefined,
      adressComplement:undefined,
      cpf_cnpj:undefined,
      cep: undefined,
      city: '',
      state: '',
      telephoneNumber: undefined
    }),
    handlePlanChange = (e) => {
      setPlan(e.target.value);
    },
    handlePaymentChange = (e) => {
      setPaymentType(e.target.value);
    },
    handleSubscriptionRequest = async () => {
      setSending(true);
      const
        supportData = {
          requestType: "32",
          summary: 'Solicitação de assinatura MOU', 
          description: `${profile.personalAccount.name} (${draft.name}) com CPF/CNPJ:${draft.cpf_cnpj} solicitou a criação de uma assinatura. Tipo: ${plan}, Forma de pagamento: ${paymentType}`+
          `Endereço de cobrança: ${draft.adress}, número: ${draft.adressNumber} e complemento: ${draft.adressComplement}, CEP: ${draft.cep},`+
          `cidade: ${draft.city} - ${draft.state}. Contato: ${draft.telephoneNumber}.`,
          // description: `${profile.personalAccount.name} TESTE DE SUBSCRIPTION blaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa   aasdsadasdsdasadsadasdasdasdasdasddddddddddddddddddddddddddddsadasdasdasdwweweeaw  daiksdkalsjhdkajshdkjashdkjashdkjashkjd teste de TESTE DE SUBSCRIPTION blaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa   aasdsadasdsdasadsadasdasdasdasdasddddddddddddddddddddddddddddsadasdasdasdwweweeaw  daiksdkalsjhdkajshdkjashdkjashdkjashkjd teste de`,
        }
      props.actions.createSupportRequest(supportData, (responseData) => {
        setSending(false);
        if(responseData.issueKey){
          setResponse('success');
          // setGoingToPaymentPage(true);
        } else {
          setResponse('error');
        }
      });
    };
    useEffect(() => {
      for (let id in draft){
        if(id === 'company' || id === 'adressComplement') continue;
        if(draft[id] === '' || draft[id] === undefined) setAreFieldsEmpty(true);
    }
    },[draft]);
  return (
    <div>
      {isSubscribing &&
        <Dialog open={true} maxWidth={'lg'} fullWidth>
          <DialogTitle>Assine a MOU</DialogTitle>
          {(sending && response === null) &&  
            <DialogContent dividers>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
                <Typography variant={'body2'} style={{marginLeft: 8}}>Enviando...</Typography>
              </div>
            </DialogContent>
          }
          {(!sending && response === null ) && 
            <DialogContent dividers>
              {/* <CountDownRedirect /> */}
              <Typography variant={'body1'} gutterBottom>
                Solicite sua assinatura diretamente conosco preenchendo o formulário abaixo. 
                Entraremos imediatamente em contato com você pelo e-mail de sua conta MOU enviando todos os dados necessários.<br /><br /></Typography>
              <FormControl component="fieldset">
                <FormLabel component="legend">Escolha seu tipo de plano:</FormLabel>
                <RadioGroup aria-label="plan" name="plan1" value={plan} onChange={handlePlanChange}>
                  <FormControlLabel value="yearly" control={<Radio />} label={`Anual: ${yearlyPrice} R$/mês`} />
                  <FormControlLabel value="semiannual" control={<Radio />} label={`Semestral: ${semiannualPrice} R$/mês`} />
                  <FormControlLabel 
                    value="monthly" 
                    control={<Radio />} 
                    label={`Mensal: ${monthlyPrice} R$/mês`}
                  />
                </RadioGroup>
              </FormControl>
              <br /><br />
              <SubscriptionForm 
                planType={plan}
                paymentType={paymentType} 
                paymentHandler={handlePaymentChange}
                draft={draft}
                setFormDraft={setFormDraft}
                setAreFieldsIncorrect={setAreFieldsIncorrect}
                setAreFieldsEmpty={setAreFieldsEmpty}
                />
            </DialogContent>
          }
          {response !== null &&
            <DialogContent dividers>
              {
                response === 'success'
                  ? <div>
                      <Typography variant='h5' gutterBottom>Atenção!</Typography>
                      {/* <Typography variant='body1' gutterBottom>
                        Você será redireciado a nossa página do Mercado Pago, para efetuar o pagamento do plano selecionado.
                      </Typography> */}
                      <CountDownRedirect id={profile.currentAccountId} redirectLink={subscriptionStatus.promo[plan].link}/>
                    </div>
                  : <Typography variant={'body1'}>Algo deu errado! Não foi possível enviar sua solicitação.<br />Por favor, tente novamente mais tarde.</Typography>
              }
            </DialogContent>
          }
          <DialogActions>
            <Button
              disabled={sending}
              onClick={() => {
                setIsSubscribing(false);
                setFormDraft({
                  name:'',
                  company:'',
                  adress:'',
                  adressNumber:undefined,
                  adressComplement:undefined,
                  cpf_cnpj:undefined,
                  cep: undefined,
                  city: '',
                  state: '',
                  telephoneNumber: undefined
                })
              }}
            >
              {response === null ? 'Cancelar' : 'Fechar'}
            </Button>
            {response === null && 
                <Button
                  variant={'contained'}
                  disableElevation
                  color={'primary'}
                  onClick={handleSubscriptionRequest}
                  disabled={sending || response !== null || areFieldsIncorrect || areFieldsEmpty} //Send Button is disabled unless user filled all fields correctly
                >
                  Enviar
                </Button>
            }
            {response === 'success' &&
              <Button>
                <Link href={subscriptionStatus.promo[plan].link}>Prosseguir</Link>
              </Button>
            }
          </DialogActions>
        </Dialog>
      }

      {/*
      <Typography 
        variant={'body1'} 
        gutterBottom
      >
        Aqui você pode verificar e alterar seu plano, dados de cobrança e vencimento de sua assinatura.
      </Typography>
      */}
      {subscriptionStatus 
        ? <Fragment>

            {subscriptionStatus.status === 'on-trial' &&
              <Fragment>
                <Typography variant={'body1'}>
                  Você está no período de teste gratuíto. (Degustação MOU).<br /><br />
                  Você possui {subscriptionStatus.promo.trialDaysLeft} dia{subscriptionStatus.promo.trialDaysLeft === 1 ? '' : 's'} restante{subscriptionStatus.promo.trialDaysLeft === 1 ? '' : 's'}.<br /><br />
                  Para assinar, <Link style={{cursor: 'pointer'}} onClick={() => setIsSubscribing(true)}>Clique aqui</Link>.<br /><br />
                </Typography>
                <Plans promoInfo={subscriptionStatus.promo}/>
              </Fragment>
            }

            {(subscriptionStatus.status === 'unpaid' || subscriptionStatus.status === 'invalid-payment-status') && 
              <Fragment>
                <Typography variant={'body1'}>
                  Você precisa ativar ou renovar sua assinatura.<br /><br />
                  Para fazer isso, <Link style={{cursor: 'pointer'}} onClick={() => setIsSubscribing(true)}>Clique aqui</Link>.<br /><br />
                </Typography>
                <Plans promoInfo={subscriptionStatus.promo}/>
              </Fragment>
            }

            {(subscriptionStatus.status === 'paid') && 
              <Fragment>
                <Typography variant={'body1'}>
                  {subscriptionStatus.nextPayment && 
                    <span>
                      Sua assinatura está ativa e precisa ser renovada até o dia {formatDate(subscriptionStatus.nextPayment)}.<br /><br />
                      Para renovar, <Link style={{cursor: 'pointer'}} onClick={() => setIsSubscribing(true)}>Clique aqui</Link>.<br /><br />
                    </span>
                  }
                </Typography>
                <Plans promoInfo={subscriptionStatus.promo}/>
              </Fragment>
            }

          </Fragment>

        : <Fragment>
            <Typography 
              variant={'body1'}
            >
              Sua conta faz parte de um contrato exclusivo com a MOU ou é do tipo "convidado" de uma conta de outro usuário da MOU, 
              portanto não possui informação de assinatura.
              <br />
              <br />
              Para dúvidas ou perguntas, entre em contato conosco através do email <Link href={'mailto:suporte@mou.digital'}>suporte@mou.digital</Link>
            </Typography>
          </Fragment>
      }
    </div>
  );
}

export default SubscriptionTab;