import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateNestingTable(data, payload, date, user, isListening){

  if(!data.assets.nestingTables){
    data.assets.nestingTables = {};
  }

  data.assets.nestingTables[payload.id] = {
    name: payload.name,
    uuid: payload.id,
    height: payload.height,
    width: payload.width,
    active: true
  }

  if(isListening){
    // live side effects
    DataManagementStore.addItemToBucket({
      bucketKey: 'nestingTables',
      itemData: {
        name: payload.name,
        uuid: payload.id,
        height: payload.height,
        width: payload.width,
        active: true
      },
      itemId: payload.id
    });
  }

  return data;
}