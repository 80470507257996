import React from 'react';
import { Button, Link, Typography } from '@material-ui/core';

function InvalidLinkMessage(props){
  return (
    <Typography 
      variant={'body1'} 
      style={{
        textAlign: 'center', 
        paddingTop: 20
      }}
    >
      Link invalido. <Link href={'/'} color={'primary'}>Voltar para a página inicial</Link>
    </Typography>
  );
}

export default InvalidLinkMessage;