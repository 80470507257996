export default function CreateRole(data, payload){

  if(!data.contexts[payload.contextId]) data.contexts[payload.contextId] = {};

  if(!data.roles) data.roles = {};
  
  data.roles[payload.id] = {
    name: payload.name,
    allowedActions: payload.allowedActions
  };

  var context = data.contexts[payload.contextId];

  if(!context.roles) context.roles = {};

  context.roles[payload.id] = true; 

  return data;
}