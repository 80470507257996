import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';

import palettes from '../../../mou-mui-themes/palettes';
import BaseIcon from '../../mou-icons/BaseIcon';

const styles = {
  baseAvatar: { 
    background: palettes.mouRed.main
  },
  baseIcon: {
    width:34, 
    height:34, 
    stroke: palettes.mouRed.contrastText, 
    strokeWidth: 6, 
    marginTop: 2
  },
  baseBorder: {
    border: '2px solid ' + palettes.mouRed.contrastText
  }
}

const BaseAvatar = (props) => <Avatar 
                                className={
                                  props.classes.baseAvatar + 
                                  (props.withBorder ? ' ' + props.classes.baseBorder : '')
                                } 
                                size={props.size} 
                                style={props.style}
                              >
                                <BaseIcon className={props.classes.baseIcon} />
                              </Avatar>

export default withStyles(styles)(BaseAvatar);