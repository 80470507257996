import React from 'react';

import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';

import FormErrorText from '../../../../components/Utils/FormErrorText';
import { withStyles, Typography, Checkbox, FormControlLabel } from '@material-ui/core';

const
  styles = theme => ({
    forgotPassword: {
      textDecoration: 'none',
      color: theme.palette.secondary.main
    }
  });

class Login extends React.Component { 

  constructor(props) {
    super(props);
    this.errorMessages = {
      isEmail: "E-mail inválido"
    };
    this.state = {
      email: '',
      password: '',
      rememberMe: false
    } 
  }
  
  signInWithEmailAccount(){
    this.props.actions.signInWithEmailAccount(this.state.email,this.state.password, this.state.rememberMe);
  }

  changeValue(property, event){   
    var state = {};
    state[property] = event.target.value;
    this.setState(state);
  }

  componentDidMount(){
    // hideInitialLoader();
  }


  render() {
    const 
      props = this.props,
      classes = props.classes;
    
    if(props.auth.get('checkingAuth') == true) return null;

    /*
    if(props.auth.get('welcomeScreen') == true) {
      return <WelcomeScreen auth={props.auth} actions={props.actions} />;
    }
    */

    var 
      header, 
      { t, auth } = props,
      layoutIsNarrow = props.app.get('screenLayout') == 'narrow';

      var error = props.auth.get('loginError');
      var errorMarkup = null;
      var errorExtra  = null;
      var logoutReason = props.auth.get('logoutReason');

      //this.props.auth.get('logoutReason')

      if(error){
        if(error == 'already-logged'){
          errorExtra = (
            <a href="javascript:" onClick={this._onForceLogin.bind(this)}>
              {/*<FormattedMessage id={'already-logged-extra'} />*/}
              Clique aqui para fechar a sessão na outra máquina e logar nesta.
            </a>
          );
        }
        errorMarkup = (
          <div className={"login-error-message " + error}>
            Usuário ou senha incorreta.
            {/*<FormattedMessage id={error} />*/}
            <br />
            {errorExtra}
          </div>
        );//<RaisedButton className="login-send" label='Login' secondary={true} onClick={this._onSubmit.bind(this)}/>
      }
      //console.log(auth.get('loginError'));
    return (
      
      <div style={{position:'relative'}}>
        { auth && auth.get('loading') == true && 
              <div style={{
                position:'absolute',
                zIndex: 999,
                background: '#FFFFFFDD',
                top: 0,
                bottom: -5,
                left: -5,
                right: -5
              }}
              >
                
              </div>
            }    
        <div>
          {/*<div className="row">
            <div className="col-xs-12">
              <Link to="/create-account">
                <Button
                  variant="contained"
                  type="submit"
                  color='secondary'
                  style={{width: '100%'}}>
                    Não tem uma conta? <br /> Clique aqui para se cadastrar!
                </Button>
              </Link>
            </div>
          </div>*/}
          <div className="row" style={{marginTop:0}}>
            <div className="col-xs-12">
              <TextField
                name='email'
                value={this.state.email}
                onChange={this.changeValue.bind(this,'email')}
                label="E-mail" 
                style={{width:"100%"}} 
              />
            </div>
          </div>

          <div className="row" style={{marginTop: 10}}>
            <div className="col-xs-12">
              <TextField
                type='password'
                name='password'
                value={this.state.password}
                label="Senha" 
                onChange={this.changeValue.bind(this,'password')}
                style={{width:"100%"}} 
              />
            </div>
          </div>

          <div className="row end-xs" style={{marginTop: 10}}>
            <div className="col-xs">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.rememberMe}
                  onChange={(e, checked) => this.setState({...this.state, rememberMe: checked})}
                  color="primary"
                />
              }
              label="Lembre-me"
              labelPlacement="start"
            />
            </div>
          </div>

          <div className="row between-xs" style={{marginTop: 10}}>
            { auth && auth.get('loginError') 
              ? <div className="col-xs-6" style={{marginTop:12, textAlign: 'left', padding: '0 7px'}}>
                  <FormErrorText message={t(auth.get('loginError'))} />
                  <Link to={'/forgot-password'} className={classes.forgotPassword}>
                    <Typography variant={'body2'}>{t('forgot-password')}</Typography>
                  </Link>
                </div>
              : <div className="col-xs-6" style={{marginTop:15, textAlign: 'left', padding: '7px'}}>
                  <Link to={'/forgot-password'} variant={'body2'} className={classes.forgotPassword}>
                    <Typography variant={'body2'}>{t('forgot-password')}</Typography>
                  </Link>
                </div>
            }
            <div className="col-xs-6" style={{marginTop:15, textAlign: 'right'}}>
              <Button
                type="submit"
                color='primary'
                variant="contained"
                style={{width: '100%'}}
                onClick={this.signInWithEmailAccount.bind(this)}>
                  {t('login')}
              </Button>
            </div>
            {logoutReason &&
              <div className="col-xs-12" style={{marginTop:15}}>
                <Alert severity={'error'}>{t('logout-reason-'+logoutReason)}</Alert>
              </div>
            }
          </div>
              
          <div className="row between-xs">
            <div className="col-xs-12" style={{marginTop: 0, textAlign: 'center'}}>
              {/* OU
              <br />
              <Button
                type="button"
                color='primary'
                onClick={this.props.actions.googleLogin}>
                  {t('do-login-with-google')}
              </Button> */}
            </div>
          </div>
         
          
      </div>
      </div>
    );
  }
}

export default withTranslation(['global', 'dashboard'])(withStyles(styles)(Login));