import React from 'react';
import { Offline } from "react-detect-offline";
import { Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';

export default function OfflineNotification(props){
  return (
    <Offline polling={{interval: 15000}}>
      <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={'warning'}>
          Você está offline! Verifique sua conexão Internet.
        </Alert>
      </Snackbar>
    </Offline>
  )
}