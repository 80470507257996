import DataManagementStore from '../../../commons/DataManagementStore';

export default function SetFabricAttributes(data, payload, date, user, isListening){
    var itemId = payload['id'];
    var fabrics = data['assets']['fabrics'];
    var currentCollections = fabrics[itemId]['collections'];
    var updatedCollections = payload['collections'];
    currentCollections = payload['collections'];
    if(itemId){

        if(isListening){
            DataManagementStore.updateItem(itemId,payload,false)
        };
    }
    return data;

}