import DataManagementStore from '../../../commons/DataManagementStore';



export default function TrashBase(data, payload, date, user, isListening){

  //console.log('Trash base handler');
  if(!data.bases[payload.id]) return data;

  if(!data.bases[payload.id]) return data;

  data.bases[payload.id].active = false;

  if(isListening){
    // live side effects
    DataManagementStore.trashItem(payload.id);
  }

  return data;
}