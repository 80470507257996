import DataManagementStore from '../../../commons/DataManagementStore';

export default function CreateNestingSession(data, payload, date, user, isListening){

  data.nestings[payload.id] = {
    name: payload.name,
    created_at: date,
    created_by: user,
    projects: payload.projects
  }

  if(isListening){
    // live side effects
    DataManagementStore.addItemToBucket({
      bucketKey: 'userNestings',
      itemData: {
        name: payload.name,
        projects: payload.projects,
        uuid: payload.uuid,
        created_at: date,
        created_by: user
      },
      itemId: payload.uuid
    });
  }

  return data;
}