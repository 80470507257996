import { TextField, Icon, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { userCan } from '../../../../../../../../commons/Permissions';

function NameField(props){
  const
    [ name, setName ] = useState(props.displayName || ''),
    [ editing, setEditing ] = useState(false),
    [ loading, setLoading ] = useState(false),
    undoEditing = () => {
      setName(props.displayName || '');
      setEditing(false);
    },
    confirmEditing = () => {
      if(!userCan('changeOwnName')){
        return;
      }
      if(props.displayName === name){
        return;
      }
      setLoading(true);
      setEditing(false);
      props.actions.updateUserDisplayName(
        name, 
        result => {
          setLoading(false);
        }
      )
    };
  return (
    <Fragment>
      <TextField 
        label={'Nome'}
        value={name}
        disabled={!editing}
        onChange={(e) => setName(e.target.value)}
        style={{width: 300}}
      />
      {editing 
        ? <Fragment>
            <IconButton 
              color={'primary'}
              onClick={() => confirmEditing()}
              disabled={props.displayName === name || loading}
            >
              <Icon>check</Icon>
            </IconButton>
            <IconButton 
              color={'primary'}
              onClick={() => undoEditing()}
            >
              <Icon>clear</Icon>
            </IconButton>
          </Fragment>
        : loading
            ? <CircularProgress size={20} style={{marginTop: 20, marginLeft: 16}} />
            : <Tooltip
                title={
                  userCan('changeOwnName')
                    ? 'Clique para alterar seu nome'
                    : 'Você não tem permissão para alterar seu nome. Entre em contato com os administradores de sua conta.'
                }
              >
                <span>
                  <IconButton 
                    color={'primary'}
                    onClick={() => setEditing(true)}
                    disabled={!userCan('changeOwnName')}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </span>
              </Tooltip>
      }
    </Fragment>
  );
}

export default NameField;