import React from 'react';
import { Offline, Detector } from "react-detect-offline";
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export default function OfflineNotification(props){
  return (

    <Detector polling={{enabled:true,url:"https://ipv4.icanhazip.com",interval:10000,timeout:10000}} render={({online}) => {
      return(
        <div>
        {
          !online && 
          <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity={'warning'}>
            Você está offline! Verifique sua conexão Internet.
          </Alert>
        </Snackbar>
        }
        </div>
      )
    }} />

    // // <Offline polling={{ timeout:20000}}>
    //   <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
    //     <Alert severity={'warning'}>
    //       Você está offline! Verifique sua conexão Internet.
    //     </Alert>
    //   </Snackbar>
    // </Offline>
  )
}