export default function CreateUser(data, payload){

  if(payload.id == data.myId){
    data.myName = payload.name;

  } else {
    
    if(!data.contexts[payload.contextId].users){
      data.contexts[payload.contextId].users = {};
    }
    data.contexts[payload.contextId].users[payload.id] = {
      id: payload.id,
      name: payload.name
    }
  }

  return data;
}