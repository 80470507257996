const mainColors = {
  userBases: '#cc6666',
  mouRed: '#cc6666',
  userProjects: '#cc6666',
  mouRedLight: '#cc9999',
  mouYellow: '#efbc2a',
  accountSizesTables: '#00a29d',
  fabrics: '#81b5b7',
  userNestings: '#efbc2a',
  nestingTables: '#d0b346',
  adminColor: '#00bcd4',//'#b1b2b4',
  // printCenterColor: '#2d2f33'
  printCenterColor: '#000000',
};

export default mainColors;