import React from 'react';
import getUrlQueryVariable from '../../../../commons/Utils/getUrlQueryVariable';
import NotLoggedInFrame from '../NotLoggedInFrame';
import InvalidLinkMessage from './components/InvalidLinkMessage';
import RecoverEmail from './components/RecoverEmail';
import ResetPassword from './components/ResetPassword';


function AuthChange(props) {
  const
    mode = getUrlQueryVariable('mode');

  if(mode === 'resetPassword'){
    return  props.loggedIn === true 
              ? <NotLoggedInFrame>
                  <ResetPassword {...props} />
                </NotLoggedInFrame>
              : <ResetPassword {...props} />;
  } 

  if(mode === 'recoverEmail'){
    return  props.loggedIn === true 
              ? <NotLoggedInFrame>
                  <RecoverEmail {...props} actionCode={getUrlQueryVariable('oobCode')} />
                </NotLoggedInFrame>
              : <ResetPassword {...props} />;
  } 

  return  props.loggedIn === true 
            ? <NotLoggedInFrame>
                <InvalidLinkMessage />
              </NotLoggedInFrame>
            : <InvalidLinkMessage />;
}

export default AuthChange;