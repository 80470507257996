import MicroEvent from 'microevent';

class GlobalManager extends MicroEvent{

  constructor(props){
    super(props);
    this.swUpdateFound = false;
  }

	triggerUpdateFound(data){
    //console.log(data);
    this.swUpdateFound = true;
    setTimeout( 
      () => {
        this.trigger('sw:updateFound');
      },
      2000
    );
  }

}

var globalManager = new GlobalManager();

export default globalManager;