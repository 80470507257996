import { Button, CircularProgress, Icon, Paper } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import Avatar from 'react-avatar-edit'

function UserAvatarField(props){
  const
    { user, classes } = props,
    [ src, setSrc ] = useState(null),
    [ preview, setPreview ] = useState(null),
    [ editing, setEditing ] = useState(false),
    [ uploading, setUploading ] = useState(false),
    onClose = () => {
      setPreview(null)
    },
    onCrop = (preview) => {
      setPreview(preview)
    },
    onBeforeFileLoad = (elem) => {
      /*
      if(elem.target.files[0].size > 71680){
        alert("File is too big!");
        elem.target.value = "";
      };
      */
    },
    undoEditing = () => {
      setPreview(null);
      setEditing(false);
    },
    confirmEditing = () => {
      setUploading(true);
      props.actions.updateUserPhoto(
        preview, 
        result => {
          setTimeout( 
            () => {
              setUploading(false);
              setEditing(false);
            }, 
            2000
          );
        }
      );
    };
  return (
    <Fragment>
      {(editing && !uploading) && 
        <div style={{position: 'relative'}}>
          <Paper 
            elevation={10}
            style={{
              position: 'absolute', 
              top: -20, 
              left: 220,
              padding: 20,
              background: '#FFFFFF',
              zIndex: 2
            }}
          >
            <Avatar
              width={400}
              height={400}
              imageWidth={400}
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              src={src}
              label={'Escolher imagem'}
              exportMimeType={'image/jpeg'}
              exportAsSquare={true}
              exportSize={200}
              exportQuality={90}
            />
          </Paper>
        </div> 
      }
      {uploading &&
        <div style={{position: 'relative'}}>
          <div 
            style={{
              position: 'absolute', 
              zIndex: 2, 
              width: 200, 
              height: 200, 
              background: '#FFFFFF99', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress size={100} />
          </div>
        </div>
      }
      <img 
        src={preview || user.get('photoURL')} 
        className={classes.userPhoto}
      />
      
      {editing 
        ? <Fragment>
            <Button 
              size={'small'} 
              color={'primary'}
              startIcon={<Icon>check</Icon>}
              onClick={confirmEditing}
              disabled={!preview}
            >
              Confirmar
            </Button>
            <Button 
              color={'secondary'}
              size={'small'} 
              startIcon={<Icon>clear</Icon>}
              onClick={undoEditing}
            >
              Cancelar
            </Button>
          </Fragment>
        : <Button 
            size={'small'} 
            startIcon={<Icon>edit</Icon>}
            onClick={() => setEditing(true)}
          >
            Alterar foto
          </Button>
      }

    </Fragment>
  );
}

export default UserAvatarField;