import firebase from 'firebase/app';
import 'firebase/functions';
import "firebase/analytics";
import "firebase/storage";
 
const defaultConfig = {
  apiKey: process.env.FIREBASE_apiKey,
  authDomain: process.env.FIREBASE_authDomain,
  databaseURL: process.env.FIREBASE_databaseURL, 
  projectId: process.env.FIREBASE_projectId,
  storageBucket: process.env.FIREBASE_storageBucket,
  messagingSenderId: process.env.FIREBASE_messagingSenderId,
  appId: process.env.FIREBASE_appId,
  measurementId: process.env.FIREBASE_measurementId
};

const firebaseApp  = firebase.initializeApp(defaultConfig);
if(process.env.FIREBASE_ENV == 'dev'){
  // console.log('Functions emulator');
  firebase.functions().useEmulator('localhost', 5001);
  // console.log('Auth emulator');
  const auth = firebase.auth();
  auth.useEmulator("http://localhost:9099", { disableWarnings: true });
  // console.log('Storage emulator');
  var storage = firebase.storage();
  storage.useEmulator("localhost", 9199);
} else if(process.env.FIREBASE_ENV == 'prod'){
  firebase.analytics();
}

export default firebaseApp;