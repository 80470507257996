import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function ModelingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 110 110">
      <path d="m54.5,16.3c-12.4,1.2 -14.5,-12.3 -14.5,-12.3s-14.2,2.6 -24.2,11.2s-12.8,14.1 -12.8,14.1l9.6,14.4l10.8,-1.8l0,54.1" fill="none" />
      <path d="m54.5,16.3c12.4,1.2 14.5,-12.3 14.5,-12.3s14.2,2.6 24.2,11.2s12.8,14.1 12.8,14.1l-9.6,14.4l-10.8,-1.8l0,54.1" fill="none" />
    </SvgIcon>
  );
}