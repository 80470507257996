import { Map, fromJS } from 'immutable';

import { 
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_INVALID, LOGIN_ALREADY_DONE, 
  REFRESH_AUTH,
  LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_ERROR, LOGOUT_REPEATED_USER_SUCCESS,
  // novo com Firebase
  USER_NOT_LOGGED, NO_ACCOUNT, SET_ACCOUNT, SET_ACCOUNT_SUCCESS, HAS_ACCOUNT, USER_IS_BLOCKED,
  SET_PARTITION, PROFILE_DATA_LOADED, PROFILE_CONFIGS_LOADED, ACCOUNT_EXPIRED, LOGOUT_WITH_MESSAGE,
  SET_PROFILE_PHOTOURL, SET_PROFILE_DISPLAYNAME, SET_PROFILE_EMAIL
} from '../constants';

var defaultAuth = Map({ 
  loading: false,
  checkingAuth: true,
  checkingProfile: true,
  loggedIn: false,
  hasAccount: false,
  welcomeScreen: false,
  creatingAccount: false,
  user: null,
  loginError: null,
  expired: false
});

export default function auth(state = defaultAuth, action) {

  if(!action) return state;

  switch (action.type) {

    case LOGIN_REQUEST:
      return state.set('loading', true)
                  .set('loggedIn', false)
                  .set('logoutReason', null)
                  .delete('loginError');

    case LOGIN_SUCCESS:
      return state.set('loading', false)
                  .set('loggedIn', true)
                  .set('checkingAuth', false)
                  .delete('loginError');

    case LOGIN_INVALID:
      return state.merge({ 
        loading: false,
        loggedIn: false,
        loginError: action.data.code
      });

    case LOGIN_ALREADY_DONE:
      return state.merge({ 
        loading: false,
        loggedIn: false,
        loginError: 'already-logged',
        credentials: {
          email: action.data.email,
          password: action.data.password
        }
      });

    case LOGIN_ERROR:
      return state.merge({ 
        loading: false,
        loggedIn: false,
        loginError: 'server-error' 
      });

    case REFRESH_AUTH:
      return state.merge({
        loading: false,
        loggedIn: true
      }); 

    case LOGOUT_WITH_MESSAGE:
      return state.merge({
        loggedIn: false,
        logoutReason: action.data.reason
      }).delete('loginError'); 

    case LOGOUT_SUCCESS:
      return state.merge({
        loggedIn: false,
        checkingProfile: true
      }).delete('loginError'); 
    //


    //
    case LOGOUT_REPEATED_USER_SUCCESS:
      return state.merge({
        loading: false,
        loggedIn: false,
        logoutReason: 'repeated'
      }).delete('loginError');

    //
    
    case USER_NOT_LOGGED:
      //console.log('usser not logged');
      return state.merge({
        loading: false,
        checkingAuth: false,
        checkingProfile: true,
        loggedIn: false
      });

    case NO_ACCOUNT:
      return state.merge({
        loading: false,
        checkingAuth: false,
        loggedIn: true,
        hasAccount: false,
        welcomeScreen: true,
        creatingAccount: false,
        user: action.data.user
      });

    case USER_IS_BLOCKED:
      return state.merge({
        loading: false,
        checkingAuth: false,
        blocked: true
      });
  
    case ACCOUNT_EXPIRED:
      return state.merge({
        loading: false,
        checkingAuth: false,
        expired: true
      });

    case SET_ACCOUNT:
      return state.merge({
        loading: false,
        creatingAccount: true
      });

    case SET_ACCOUNT_SUCCESS:
      return state.merge({
        loading: false,
        hasAccount: true,
        welcomeScreen: false,
        creatingAccount: false,
        accounts: action.data.accounts,
        currentAccountId: action.data.currentAccountId,
        user: state.get('user').set('displayName', action.data.userName)
      });

    case SET_PROFILE_PHOTOURL:
      return state.merge({
        user: state.get('user').set('photoURL', action.data)
      });

    case SET_PROFILE_DISPLAYNAME:
      return state.merge({
        user: state.get('user').set('displayName', action.data)
      });

    case SET_PROFILE_EMAIL:
      return state.merge({
        user: state.get('user').set('email', action.data)
      });

    case HAS_ACCOUNT:
      return state.merge({
        loading: false,
        checkingAuth: false,
        checkingProfile: false,
        loggedIn: true,
        hasAccount: true,
        welcomeScreen: false,
        creatingAccount: false,
        user: action.data.user,
        accounts: action.data.accounts,
        currentAccountId: action.data.currentAccountId
      });

    case SET_PARTITION:
      return state.merge({
        loading: false,
        partition: action.data.partition
      });

    case PROFILE_DATA_LOADED:
      var stateMerge = { loading: false };
      stateMerge[action.data + '_loaded'] = true;
      return state.merge(stateMerge);
    
    case PROFILE_CONFIGS_LOADED:
      var stateMerge = { loading: false };
      stateMerge['profile_configs_loaded'] = true;
      return state.merge(stateMerge);
      
    default:
      return state;
  }
}