import DataManagementStore from '../../../commons/DataManagementStore';

export default function SetBaseAttributes(data, payload, date, user, isListening){
    
    var itemId = payload['id'];
    var bases = data['bases'];
    if(itemId){

        var currentCollections = bases[itemId]['collections'];
        var updatedCollections = payload['collections'];
        currentCollections = payload['collections'];
    
        //////////////////////////////////////////////////////////
        if(isListening){
            DataManagementStore.updateItem(itemId,payload,false)
        };
    }   
    return data;
};