/**
 * Combine all reducers in this file and export the combined reducers.
 */

//import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import authReducer from './containers/Main/reducers/authReducer';
import userProfileReducer from './containers/Main/reducers/userProfileReducer';
import appReducer from './containers/Main/reducers/appReducer';
import accountReducer from './containers/Main/reducers/accountReducer';

/*//
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react -router-redux@5
 *
 */

// Initial routing state
//const routeInitialState = fromJS({
//  location: null,
//});

/**
 * Merge route into the global application state
 */
//function routeReducer(state = routeInitialState, action) {
  //return state;
  /*
  switch (action.type) {
    /* istanbul ignore next * /
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }*/
//}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    //route: routeReducer,
    app: appReducer,
    account: accountReducer,
    auth: authReducer,
    userProfile: userProfileReducer,
    ...injectedReducers,
  });
}
