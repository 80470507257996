'use strict';

import lunr from 'lunr';
//require('lunr-languages/lunr.stemmer.support')(lunr);
//require('lunr-languages/lunr.pt')(lunr); // or any other language you want
import MicroEvent from 'microevent';
import TreeFunctions from '../commons/Utils/TreeFunctions';
import { fromJS } from 'immutable';

var 
  _buckets = {},
  _lookup = {},
  _originalData = {},
  _cachedIndexes = {},
  _cachedResults = {},
  _collectionsByItems = {},

  // _systemTagsNamesConversion = {
  //   'body:top'    : 'superior',
  //   'body:bottom' : 'inferior'
  // },
  
  recentItems = [];

class DataManagementStore extends MicroEvent {

  constructor(props){
    super(props);
    //console.log(this);
    window.DataManagementStore = this;
    this.recentsItemsLoaded = false;
  }

  addUserBuckets(data){
    this.addBuckets([
      { key: 'userProjects',       data: data.projects /* , childItems: { key: 'trc', createBucket: 'userProjectsTracings' } */ },
      { key: 'userBases',          data: data.bases/*, childItems: { key: 'trc', createBucket: 'userBasesTracings' } */ },
      { key: 'accountSizesTables', data: data.sizestables },
      { key: 'accountFabrics',     data: data.fabrics }
    ]);
    // console.log('All Buckets >> ', _buckets);
    // console.log('Original data >> ', _originalData);
    // var userProjectsItems = _buckets.userProjects;
    this.trigger('userdata:request');
  }

  /*
  addTemplatesBuckets(data){
    this.addBuckets([
      { key: 'i C l o t h Projects',     data: data.i C l o t h Projects/ *, childItems: { key: 'trc', createBucket: 'i C l o t h ProjectsTracings' } * / },
      { key: 'i C l o t h Bases',        data: data.i C l o t h Bases/ *, childItems: { key: 'trc', createBucket: 'i C l o t h BasesTracings' }  * / },
      { key: 'i C l o t h SizesTables',  data: data.i C l o t h SizesTables }
    ]);
    this.trigger('userdata:request');
  }
  */

  getBuckets(){
    return _buckets;
  }

  hasBucket(bucketKey){
    return _buckets[bucketKey] != undefined;
  }

  getBucket(bucketKey){
    return _buckets[bucketKey];
  }

  getOriginalData(){
    return _originalData;
  }

  getCachedIndexes(){
    return _cachedIndexes;
  }

  getLookup(){
    return _lookup;
  }

  getItems(params){
    var 
      out = [],
      buckets = params.buckets;
    for(var b in buckets){
      // we need to remove any reference to the result
      if(!_buckets[buckets[b]]) continue;
      var items = JSON.parse(JSON.stringify(_buckets[buckets[b]].items));
      for(var i in items){
        items[i].id = i;
        out.push(items[i]);
      }
    }
    //console.log('items:', out);
    return out;
  }

  getById(id){
    var item = _lookup[id];
    if(item)
      item.id = id;
    return item;
  }

  getItemCollectionByItemId(itemId){
    return _collectionsByItems[itemId];
  }

  clearCachedIndexes(){
    _cachedIndexes = {};
    _cachedResults = {};
  }

  addBuckets(buckets){
    //console.log('Adding buckets');
    for(var i in buckets){
      var bucket = buckets[i];
      this.addBucket({
        key:  bucket.key,
        data: bucket.data,
        childItems: bucket.childItems
      });
    }
  }

  addBucket(source){

    //console.log('---- Adding Bucket:', source);

    var 
      data = source.data,
      key = source.key;
    // initializing bucket registry
    _originalData[key] = data;

    _buckets[key] = {
      collections: {},
      items: {},
      // tags: {},
      collectionsAttributes: {},
      collectionsAttributesTypes: {},
      collectionsTree: {},
      searchIndex: {
        items: [],
        // tags: [],
        collections: [],
        attributes: []
      },
      itemsFields: data.itemsFields
    };

    /* not used anymore
    for(var collectionId in data.collections){
      var currCollection = data.collections[collectionId];
      this.addCollectionToBucket({ bucketKey: key, collectionData: currCollection, collectionId: collectionId });
    }
    */

    for(var attributeId in data.collectionsAttributes){
      this.addCollectionAttributeToBucket({ bucketKey: key, attributeData: data.collectionsAttributes[attributeId], attributeId: attributeId });
    }

    for(var attributeTypeId in data.collectionsAttributesTypes){
      this.addCollectionAttributeTypeToBucket({ bucketKey: key, attributeTypeData: data.collectionsAttributesTypes[attributeTypeId], attributeTypeId: attributeTypeId });
    }

    for(var itemId in data.items){
      this.addItemToBucket({ bucketKey: key, itemData: data.items[itemId], itemId: itemId, childItems: source.childItems, silent: true });
    }

    this.buildTree({ bucketKey: key });

    //console.log(key, _buckets[key]);
  }

  buildTree(params){
    var 
      allPaths = [], 
      pathsHashes = {},
      collections = _buckets[params.bucketKey].collections;
    for(var collectionId in collections){
      var currCollection = collections[collectionId];
      var path = currCollection.path;
      allPaths.push(path.concat( { id: collectionId, isCollection: true } ));
      var pathHash = [];
      for(var p in path){
        pathHash.push(path[p].id);
      }
      pathsHashes[pathHash.join(':')] = collectionId;
    }
    _buckets[params.bucketKey].collectionsPathHashes = pathsHashes;
    _buckets[params.bucketKey].collectionsTree = TreeFunctions.createTreeFromPathsArray(allPaths, 'children', 'id').children;
  }

  getCollectionRootAttributes(params){
    var 
      out = [],
      tree = _buckets[params.bucketKey].collectionsTree;
    for(var i in tree){
      out.push(_lookup[i]);
    }
    return out;
  }

  getCollectionBranchAttributes(params){
    var 
      tree = _buckets[params.bucketKey].collectionsTree,
      path = JSON.parse(JSON.stringify(params.path));

    var currPath = tree[path[0]];

    if(!currPath) return null;

    if(path.length == 1) return this.childrenToArray(currPath.children);
  
    path.shift(1);

    for(var i in path){
      var 
        currAttribute = path[i],
        currChildren  = currPath.children;
      if(!currChildren[currAttribute]) return null;
      currPath = currChildren[currAttribute];
    }
    
    return this.childrenToArray(currPath.children);
  }

  childrenToArray(children){
    var out = [];
    for(var k in children){
      if(children[k].isCollection) return [];
      out.push(_lookup[k]);
    }
    return out;
  }

  getFlatCollectionList(params){
    //console.log('getFlatCollectionList', params);
    var 
      out = {},
      bucket = _buckets[params.bucketKey],
      attributesTypes = bucket.collectionsAttributesTypes,
      attributes = bucket.collectionsAttributes;
    //console.log(bucket);
    for(var i in attributesTypes){
      var 
        curr = attributesTypes[i],
        typeData = _lookup[i],
        data = {
          id: i,
          name: typeData.name,
          order: curr.order,
          children: []
        };
      var 
        ch = data.children;
      out[i] = data;
    }
    for(var i in attributes){
      var currAttr = attributes[i];
      out[currAttr.type].children.push(currAttr);
    }
    if(params.asArray == true){
      var outArr = [];
      for(var o in out){
        outArr[out[o].order] = out[o];
      }
      return outArr;
    }
    return out;
  }

  // gets all items (leaves) that have the specified collection branch as ancestor
  getCollectionBranchItems(params){

    if(params.path === 'root') return this.getItemsFromRoot(params);

    var 
      searchedPath = params.path.join(':'),
      hashes = _buckets[params.bucketKey].collectionsPathHashes,
      result = [];
    for(var currentPath in hashes){
      if(currentPath.indexOf(searchedPath) == 0){
        var items = _lookup[ hashes[currentPath] ].items;
        for(var i in items){
          var currItem = _lookup[items[i]];
          if(currItem && params.filter && typeof params.filter == 'function'){
            if(!params.filter(currItem)){
              continue;
            }
          }
          if(currItem){
            currItem.id = items[i];
            result = result.concat(currItem);
          }
        }
      }
    }
    return result;
  }

  // gets all items with getCollectionBranchItems() starting from the root
  getItemsFromRoot(params){
    var 
      bucketKey = params.bucketKey,
      tree = _buckets[bucketKey].collectionsTree,
      result = [];
    for(var attributeHash in tree){
      result = result.concat(this.getCollectionBranchItems({ bucketKey:bucketKey, path:[attributeHash], filter: params.filter }));
    }
    return result; 
  }

  addCollectionAttributeToBucket(params){
    var attributeData = params.attributeData;
    attributeData.__type = 'attribute';
    attributeData.__bucket = params.bucketKey;
    attributeData.id = params.attributeId;
    _lookup[params.attributeId] = attributeData;
    _buckets[params.bucketKey].collectionsAttributes[params.attributeId] = attributeData;
    _buckets[params.bucketKey].searchIndex.attributes.push({
      name: attributeData.name,
      id: params.attributeId
    });
    this.trigger('attribute'+ params.bucketKey);
    this.trigger(params.bucketKey+':add');
    this.clearCachedIndexes();
  }

  addCollectionAttributeTypeToBucket(params){
    var attributeTypeData = params.attributeTypeData;
    attributeTypeData.__type = 'attributeType';
    attributeTypeData.__bucket = params.bucketKey;
    _lookup[params.attributeTypeId] = attributeTypeData;
    _buckets[params.bucketKey].collectionsAttributesTypes[params.attributeTypeId] = attributeTypeData;
    this.trigger('attributetype'+ params.bucketKey);
    this.trigger(params.bucketKey+':add');
    this.clearCachedIndexes();
  }

  addItemToBucket(params){
    var 
      bucketKey = params.bucketKey,
      itemData  = params.itemData,
      colls = this.flattenItemCollections(itemData.collections || itemData.attributes);
    for(var typeId in colls){
      var currType = colls[typeId];
      for(var attrId in currType){
        if(currType[attrId] != true){
          this.addCollectionAttributeToBucket({
            attributeData: {
              id: attrId + '_' + currType[attrId],
              name: _lookup[attrId].name + ' (' + currType[attrId] + ')',
              add: currType[attrId],
              originalId: attrId,
              // order: 1,
              type: _lookup[attrId].type,
              __bucket: _lookup[attrId].__bucket,
              __type: _lookup[attrId].__type
            },
            attributeId: attrId + '_' + currType[attrId],
            bucketKey: bucketKey
          });
        }
      }
    }
  
    var
      itemProps = {
        id: params.itemId,
        name: itemData.name,
        created_at: itemData.created_at,
        // tags: itemData.tags,
        __type: 'item',
        __bucket: bucketKey,
        //status: itemData.st,
        collections: colls,
        active: itemData.active != undefined ? itemData.active : true
      };

      if(itemData.workspace){
        itemProps.workspace = itemData.workspace;
      }
      if(itemData.sent_by){
        itemProps.sent_by = itemData.sent_by;
      }

      if(itemData.syskey){
        itemProps.syskey = itemData.syskey;
      }

      if(params.parentId){
        itemProps.parentId = params.parentId;
      }

    _lookup[params.itemId] = itemProps;
    _buckets[bucketKey].items[params.itemId] = itemProps;

    if(_buckets[bucketKey].itemsFields){
      for(var itemKey in _buckets[bucketKey].itemsFields){
        if(itemData[itemKey] != null){
          itemProps[itemKey] = itemData[itemKey];
        }
      }
    }

    if(itemData.k){
      _lookup[itemData.k] = itemProps;
      //_buckets[bucketKey].items[itemData.k] = itemProps;
    }
    if(itemData.active != false){
      _buckets[bucketKey].searchIndex.items.push({
        id: params.itemId,
        name: itemData.name
      });
    }

    // if(itemData.tags && itemData.tags.length > 0){
    //   this.extractTagsFromItem({ bucketKey: bucketKey, itemData: itemData, itemId: params.itemId });
    // }

    if(params.childItems){
      var childItems = params.childItems;
      if(itemData[childItems.key]){
        if(!_buckets[childItems.createBucket]){
          _buckets[childItems.createBucket] = {
            collections: {},
            items: {},
            // tags: {},
            collectionsAttributes: {},
            collectionsAttributesTypes: {},
            collectionsTree: {},
            searchIndex: {
              items: [],
              // tags: [],
              collections: []
            }
          };
        }
        for(var childItemId in itemData[childItems.key]){
          this.addItemToBucket({ 
            bucketKey: childItems.createBucket, 
            itemData: itemData[childItems.key][childItemId], 
            itemId: childItemId,
            parentId: params.itemId 
          });
        }
      }
    }

    this.clearCachedIndexes();

    if(!params.silent) this.trigger(bucketKey + ':add');
  }

  flattenItemCollections(itemCollections){
    var out = {};
    //console.log('------------------------');
    //console.log(itemCollections);
    for(var attrTypeId in itemCollections){
      out[attrTypeId] = {};
      for(var attrId in itemCollections[attrTypeId]){
        out[attrTypeId][attrId] = itemCollections[attrTypeId][attrId];
      }
      this.getAllParentAttributes(attrId, out[attrTypeId]);
    }
    return out;
  }

  getAllParentAttributes(attrId, target){
    //console.log('=> id', attrId, target);
    var attr = _lookup[attrId];
    if(!attr) return;
    //console.log('======> obj', attr);
    var parent = attr.parent;
    if(parent){
      //console.log('tem parent', attr);
      target[parent] = true;
      if(_lookup[parent].parent){
        this.getAllParentAttributes(parent, target);
      }
    }
  }

  renameItem(itemId, name){
    _lookup[itemId].name = name;
    var bucketKey = _lookup[itemId].__bucket;
    var searchIndexItems = _buckets[bucketKey].searchIndex.items;
    searchIndexItems = searchIndexItems.map( itm => {
      if(itm.id == itemId) {
        itm.name = name;
        return itm;
      }
      return itm;
    });
    this.clearCachedIndexes();
    this.trigger(_lookup[itemId].__bucket + ':update');
  }

  updateItem(itemId, data, dontTrigger){
    if(!_lookup[itemId]) return null;
    for(var i in data){
      // console.log('DATA[i]',data[i]);
      _lookup[itemId][i] = data[i];
    }
    // console.log(_lookup[itemId]);
    //console.log(this);
    if(!dontTrigger){
      this.clearCachedIndexes();
      this.trigger(_lookup[itemId].__bucket + ':update');
    }
    // console.log('_lookup[itemId]',_lookup[itemId]);
    return _lookup[itemId];
  }

  trashItem(itemId){
    // console.log('Trashing item...', itemId);
    _lookup[itemId].active = false;
    var bucketKey = _lookup[itemId].__bucket;
    var searchIndexItems = _buckets[bucketKey].searchIndex.items;
    searchIndexItems = searchIndexItems.map( itm => {
      if(itm.id != itemId) return itm;
    });
    this.clearCachedIndexes();
    //this.clearRecentItems();
    this.trigger(_lookup[itemId].__bucket + ':update');
  }

  deleteAttributeItem(itemId, dontTrigger){
    if(!_lookup[itemId]) return null;
    var bucket = _lookup[itemId].__bucket;
    delete _lookup[itemId];
    delete _buckets[bucket].collectionsAttributes[itemId];
    if(!dontTrigger){
      this.clearCachedIndexes();
      // console.log(bucket + ':update');
      this.trigger(bucket + ':update');
    }
    return true;
  }

  deleteAttributeTypesItem(itemId, dontTrigger){
    //console.log(data);
    if(!_lookup[itemId]) return null;
    var bucket = _lookup[itemId].__bucket;
    delete _lookup[itemId];
    delete _buckets[bucket].collectionsAttributesTypes[itemId];
    if(!dontTrigger){
      this.clearCachedIndexes();
      // console.log(bucket + ':update');
      this.trigger(bucket + ':update');
    }
    return true;
  }

  // essa só pra depois (agora collections já vem com items)
  addItemToCollection(params){ // bucket, data, collection id
  }

  // para quando adicionar
  getCollectionByAttributes(params){ // attributes array,
    //console.log('procurando coleção com attributos:', params);
    var 
      bucket = _buckets[params.bucket],
      hashes = bucket.collectionsPathHashes,
      fullPath = params.attributes.join(':');
    //console.log(hashes, fullPath);
    if(hashes[fullPath]){
      //console.log("TEM!");
      return hashes[fullPath];
    } else {
      //console.log("NOOOO!");
      return null;
    }
  }

  getItemsByCollection(params){ // collection id,
  }

  getItemsByAttributes(){ // attributes ids array,
  }

  // extractTagsFromItem(params){
  //   var 
  //     bucketKey = params.bucketKey,
  //     bucket = _buckets[bucketKey],
  //     itemTags =   bucket.items[params.itemId].tags,
  //     bucketTags = bucket.tags;
  //   for(var t in itemTags){
  //     var 
  //       tagName = itemTags[t], 
  //       tagVisibleName = tagName;
  //     if(_systemTagsNamesConversion[tagName]){
  //       tagVisibleName = _systemTagsNamesConversion[tagName];
  //       itemTags[t] = tagVisibleName;
  //     }
  //     this.addTagToBucket({ bucketKey: bucketKey, tagName: tagName, tagVisibleName: tagVisibleName});
  //     this.addItemReferenceToTag({ bucketKey: bucketKey, tagName: tagName, tagVisibleName: tagVisibleName, itemId: params.itemId});
  //   }
  // }

  // addTagToBucket(params){
  //   var 
  //     tagName = params.tagName,
  //     bucketKey = params.bucketKey,
  //     tags = _buckets[bucketKey].tags,
  //     tagVisibleName = params.tagVisibleName;
    
  //   if(!tags[tagVisibleName]){
  //     tags[tagVisibleName] = { items: {} };
  //     var id = bucketKey + ':' + tagVisibleName;
  //     var tagProps = {
  //       id: id,
  //       sysName: tagName,
  //       name: tagVisibleName,
  //       __type: 'tag',
  //       __bucket: bucketKey
  //     };
  //     _lookup[id] = tagProps;
  //     _buckets[bucketKey].searchIndex.tags.push({
  //       id: id,
  //       sysName: tagName,
  //       name: tagVisibleName
  //     });
  //   }
  //   this.clearCachedIndexes();
  // }

  // addItemReferenceToTag(params){
  //   _buckets[params.bucketKey].tags[params.tagVisibleName].items[params.itemId] = true;
  //   this.clearCachedIndexes();
  // }

  // getItemsByTag(params){
  //   var tag = _buckets[params.bucketKey].tags[params.tagName];
  //   if(!tag) return null;
  //   var tagItems = tag.items;
  //   if(!tagItems) return null;
  //   var out = [];
  //   //console.log(tagItems);
  //   for(var i in tagItems){
  //     out.push(_lookup[i]);
  //   }
  //   return out;
  // }

  searchIntoBuckets(params){

    if(params.pattern == '') return [];

    var 
      buckets = params.buckets,
      types   = params.types,
      pattern = params.pattern;

    //console.log('--------------------- SEARCH for: ', pattern, buckets, types, ' ---------------------');

    var 
      searchTarget, 
      cachedIndexKey = buckets.join(',') + ':' + types.join(','),
      cachedResultKey = cachedIndexKey + ':' + pattern;

    if(_cachedResults[cachedResultKey]){
      //console.log(' => result already in cache');
      return _cachedResults[cachedResultKey];
    }
    //console.log(' => result NOT in cache');

    if(_cachedIndexes[cachedIndexKey]){
      //console.log('=> index: already in cache');
      searchTarget = _cachedIndexes[cachedIndexKey];
    } else {
      //console.log('=> index: NOT in cache, building...');
      
      var addToSearch = [];
      for(var i in buckets){
        //console.log(buckets[i]);
        if(!_buckets[buckets[i]]) continue;
        var currBucketIndex = _buckets[buckets[i]].searchIndex;
        for(var t in types){
          var currType = currBucketIndex[types[t]];
          for(var k in currType){
            var _current = currType[k];
            if(_lookup[_current.id].active !== false){
              var _add = { id: _current.id, name: _current.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")};
              addToSearch.push(_add);
            }
          }
        }
      }

      searchTarget = lunr(function () {
        this.ref('id')
        this.field('name')
        //this.use(lunr.pt);
        addToSearch.forEach(function (doc) {
          this.add(doc)
        }, this)
      })

      _cachedIndexes[cachedIndexKey] = searchTarget;
    }
    //console.log('searchTarget', searchTarget);

    pattern = pattern.replace('~', '')
                     .replace('^', '')
                     .normalize("NFD")
                     .replace(/[\u0300-\u036f]/g, "");

    if(pattern == '') return [];
    var searchResult = searchTarget.search(pattern + '*'); //  + '~1 ' + pattern + '*~1'
    var out = [];

    for(var r in searchResult){
      
      var resultId = searchResult[r].ref;
      var result = this.getById(resultId);
      result.id = resultId;
      out.push(result);
    }

    _cachedResults[cachedResultKey] = out;

    //console.table(out);
    //console.log('--------------------- /SEARCH ---------------------');
    return out;
  }

  filterResultSet(params){
    console.warn('testar esta função');
    var 
      resultSet = params.resultSet,
      pattern = params.pattern,
      searchTarget = lunr(function () {
        this.ref('id')
        this.field('name')
        //this.use(pt);
        for(var i in resultSet){
          this.add(resultSet[i]);
        } 
      });

    var searchResult = searchTarget.search(pattern); // +'*'

    var out = [];

    for(var r in searchResult){
      var resultId = searchResult[r].ref;
      var result = this.getById(resultId);
      result.id = resultId;
      out.push(result);
    }

    return out;
  }

  filterBucketByAttributes(params){
    if(!_buckets[params.bucket]){
      return [];
    };
    var items = _buckets[params.bucket].items;
    var searchedAttrs = params.attributes;
    var results = [];
    var hasAttrs = false;
    //console.log('=================> ATTRS:', searchedAttrs);
    for(var i in items){
      //console.log('=======> Arquivo', items[i].name);
      var collections = items[i].collections;
      var match = false;
      //console.log(collections);
      for(var searchedTypeId in searchedAttrs){
        var searchedAttr = searchedAttrs[searchedTypeId];
        if(searchedAttr == null || Object.keys(searchedAttr).length == 0) continue;
        var internalMatch = false;
        for(var attrId in searchedAttr){
          hasAttrs = true;
          if(!collections[searchedTypeId]) break;
          if(collections[searchedTypeId][attrId]){
            // at least one of them
            internalMatch = true;
            //console.log('bateu');
            break;
          }
          var currSearchedAttr = _lookup[attrId];
          if(currSearchedAttr.originalId){
            if(collections[searchedTypeId][currSearchedAttr.originalId] == currSearchedAttr.add){
              internalMatch = true;
              //console.log('bateu');
              break;
            }
          } else {
            
          }
        }
        if(internalMatch == false){
          match = false;
          break;
        } else {
          match = true;
        }
      }
      items[i].id = i;
      if(match){
        //console.log('Esse deu!');
        results.push(items[i]);
      }
    }
    if(!hasAttrs){
      return Object.values(items);
    }
    //console.log(results);
    return results;
  }

  // filterBucketByTags(params){
  //   var items = _buckets[params.bucket].items;
  //   var queriedTags = params.tags;
  //   var results = [];
  //   for(var i in items){
  //     //console.log('------------');
  //     var item = items[i];
  //     var tags = item.tags;
  //     var match = false;
      
  //     for(var t in queriedTags){
  //       //console.log(t, tags, tags.indexOf(t) > -1);
  //       if(tags && tags.indexOf(t) > -1) {
  //         match = true;
  //       } else {
  //         match = false;
  //         break;
  //       }
  //     }
  //     if(match){
  //       item.id = i;
  //       //console.log('Esse deu!');
  //       results.push(item);
  //     }
  //   }
  //   return results;
  // }

  getBucketOrderedBy(params){
    var itemsArray = [];
    var items = _buckets[params.bucket].items;
    if(Object.values){
      itemsArray = Object.values(items);
    } else {
      for(var i in items){
        itemsArray.push(items[i]);
      }
    }
    var field = params.field;
    //console.log(itemsArray);
    itemsArray = itemsArray.filter( it => it.active == true);
    itemsArray.sort((a, b) => {
      if (a[field] < b[field])
        return -1;
      if (a[field] > b[field])
        return 1;
      return 0;
    });
    if(params.order == 'desc'){
      itemsArray.reverse();
    }
    return itemsArray;
  }

  initRecentItems(timelineEvents){
    //console.log('init recent items');
    recentItems = [];
    var len = timelineEvents.length;
    for(var i=0; i<len; i++){
      this.addRecentItem(timelineEvents[i]);
    }
    this.recentsItemsLoaded = true;
    this.trigger('recentItemsLoaded');
  }

  addRecentItem(timelineEvent, notify){
    var item = _lookup[timelineEvent.i];
    if(!item) return;
    recentItems.push({
      event: timelineEvent,
      item: item
    });
  }

  getRecentItems(buckets, size = 10){
    //console.log('getting recents item', buckets);
    var 
      bucketsMap = {},
      len = recentItems.length,
      map = {},
      out = [],
      tot = 0;
    for(var b in buckets){
      bucketsMap[buckets[b]] = true;
    }
    for(var i=len-1; i>=0; i--){
      let item = recentItems[i];
      //console.log(item);
      if(item.item.active == false){
        //console.log('inactivo!');
        continue;
      }
      if(map[item.item.id]) {
        //console.log('já tem', item.item.name);
        continue;
      }
      if(!bucketsMap[item.item.__bucket]) {
        //console.log('Não é do tipo', item.item.name, item.item.__bucket);
        continue;
      }
      out.push(item);
      map[item.item.id] = true;
      tot++;
      if(tot == size) break;
    }
    return out;
  }

  clearRecentItems(){
    recentItems = [];
  }

}

let _DataManagementStore = new DataManagementStore();

export default _DataManagementStore;