import React from 'react';

const colorSchemes = {
  'red':    [ '#CC6666', '#CC9999' ],
  'yellow': [ '#efbc2a', '#edcc77' ],
  'green':  [ '#00a29d', '#04bab1' ],
  'grey':   [ '#999999', '#CCCCCC' ],
  'white':  [ '#FFFFFF', '#FFFFFF88'],
  'lightblue': ['#00bcd4', '#00bcd488']
}

export default function MouIcon(props) {
  const 
    [ dark, light ] = colorSchemes[props.colorScheme || 'white'],
    svg = <svg width={props.width} height={props.height} viewBox="66 0 710 260">
            <path fill={dark} d="m420,1.10995a127.84,127.84 0 1 0 127.84,127.84a127.83,127.83 0 0 0 -127.84,-127.84zm0,200.84a73,73 0 1 1 73,-73a73,73 0 0 1 -73,73z" />
            <path fill={light} d="m134.34,79.04995a73,73 0 0 1 -73,-73l-54.85,0a127.85,127.85 0 1 0 255.69,0l-54.84,0a73,73 0 0 1 -73,73z" />
            <rect fill={dark} height="245.81" width="54.86" x="207.32" y="6.04"/>
            <rect fill={dark} height="245.81" width="54.86" x="6.49" y="6.04"/>
            <path fill={dark} d="m778.64,6.03995l0,122.91l0,0a73,73 0 0 1 -146,0l0,0l0,-122.91l-54.82,0l0,122.91a127.85,127.85 0 0 0 255.69,0l0,-122.91l-54.87,0z" />
          </svg>;
  if(props.innerIcon){
    return (
      <div style={{position:'relative'}}>
        {svg}
        <div style={{position: 'absolute', top: -7, left: 63}}>
          {props.innerIcon}
        </div>
      </div>
    );
  }
  return svg;
}