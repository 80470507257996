import DataManagementStore from '../../../commons/DataManagementStore';

export default function TrashSizesTable(data, payload, date, user, isListening){

  //console.log('Trash project handler');

  data.sizestables[payload.id].active = false;

  if(isListening){
    // live side effects
    DataManagementStore.trashItem(payload.id);
  }

  return data;
}