import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function SizesTableIcon(props) {
  return (
    <SvgIcon {...props} viewBox="30 20 70 100">
      <polyline points="95,120 95,26 35,26 35,40.30000305175781 35,121 " fill="none" />
      <path fill="none"  d="m94.5,25.5l-15,0l-29,0l-15,0l0,19.6c2.1,3.2 5.8,5.4 10,5.4c2.3,0 4.4,-0.6 6.2,-1.8c3.5,3.2 8.1,5.3 13.3,5.3s9.8,-2 13.3,-5.3c1.8,1.1 3.9,1.8 6.2,1.8c4.2,0 7.9,-2.1 10,-5.4l0,-19.6z" />
      <ellipse fill="none"  cx="66" cy="38.5" rx="9.5" ry="5" />
      <line x1="70" x2="35" y1="63" y2="63"/>
      <line x1="57" x2="35" y1="73" y2="73"/>
      <line x1="70" x2="35" y1="83" y2="83"/>
      <line x1="57" x2="35" y1="93" y2="93"/>
      <line x1="70" x2="35" y1="102" y2="102"/>
      <line x1="57" x2="35" y1="112" y2="112"/>
    </SvgIcon>
  );
}