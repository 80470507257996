/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectAuth = (state) => state.get('auth');

const selectApp = (state) => state.get('app');

const makeSelectAuth = () => createSelector(
  selectAuth,
  (authState) => authState
);

const makeSelectApp = () => createSelector(
  selectApp,
  (appState) => appState
);

export {
  selectAuth,
  makeSelectAuth,
  makeSelectApp
};
