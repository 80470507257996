import { Button, CircularProgress, Icon, Link, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

function RecoverEmail(props){
  const
    [ status, setStatus ] = useState('loading'),
    confirmRecover = () => {
      props.actions.recoverUserEmail(
        props.actionCode,
        result => {
          setStatus(result);
        }
      );
    };
  useEffect( 
    () => {
      props.actions.checkActionCode(
        props.actionCode, 
        (result, info) => {
          if(result === 'correct-code'){
            setStatus('confirming');
          } else if(result === 'wrong-code'){
            setStatus('wrong-code');
          }
        }
      )
    },
    []
  )
  return (
    <div style={{textAlign: 'center', paddingTop: 20}}>
      {status === 'loading' &&
        <CircularProgress />
      }
      {status === 'wrong-code' &&
        <Typography variant={'body1'}>
          Link inválido. 
          <br />
          <Link href={'/'} color={'primary'}>
            Voltar para a MOU
          </Link>.
        </Typography>
      }
      {status === 'confirming' &&
        <Typography variant={'body1'}>
          Deseja restaurar seu email?
          <br />
          <Button
            color={'primary'}
            onClick={confirmRecover}
          >
            <Icon>check</Icon>
          </Button> <Link href={'/'} style={{textDecoration: 'none'}}><Button
            color={'primary'}
          >
            <Icon>clear</Icon>
          </Button></Link>
        </Typography>
      }
      {status === 'success' &&
        <Typography variant={'body1'}>
          E-mail restaurado com successo. <Link href={'/'} color={'primary'}>
            Voltar para a MOU
          </Link>.
        </Typography>
      }
      {status === 'error' &&
        <Typography variant={'body1'}>
          Não foi possível resstaurar seu e-mail. Por favor, tente novamente mais tarde. <Link href={'/'} color={'primary'}>
            Voltar para a MOU
          </Link>.
        </Typography>
      }
    </div>
  );
}

export default RecoverEmail;