import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import {Link} from 'react-router-dom';

import AdminUserAvatar from '../../../../components/Utils/Avatar/AdminUserAvatar';
import { withTranslation } from 'react-i18next';

import { userCan } from '../../../../commons/Permissions';

import CreationImage from "../../../../images/admin_thumb.png";

const styles = theme => ({
  card: {
    display: 'flex',
    marginBottom: 15
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '30%',
    height: 200
  },
  areaLink: {
    color: '#6f6f6f', 
    textDecoration: 'none'
  }
});

function DashboardSectionAdministration(props) {
  const { classes, theme, t } = props;

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cover}
        image={CreationImage}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            Administração
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Administração e configuração
          </Typography>
          <div className={'row'} style={{paddingTop: 30}}>
            {userCan('manageUsers') &&
              <div className="col-xs-4">
                <div className={'box'} style={{width: '100%'}}>
                <Link to="/administration/users">
                  <AdminUserAvatar style={{margin: 'auto'}} />
                </Link>
                </div>
              </div>
            }
            {(userCan('manageWorkspaces') || userCan('manageItsWorkspaces')) &&
              <div className="col-xs-4">
                <div className={'box'} style={{width: '100%'}}>
                <Link to="/administration/workspaces">
                  <AdminUserAvatar style={{margin: 'auto'}} />
                </Link>
                </div>
              </div>
            }
          </div>
          <div className={'row'}>
            {userCan('manageUsers') &&
              <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px'}}>
                <Link to="/administration/users" className={classes.areaLink}><span>Usuários</span></Link>
              </div>
            }
            {(userCan('manageWorkspaces') || userCan('manageItsWorkspaces')) &&
              <div className="col-xs-4" style={{textAlign: 'center', fontSize: '13px'}}>
                <Link to="/administration/workspaces" className={classes.areaLink}><span>Grupos</span></Link>
              </div>
            }
          </div>
        </CardContent>

      </div>
      
    </Card>
  );
}

export default withTranslation(['global', 'dashboard'])(withStyles(styles, { withTheme: true })(DashboardSectionAdministration));

