import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/functions';
import firebaseApp from '../../commons/Firebase';

import { browserHistory } from 'react-router-dom';
import DataManagementStore from '../../commons/DataManagementStore';

import getUrlQueryVariable from '../../commons/Utils/getUrlQueryVariable';
import awaitData from '../../commons/Utils/awaitData';

import LogRocket from 'logrocket';

import { 
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_INVALID, LOGIN_ALREADY_DONE, 
  REFRESH_AUTH,
  LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_ERROR, LOGOUT_REPEATED_USER_SUCCESS, LOGOUT_WITH_MESSAGE,
  USER_NOT_LOGGED, NO_ACCOUNT, SET_ACCOUNT, SET_ACCOUNT_SUCCESS, HAS_ACCOUNT, USER_IS_BLOCKED, ACCOUNT_EXPIRED,
  SET_PARTITION, PROFILE_DATA_LOADED, PROFILE_CONFIGS_LOADED,
  RECENT_ITEM_TYPE_LOADED, RECENT_ITEM_TYPE_ADDED,
  START_SPECSHEET, START_SKETCH, STOP_SPECSHEET, STOP_SKETCH, WINDOW_RESIZE, SET_SCREEN_LAYOUT,
  CHANGE_USERNAME, SET_PROFILE_PHOTOURL, SET_PROFILE_DISPLAYNAME, SET_PROFILE_EMAIL
} from './constants';

import buildProfileFromEvents from '../../commons/events/builder';
import { addPermissions, resetPermissions } from '../../commons/Permissions';
import { startPartitionListener } from '../../commons/events/listener';
import { listenToUserTimeline } from '../../commons/user-timeline';
import { getHotItem } from '../../commons/storage/storage';

import { USER_CONFIG_SET } from './constants';
import { addMetadata, clearMetadata } from '../../commons/MetadataEngine/MetadataEngine';

var db = firebaseApp.database();
var Fingerprint2 = require('fingerprintjs2');
const FBFcreatePartitionEvent = firebase.functions().httpsCallable('createPartitionEvent');
//const FBFcreateAccountData = firebase.functions().httpsCallable('createAccountData');
const FBFimportSharedItem = firebase.functions().httpsCallable('importSharedItem');
const FBFshareItem = firebase.functions().httpsCallable('shareItem');
const FBFgetSharedItemInfos = firebase.functions().httpsCallable('getSharedItemInfos');
const FBFinitUserProfile = firebase.functions().httpsCallable('initUserProfile');

var currentPartitionId = null;

/**
 * Changes the input field of the form
 *
 * @param  {name} name The new text of the input field
 *
 * @return {object}    An action object with a type of CHANGE_USERNAME
 */
/*
export function changeUsername(name) {
  return {
    type: CHANGE_USERNAME,
    name
  };
}
*/

export function googleLogin(){
  return (dispatch, getState) => {
   // console.log('google login!');
  
    var provider = new firebase.auth.GoogleAuthProvider();
    firebaseApp.auth().signInWithPopup(provider).then((result) => {
      dispatch(loginSuccess(result));
    }).catch((error) => {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          section: 'login',
          method: 'googleLogin'
        }/*,
        extra: {
          // additional arbitrary data associated with the event
          profile: profile
        },*/
      });
     // console.log("Google sign in error: "+ error.message);
      dispatch(loginInvalid({ msg: error.message, provider:'google' })); //
    });
  }
}

export function signInWithEmailAccount(email,password, remeberMe){
  return (dispatch, getState) => {
    const
      persistence = remeberMe
                      ? firebase.auth.Auth.Persistence.LOCAL
                      : firebase.auth.Auth.Persistence.SESSION;
    dispatch(loginRequest());
    firebase.auth().setPersistence(persistence).then(() => {
      firebaseApp.auth().signInWithEmailAndPassword(email,password).then((result) => {
        dispatch(loginSuccess(result));
      }).catch((error) => {
       // console.log("signInWithEmailAndPassword in error: "+ error.message);
        LogRocket.captureException(error, {
          tags: {
            // additional data to be grouped as "tags"
            section: 'login',
            method: 'signInWithEmailAccount',
            firebaseMethod: 'signInWithEmailAndPassword'
          }
        });
        dispatch(loginInvalid({ msg: error.message, code: error.code, provider: 'email' })); //
      });
    }).catch(function(error) {
     // console.log("signInWithEmailAndPassword in error: "+ error.message);
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          section: 'login',
          method: 'signInWithEmailAccount',
          firebaseMethod: 'setPersistence'
        }
      });
      dispatch(loginInvalid({ msg: error.message, code: error.code, provider: 'email' })); //
    });
  }
}

export function sendPasswordResetEmail(email, callback){
  return (dispatch, getState) => {
    firebaseApp.auth()
                .sendPasswordResetEmail(email)
                .then( result => callback(null))
                .catch( err => {
                  LogRocket.captureException(err, {
                    tags: {
                      // additional data to be grouped as "tags"
                      section: 'resetPassword',
                      method: 'sendPasswordResetEmail',
                      firebaseMethod: 'sendPasswordResetEmail'
                    }
                  });
                  callback(err);
                });
  }
}

export function createEmailAccount(email,password){
  return (dispatch, getState) => {
    dispatch(loginRequest());
    firebaseApp.auth().createUserWithEmailAndPassword(email,password).then(function(result) {
      dispatch(loginSuccess(result));
    }).catch(function(error) {

      dispatch(loginInvalid({ msg: error.message, code: error.code, provider:'email' })); //
    });
  }
}

///////////////////

function checkFingerPrint(user, dispatch){
  var amOnline = db.ref('/.info/connected');
  var userRef = db.ref('/presence/' + user.uid);
  Fingerprint2.get(function (components) {
    var 
      values = components.map(function (component) { return component.value }),
      fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
    amOnline.on('value', (snapshot) => {
      if (snapshot.val()) {
        userRef.set(fingerprint);
      }
    });
    userRef.on('value', (snapshot) => {
      if(snapshot.val() !== fingerprint){
        doLogout(dispatch, 'user-repeated');
      }
    });
  });
}

export function checkAuth(join){
  return  (dispatch, getState) => {
    firebaseApp.auth().onAuthStateChanged( async user => {
      if (!user) {
        dispatch(userNotLogged());
      } else {
        dispatch(loginSuccess(user));
        clearMetadata();
        // avoid multiple user login 
        if (window.requestIdleCallback) {
          requestIdleCallback(() => { checkFingerPrint(user, dispatch) });
        } else {
          setTimeout(() => { checkFingerPrint(user, dispatch) }, 500);
        }
        // profile data
        try {
          var
            fullUserData = await FBFinitUserProfile().then(({data}) => data);
          if(fullUserData.error){
            if(fullUserData.error.message){
              switch(fullUserData.error.message){
                case 'INTERNAL': return doLogout(dispatch, 'init-profile-error');
                default: return doLogout(dispatch, 'init-profile-error');
              }
            } else {
              return doLogout(dispatch, 'init-profile-error');
            }
          }
          if(fullUserData.userError){
            switch(fullUserData.userError){
              case 'no-account': return dispatch(noAccount(user));
              case 'user-blocked': return doLogout(dispatch, 'user-blocked');
              case 'account-expired': return doLogout(dispatch, 'account-expired');
              case 'account-inactive': return doLogout(dispatch, 'account-inactive');
              case 'host-account-inactive': return doLogout(dispatch, 'host-account-inactive');
              case 'host-account-expired': return doLogout(dispatch, 'host-account-expired');
              case 'guest-disabled-by-host': return doLogout(dispatch, 'guest-disabled-by-host');
              default: return doLogout(dispatch, 'init-profile-error');
            }
          }
        } catch(err) {
          // console.log(err);
          LogRocket.captureException(err);
          return doLogout(dispatch, 'init-profile-error');
        }
        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email,
          partitionId: fullUserData.partitionId,
          currentAccountId: fullUserData.currentAccountId,
          personalAccountId: fullUserData.personalAccount?.id
        });
        
        var
          profile = await buildProfileFromEvents({ 
            userId: user.uid, 
            partitionId: fullUserData.partitionId, 
            hash: fullUserData.personalAccount.hash 
          }),
          itemsTypes = {
            projects:    'userProjects',
            bases:       'userBases',
            sizestables: 'accountSizesTables'
          };
        profile.workspaces = fullUserData.workspaces;
        profile.currentAccountRoles = fullUserData.currentAccountRoles;
        profile.hosts = fullUserData.hosts;
        profile.personalAccount = fullUserData.personalAccount;
        profile.currentAccountId = fullUserData.currentAccountId;
        profile.currentAccountName = fullUserData.personalAccount.id == fullUserData.currentAccountId
                                        ? fullUserData.personalAccount.name
                                        : fullUserData.hosts.filter( h => h.id == fullUserData.currentAccountId)[0].name;
        profile.adminDisclaimerAgree = fullUserData.personalAccount.id == fullUserData.currentAccountId
                                        ? fullUserData.personalAccount.adminDisclaimerAgree
                                        : fullUserData.hosts.filter( h => h.id == fullUserData.currentAccountId)[0].adminDisclaimerAgree;
        profile.capabilities = fullUserData.capabilities;
        profile.accounts = {};
        profile.subscriptionStatus = fullUserData.subscriptionStatus;
        if(profile.personalAccount){
          profile.accounts[profile.personalAccount.id] = profile.personalAccount;
        }
        profile.hosts.forEach( h => {
          profile.accounts[h.id] = h;
        });
        profile.workspacesTypes = fullUserData.workspacesTypes;
        profile.currentAccountUsersMaxNum = fullUserData.currentAccountUsersMaxNum;
        if(fullUserData.mouAdm){
          profile.mouAdm = fullUserData.mouAdm;
        }
        resetPermissions();
        addPermissions(fullUserData.capabilities);
        dispatch(setPartition(fullUserData.partitionId));


        for(var itemType in itemsTypes){
          DataManagementStore.addBucket({
            key: itemsTypes[itemType],
            data: {
              collectionsAttributes: profile.attrs[itemType],
              collectionsAttributesTypes: profile.attrsTypes[itemType],
              items: profile[itemType],
              itemsFields: {
                sharedWith: true,
                sharedFrom: true,
                sharedFromPartition: true,
                sharedAt: true
              }
            }
          });
          dispatch(profileDataLoaded(itemsTypes[itemType]));
        }

        DataManagementStore.addBucket({
          key: 'fabrics',
          data: {
              collectionsAttributes: profile.attrs['fabrics'],
              collectionsAttributesTypes: profile.attrsTypes['fabrics'],
            items: profile.assets.fabrics,
            itemsFields: {
              width: true,
              height: true,
              shrinkageY:true,
              shrinkageX:true,
              marginRight:true,
              marginBottom:true,
              marginLeft:true,
              marginTop:true,
              price:true,
              weigth:true,
              available:true
            }
          }
        });

        DataManagementStore.addBucket({
          key: 'nestingTables',
          data: {
            items: profile.assets.nestingTables,
            itemsFields: {
              width: true,
              height: true,
              active: true
            }
          }
        });

        DataManagementStore.addBucket({
          key: 'nestableProjects',
          data: {
            items: profile.nestableProjects,
            itemsFields: {
              released_at: true,
              released_by: true,
              version: true
            }
          }
        });
        dispatch(profileDataLoaded('nestableProjects'));

        DataManagementStore.addBucket({
          key: 'userNestings',
          data: {
            items: profile.nestings,
            itemsFields: {
              created_at: true,
              created_by: true,
              projects: true
            }
          }
        });
        dispatch(profileDataLoaded('userNestings'));

        DataManagementStore.initRecentItems(getHotItem('timeline').events);

        DataManagementStore.trigger('userdata:request');

        startPartitionListener();

        listenToUserTimeline(user.uid, fullUserData.partitionId);
        
        //// hideInitialLoader();
        dispatch(hasAccount(user, fullUserData.accounts, fullUserData.currentAccountId));
      }
    });       
  }
}


function profileDataLoaded(type){
  return {
    type: PROFILE_DATA_LOADED,
    data: type
  }
}

// end profile functions



function userNotLogged(){
  return {
    type: USER_NOT_LOGGED
  }
}

function noAccount(user){
  return {
    type: NO_ACCOUNT,
    data: {
      user: {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email
      }
    }
  }
}

function hasAccount(user, accounts, currentAccountId){
  return {
    type: HAS_ACCOUNT,
    data: {
      user: {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL
      },
      accounts,
      currentAccountId
    }
  }
}



function setPartition(partition){
  //console.log('the partion::::', partition);
  return {
    type: SET_PARTITION,
    data: {
      partition: partition
    }
  }
}


function loginSuccess(data) {
  //localStorage.setItem('sess:' + data.user.uid, generateId());
  return {
    type: LOGIN_SUCCESS,
    data: data
  }
};

function loginRequest() {
  return {
    type: LOGIN_REQUEST
  }
};

function loginInvalid(json) {
  return {
    type: LOGIN_INVALID,
    data: json
  }
};

function refreshAuth(json) {
  return {
    type: REFRESH_AUTH,
    data: json
  }
};

function logoutSuccess(json) {
  DataManagementStore.clearRecentItems();
  return {
    type: LOGOUT_SUCCESS,
    data: json
  }
};

function logoutRepeatedUserSuccess(json) {
  return {
    type: LOGOUT_REPEATED_USER_SUCCESS,
    data: json
  }
};

function logoutWithMessage(json) {
  return {
    type: LOGOUT_WITH_MESSAGE,
    data: json
  }
};

export function logout(){
  return dispatch => {
    doLogout(dispatch);
  };
}

function doLogout(dispatch, reason){
  var 
    auth = firebaseApp.auth()//,
    //uid = auth.getUid()
    ;
  //localStorage.removeItem('sess:', uid);
  resetPermissions();
  clearMetadata();
  auth.signOut().then( () => {
    if(reason){
      if(reason == 'user-repeated'){
        dispatch(logoutRepeatedUserSuccess());
      } else {
        dispatch(logoutWithMessage({reason}));
      }
    } else {
      dispatch(logoutSuccess());
    }
  });
}


export function refreshUserAuth(data){
  return  dispatch => {
    dispatch(refreshAuth(data));
  } 
}

/// -------------------------- user profile ---------------------

export function setUserConfig(pathWithoutInitialSlash, value, callback){
  return (dispatch, getState) => { 
    db.ref('/userConfigs/' + getState().get('auth').get('user').get('uid') + '/' + pathWithoutInitialSlash).set(value).then(() => {
      dispatch(userConfigSet(pathWithoutInitialSlash, value));
      if(callback) callback();
    });
  }
}

function userConfigSet(path, value){
  return { 
    type: USER_CONFIG_SET,
    data: {
      path, value
    }
  }
}

// ------------------------ items (files) ---------------------

export function getRecentItems(type){
  return (dispatch, getState) => {
    awaitData(getState, 'auth', type + '_loaded', () => {
      //console.log('get recent');
      if(getState().get('userProfile').get('recent_' + type + '_loading') == true){
        var userId = getState().get('auth').get('user').get('uid');
        var recent = db.ref('userTimeline/' + userId).orderByChild('action_targetType').equalTo('read_' + type).limitToLast(500);
        recent.on('value', snapshot => {
          var items = [];
          var recentsMap = {};
          snapshot.forEach(item => {
            var data = item.val();
            data.name = DataManagementStore.getById(data.targetId).name;
            items.unshift(data);
          });
          dispatch(recentItemTypeLoaded(type, items));
        });
      }
    });
  } 
}
function recentItemTypeLoaded(type, items){
  return {
    type: RECENT_ITEM_TYPE_LOADED,
    data: {
      type: type,
      items: items
    }
  }
}


export function createItemCollection(itemType, data, callback){
  return  (dispatch, getState) => {
    //console.log('CRIANDO collection:', data);
    var partitionId = getState().get('auth').get('partition');
    var hashRef = '/' + itemType + '_collections_hashes/' + partitionId + '/' + data.attributes_uuids.sort().join('');
    db.ref(hashRef).once('value').then( (snapshot) => {
      var newCollectionId;
      if(!snapshot.exists()){
        var updateObj = {};
        newCollectionId = db.ref().push().key;
        updateObj[hashRef] = newCollectionId;
        updateObj['/' + itemType + '/' + partitionId + '/collections/' + newCollectionId + '/path'] = data.attributes_uuids;
        db.ref().update(updateObj).then( () => {
         // console.log('Resposta de createItemCollection: Criei', newCollectionId);
          if(callback) callback(newCollectionId);
        }); 
      } else {
        newCollectionId = snapshot.val();
       // console.log('Resposta de createItemCollection, Já tinha', newCollectionId);
        if(callback) callback(newCollectionId);
      }
    });
  }
}


export function resizeWindow(){
  return  (dispatch) => {
    dispatch(windowResized());
  } 
}

function windowResized(){
  return { 
    type: WINDOW_RESIZE
  }
}

export function startSpecSheet(){
  return  (dispatch) => {
    dispatch(specSheetStarted());
  } 
}

export function stopSpecSheet(){
  return  (dispatch) => {
    dispatch(specSheetStopped());
  } 
}

export function startSketch(){
  return  (dispatch) => {
    dispatch(sketchStarted());
  } 
}

export function stopSketch(){
  return  (dispatch) => {
    dispatch(sketchStopped());
  } 
}

export function addAttributeToBucket(data,callback){
  //// console.log('DATA ATTR',data);
  data.id = db.ref().push().key;
  // var callback = null;
  return (dispatch) => {
    createPartitionEvent(dispatch,null,data,'CreateAttribute',callback);
  }
};

export function addAttributeTypeToBucket(data){
  data.id = db.ref().push().key;
  var callback = null;
  return (dispatch) => {
    createPartitionEvent(dispatch, callback,data,'CreateAttributeType');
  } 
}

export function deleteAttributeType(data){
  var callback = null;
  return (dispatch) => {
    createPartitionEvent(dispatch, callback,data,'RemoveAttributeType');
  } 
}

export function deleteAttribute(data){
  var callback = null;
  return (dispatch) => {
    createPartitionEvent(dispatch, callback,data,'RemoveAttribute');
  } 
}

function createPartitionEvent(dispatch, dispatchCallback,data,event,callback){
   currentPartitionId = getHotItem('profile').partitionId;
   FBFcreatePartitionEvent({
    partitionId: currentPartitionId,
    event: event,
    payload: data
  }).then( (result) => {
    var dataThen = result.data;
    if(dataThen.success == true){
     // console.log('deu', dataThen);
      if(callback){
        callback();
      };
      if(dispatchCallback){
        dispatch(dispatchCallback(data))
      } 
    } else {
     // console.warn(dataThen);
    }
  });
};

export function trashItem(itemType, itemId){
  return (dispatch) => {
    if(itemType == 'userBases'){
      createPartitionEvent(dispatch, null, { id: itemId }, 'TrashBase');
    } else if(itemType == 'userProjects'){
      createPartitionEvent(dispatch, null, { id: itemId }, 'TrashProject');
    } else if(itemType == 'accountSizesTables'){
      createPartitionEvent(dispatch, null, { id: itemId }, 'TrashSizesTable');
    }
    //createPartitionEvent(dispatch, callback,data,'RemoveAttribute'); accountSizesTables
  } 
}

export function renameItem(itemType, itemId, name){
  return (dispatch) => {
    if(itemType == 'userBases'){
      createPartitionEvent(dispatch, null, { id: itemId, name: name }, 'RenameBase');
    } else if(itemType == 'userProjects'){
      createPartitionEvent(dispatch, null, { id: itemId, name: name }, 'RenameProject');
    } else if(itemType == 'accountSizesTables'){
      createPartitionEvent(dispatch, null, { id: itemId, name: name }, 'RenameSizesTable');
    }
    //createPartitionEvent(dispatch, callback,data,'RemoveAttribute'); accountSizesTables
  } 
}

export function updateItemAttributes(itemType,itemId,data){
  //itemType is bucket name
  //data is the updated information 
  return(dispatch) =>{
    if(itemType == 'userBases'){
      createPartitionEvent(dispatch,null,data,'SetBaseAttributes');
      // createPartitionEvent(dispatch,null,{id:itemId,payload:data},'SetBaseAttributes');

    }else if(itemType == 'userProjects'){
      createPartitionEvent(dispatch,null,data,'SetProjectAttributes');
    }else if(itemType == 'accountSizesTables'){
      createPartitionEvent(dispatch,null,data,'SetSizestableAttributes');
    }
    // }else if(itemType == 'fabrics'){
    //  // console.log('fabrics');
    //   createPartitionEvent(dispatch,null,data,'SetFabricAttributes');
    // };
    // return(dispatch) =>{
    //     createPartitionEvent(dispatch,null,data,'updateItemAttributes')
    // }
  }
};


function specSheetStarted(){
  return { 
    type: START_SPECSHEET
  }
}

function sketchStarted(){
  return { 
    type: START_SKETCH
  }
}

function specSheetStopped(){
  return { 
    type: STOP_SPECSHEET
  }
}

function sketchStopped(){
  return { 
    type: STOP_SKETCH
  }
}

export function setScreenLayout(layout){
  return  (dispatch) => {
    dispatch({
      type: SET_SCREEN_LAYOUT,
      data: layout
    });
  } 
}

export function getSharedItemInfos(id, type, callback, purpose){
  return (dispatch, getState) => {
    FBFgetSharedItemInfos({ type, id, purpose }).then( result => {
      if(callback){
        callback(result);
      }
    });
  }
}

export function importSharedItem(id, type, callback, purpose = 'share'){
  return (dispatch, getState) => {
    var
      partitionId = getState().get('auth').get('partition');
    FBFimportSharedItem({ id, type, partitionId, purpose }).then( ({data}) => {
      if(callback){
        callback(data);
      }
    });
  }
}

export function shareItem(id, type, callback){
  return (dispatch, getState) => {
    var 
      auth = getState().get('auth'),
      callbackUrl = document.location.origin + '/shared';
    FBFshareItem({
      name: DataManagementStore.getById(id).name,
      source: 'my',
      targetType: type,
      targetId: id,
      partitionId: auth.get('partition'),
      reqId: db.ref().push().key,
      userId: auth.get('user').get('uid')
    }).then( ({data}) => {
      if(callback) callback(callbackUrl + '/' + type + '/' + data);
    });
  }
}

const FBFshareItemByIdsOrEmails = firebase.functions().httpsCallable('shareItemByIdsOrEmails');

export function shareItemByIdsOrEmails(data, callback){
  return (dispatch, getState) => {
    var 
      { itemId, itemType } = data,
      auth = getState().get('auth'),
      sharePayload = {
        name: DataManagementStore.getById(itemId).name,
        source: 'my',
        targetType: itemType,
        targetId: itemId,
        partitionId: auth.get('partition'),
        reqId: db.ref().push().key,
        userId: auth.get('user').get('uid'),
        allowedEmails: data.emails || null,
        allowedIds: data.ids || null
      },
      callbackUrl = document.location.origin + '/shared';
    FBFshareItemByIdsOrEmails(sharePayload).then( ({data}) => {
      if(data.success === false){
        return callback({success:false, msg: data.msg});
      }
      callback({
        success: true, 
        shareLink: callbackUrl + '/' + itemType + '/' + data
      });
    }).catch( err => {
      callback({success:false, msg: err});
    });
  }
}

const FBFcreateJiraServiceDeskRequest = firebase.functions().httpsCallable('createJiraServiceDeskRequest');

export function createSupportRequest(supportData, callback){
  return (dispatch, getState) => {
    console.log(supportData); //return;
    var
      fullData = { ...supportData };
    if(supportData.sendFile === true){
      const 
        auth = getState().get('auth'),
        id = supportData.itemId;
      fullData = {
        ...fullData,
        name: DataManagementStore.getById(id).name,
        source: 'my',
        targetType: supportData.itemType,
        targetId: id,
        partitionId: auth.get('partition'),
        reqId: db.ref().push().key,
        userId: auth.get('user').get('uid')
      }
    }
    FBFcreateJiraServiceDeskRequest(fullData).then(({data}) => {
      if(callback) callback(data);
    });
  }
}

const FBFsendItemToWorkspace = firebase.functions().httpsCallable('sendItemToWorkspace');

export function sendItemToWorkspace(workspaceId, targetMembers, itemType, callback){
  return (dispatch, getState) => {
    //console.log('------- sending!', workspaceId, targetMembers, itemType,);
    var
      state = getState(),
      params = {
        itemId: itemType == 'sizestables' 
                  ? state.get('openSizesTable').get('uuid')
                  : state.get('openApparelInfos').get('uuid'),
        partitionId: state.get('auth').get('partition'),
        workspaceId,
        targetMembers,
        itemType
      };
      //console.log(params);
    FBFsendItemToWorkspace(params).then( result => {
      //console.log('send ok!');
      if(callback) callback({ link: document.location.origin + '/workspace/send/' + result.data.id });
    });
  }
}

const FBFcreateRestrictedShare = firebase.functions().httpsCallable('createRestrictedShare');

export function createRestrictedShare(workspaceId, targetMembers, itemType, callback){
  return (dispatch, getState) => {
   // console.log('------- sharing!', workspaceId, targetMembers, itemType);
    var
      state = getState(),
      params = {
        itemId: itemType == 'sizestables' 
                  ? state.get('openSizesTable').get('uuid')
                  : state.get('openApparelInfos').get('uuid'),
        partitionId: state.get('auth').get('partition'),
        workspaceId,
        targetMembers,
        itemType
      };
     // console.log(params);
      FBFcreateRestrictedShare(params).then( result => {
     // console.log('share ok!', result);
      if(callback) callback();
    });
  }
}

const FBFgetConfirmation = firebase.functions().httpsCallable('getConfirmation');

export function getConfirmation(id, callback){
  return (dispatch, getState) => {
    FBFgetConfirmation({id}).then( ({data}) => {
      callback(data);
    });
  }
}

const FBFconfirmAccount = firebase.functions().httpsCallable('confirmAccount');

export function confirmAccount(data, callback){
  return (dispatch, getState) => {
    FBFconfirmAccount(data).then( result => {
      callback(result);
    });
  }
}

const FBFswitchAccount = firebase.functions().httpsCallable('switchAccount');

export function switchAccount(data, callback){
  return (dispatch, getState) => {
    FBFswitchAccount(data).then( result => {
      callback(result);
    });
  }
}

export function verifyPasswordResetCode(code, callback){
  return (dispatch, getState) => {
    firebaseApp.auth()
               .verifyPasswordResetCode(code)
               .then( email => callback(null, email) )
               .catch( err => callback(err));
    ;
  }
}

export function confirmPasswordReset(code, newPassword, callback){
  return (dispatch, getState) => {
    firebaseApp.auth()
               .confirmPasswordReset(code, newPassword)
               .then(() => callback(null))
               .catch(err => callback(err));
  }
}

export function updateUserPhoto(photoData, callback) {
  return async (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    const response = await fetch(photoData);
    const blob = await response.blob();
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = storageRef.child('thumbs/u-' + user.uid + '.jpg').put(blob, { contentType: 'image/jpeg' });
  
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>{
        var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
        
      },
      (error) =>{
        throw error
      },
      () =>{
        uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
          
          user.updateProfile({
            photoURL: url
          }).then(() => {
            // Update successful
            dispatch({
              type: SET_PROFILE_PHOTOURL,
              data: url 
            });
            callback({ success: true });
          }).catch((error) => {
            // An error occurred
            callback({ success: false });
          });
        })
      }
    ) 
  }
}

export function updateUserDisplayName(name, callback){
  return (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    user.updateProfile({
      displayName: name
    }).then(() => {
      // Update successful
      dispatch({
        type: SET_PROFILE_DISPLAYNAME,
        data: name 
      });
      callback({ success: true });
    }).catch((error) => {
      // An error occurred
      callback({ success: false });
    });
  }
}

export function updateUserEmail( newEmail, password, callback){
  return (dispatch, getState) => {
    const 
      user = firebase.auth().currentUser,
      credential = firebase.auth.EmailAuthProvider.credential(
        user.email, 
        password
    );
    user.reauthenticateWithCredential(credential).then(() => {
      // User re-authenticated.
      user.updateEmail(newEmail).then(() => {
        // Update successful
        callback('success');
        dispatch({
          type: SET_PROFILE_EMAIL,
          data: newEmail
        })
      }).catch((error) => {
        // An error occurred
        callback('error');
      });
    }).catch((error) => {
      callback('wrong-password');
    });
  }
}

export function checkActionCode(actionCode, callback) {
  return (dispatch, getState) => {
    const auth = firebase.auth();
    auth.checkActionCode(actionCode).then((info) => {
      callback('correct-code', info);
    }).catch((error) => {
      callback('wrong-code', null);
    });
  }
}

export function recoverUserEmail(actionCode, callback){
  return (dispatch, getState ) => {
    firebase.auth().applyActionCode(actionCode).then(() => {
      callback('success');
    }).catch( error => {
      callback('error');
    });
  }
}