import Museo_Sans_100 from '../fonts/MuseoSans_100.otf';
import Museo_Sans_500 from '../fonts/MuseoSans_500.otf';

export const Museo = {
  fontFamily: 'Museo',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    url(${Museo_Sans_100}) format('opentype')
  `,
};

export const MuseoBold = {
  fontFamily: 'Museo',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${Museo_Sans_500}) format('opentype')
  `,
};