import DataManagementStore from '../../../commons/DataManagementStore';

export default function ReceiveSharedBase(data, payload, date, user, isListening){

  data.bases[payload.id] = {
    name: payload.name,
    created_at: payload.created_at,
    created_by: payload.created_by,
    sharedFrom: payload.sharedFrom,
    sharedFromPartition: payload.sharedFromPartition,
    sharedWith: payload.sharedWith,
    sharedAt: date,
    workspace: payload.workspace
  }

  if(payload.workspace){
    data.bases[payload.id].workspace = payload.workspace;
  }

  if(isListening){
    // live side effects
    var 
      addData = {
        bucketKey: 'userBases',
        itemData: {
          name: payload.name,
          created_at: payload.created_at,
          created_by: payload.created_by,
          sharedFrom: payload.sharedFrom,
          sharedFromPartition: payload.sharedFromPartition,
          sharedWith: payload.sharedWith,
          sharedAt: date,
          workspace: payload.workspace
        },
        itemId: payload.id
      };
    DataManagementStore.addItemToBucket(addData);
  }

  return data;
}